import React, { useMemo } from 'react'
import { observer } from 'mobx-react-lite'

import { useApi, useLogic, useStore } from '../../../hooks/storeHook'
import { ImportScreen } from '../../ImportScreen'

import { YandexMarketProductsTableData } from '../../../store/productStore'

import { YandexMarketProductModel } from '../../../server/mpsklad_core/Models/YandexMarketProductModel'
import { YandexMarketProductColumnId } from '../../../types/yandexMarketColumns'
import { YandexMarketProductImportTable } from '../../YandexMarketProductImportTable'

export const YandexMarketImportPage =
  observer(
    () => {
      const api = useApi()
      const {productStore: {createYandexMarketProductsTable}, homeNavRequired} = useStore()
      const {tryGetYandexMarketAccount} = useLogic()

      const account = tryGetYandexMarketAccount(homeNavRequired.accountId)

      const products = useMemo(
        () => account && createYandexMarketProductsTable(account.id),
        [createYandexMarketProductsTable, account])

      if (!products || !account) {
        return null
      }

      return (
        <ImportScreen<YandexMarketProductsTableData, YandexMarketProductModel, YandexMarketProductColumnId>
          account={account}
          type="YandexMarket"
          importProductsFunc={api.import.importProductsYandexMarket}
          importStocksFunc={api.import.importStocksYandexMarket}
          products={products}
          TableComponent={YandexMarketProductImportTable}
        />
      )
    })