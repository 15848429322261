import React, { useState } from 'react'
import { observer } from 'mobx-react-lite'
import { Link } from 'react-router-dom'
import { Box, FormHelperText } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import clsx from 'clsx'

import { useApi } from '../../../hooks/storeHook'
import { useLazyEffect } from '../../../hooks/commonHooks'

import { BoxLoader } from '../../BoxLoader'
import { WildberriesLabelsPrintForm } from './WildberriesLabelsPrintForm'

import { appRoutes } from '../../../common/appRoutes'

import { WbAccountModel } from '../../../server/mpsklad_core/Models/WbAccountModel'
import { WbSupplyModel } from '../../../server/mpsklad_core/Models/WbSupplyModel'

export type WildberriesLabelsPrintProps = {
  account: WbAccountModel
}

export const WildberriesLabelsPrint =
  observer(({account}: WildberriesLabelsPrintProps) => {
    const api = useApi()
    const wbAccountId = account.id

    const [supplies, setSupplies] = useState(null as WbSupplyModel[] | null)

    const classes = useStyles()

    const loadSupplies =
      async () => setSupplies(await api.order.getActiveWbSupplies(wbAccountId))

    const loadDeliveredSupplies =
      async () => {
        setSupplies(null)
        setSupplies(await api.order.getClosedWbSupplies(wbAccountId))
      }

    const reloadSupplies = async () => {
      setSupplies(null)
      await loadSupplies()
    }

    useLazyEffect(loadSupplies, [wbAccountId])

    const wbOrdersRoute = appRoutes.CommonStore.orders.route({accountId: wbAccountId, integration: 'Wildberries'})

    if (supplies == null) {
      return <BoxLoader/>
    }

    return <>
      <Box className={classes.container}>
        <button className={clsx(classes.loadDeliveredSuppliesButton, 'default-button')} onClick={loadDeliveredSupplies}>
          <p>Загрузить закрытые поставки</p>
        </button>

        <FormHelperText className={classes.helperText}>
          100 последних по дате создания
        </FormHelperText>
      </Box>

      {
        supplies.length > 0
        ? <WildberriesLabelsPrintForm wbAccountId={wbAccountId} wbSupplies={supplies} onPrinted={reloadSupplies}/>
        :
        <Box className={classes.noSupplyText}>
          Нет поставок.
          <br/>
          Для печати этикеток необходимо
          добавить <Link to={wbOrdersRoute}>заказы</Link> в поставку,
          либо загрузить закрытые поставки.
        </Box>
      }
    </>
  })

const useStyles = makeStyles(
  (theme) => ({
    container: {
      padding: '16px 0px 0px 0px',
      width: 'calc(100vw - 300px)'
    },
    loadDeliveredSuppliesButton: {
      width: 265,
      height: 40,
      color: '#FFFFFF',
      alignItems: 'center',
      marginTop: 0
    },
    helperText: {
      fontSize: 11,
      lineHeight: '12px',
      marginTop: 5
    },
    noSupplyText: {
      paddingTop: 20,
      color: theme.palette.mpSkladSecondary.darkBlue1,
      fontSize: 14,
      lineHeight: '12px'
    }
  }))