import React from 'react'
import { observer } from 'mobx-react-lite'

import { Box, FormLabel, Grid, GridProps } from '@material-ui/core'
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date'
import { makeStyles } from '@material-ui/core/styles'
import IOSDateTimePicker from './IOSDateTimePicker'

export type DateRangePickerProps = {
  containerProps?: Omit<GridProps, 'item' | 'container'>

  startDate: Date

  endDate: Date

  maxDate?: Date

  onChange: (startDate: Date, endDate: Date) => void
}

export const DateTimeRangePicker =
  observer(
    ({
       containerProps,
       startDate, endDate, onChange
     }: DateRangePickerProps) => {
      const onStartChange = (date: MaterialUiPickersDate) => {
        const newStartDate = date ?? startDate
        const newEndDate = newStartDate > endDate ? newStartDate : endDate

        onChange(newStartDate, newEndDate)
      }

      const onEndChange = (date: MaterialUiPickersDate) => {
        const newEndDate = date ?? endDate
        const newStartDate = newEndDate < startDate ? newEndDate : startDate

        onChange(newStartDate, newEndDate)
      }

      const classes = useStyles()

      return (
        <Grid className={classes.container} container direction="row"
              {...containerProps} style={{width: '100%'}}>

          <Grid item xs={6} className={classes.gridItemLeft}>
            <Box>
              <FormLabel component="legend" className={classes.label}>
                <span className={classes.dash}>—</span>
                От
              </FormLabel>
            </Box>

            <IOSDateTimePicker value={startDate} onChange={onStartChange} fullWidth/>
          </Grid>

          <Grid item xs={6} className={classes.gridItemRight}>

            <Box>
              <FormLabel component="legend" className={classes.label}>
                <span className={classes.dash}>—</span>
                До
              </FormLabel>
            </Box>

            <IOSDateTimePicker value={endDate} onChange={onEndChange} fullWidth/>
          </Grid>
        </Grid>
      )
    })

const useStyles = makeStyles(() => ({
  container: {
    marginBottom: 2
  },
  dash: {
    color: '#3987CF',
    marginRight: 3
  },
  label: {
    fontSize: 12,
    lineHeight: '14.4px',
    marginBottom: 15,
    marginTop: 10,
    marginLeft: 5
  },
  dateTimePicker: {
    '& .MuiInputBase-input': {
      width: 250,
      borderRadius: 8,
      padding: 10,
      fontSize: 12
    },
    '& .MuiPickersDay-daySelected': {
      backgroundColor: 'red'
    }
  },
  gridItemLeft: {
    paddingRight: 12
  },
  gridItemRight: {
    paddingLeft: 12
  }
}))