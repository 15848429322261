import { makeStyles } from '@material-ui/core/styles'

// TODO: (redesign) Why are these styles separate from the DefaultRadioGroup if they are always used?
export const useRadioGroupStyles = makeStyles(
  _ => ({
    radioButton: {
      '& span': {
        color: '#1F364D',
        fontSize: '12px',
        fontWeight: 400,
        lineHeight: '14.4px',
        textAlign: 'left'
      },
      '& .Mui-disabled span': {
        color: '#888888 !important'
      },
      '& span:not(.Mui-disabled)': {
        color: '#3987CF'
      }
    },
    selectedRadioButton: {
      '& span': {
        color: '#3987CF !important',
        fontSize: '12px',
        fontWeight: 700,
        lineHeight: '14.4px',
        textAlign: 'left'
      }
    },
    radioLabel: {
      color: '#1F364D !important'
    }
  }))