import React, { useCallback } from 'react'
import { observer } from 'mobx-react-lite'

import { useMessages } from '../../../hooks/snackbarHooks'
import { useLogic, useStore } from '../../../hooks/storeHook'

import { PricesSettings } from '../PricesSettings'
import { OzonApiSettings } from './OzonApiSettings'
import { CompanySettings } from '../CompanySettings'
import { OzonStoresForm } from '../../OzonStoresForm'
import { StoreSettingsPage } from '../StoreSettingsPage'
import { OzonOrdersSettings } from './OzonOrdersSettings'
import { OzonReturnsSettings } from './OzonReturnsSettings'
import { CommissionsSettings } from '../CommissionsSettings'

import { OzonAccountModel } from '../../../server/mpsklad_core/Models/OzonAccountModel'
import { OzonDeliveryType } from '../../../server/mpsklad_core/Entity/Ozon/OzonDeliveryType'
import { EditOzonAccountModel } from '../../../server/mpsklad_core/Models/EditOzonAccountModel'
import { CommissionsRecordingType } from '../../../server/mpsklad_core/Entity/Base/CommissionsRecordingType'
import { EditStoreAccountMsDataModel } from '../../../server/mpsklad_core/Models/EditStoreAccountMsDataModel'

export const OzonSettingsPage = observer(() => {
  const logic = useLogic()
  const store = useStore()
  const {showSuccess} = useMessages()

  const account = logic.tryGetOzonAccount(store.homeNavRequired.accountId)

  const onSubmit = useCallback(
    async (data: EditStoreAccountMsDataModel) => {
      try {
        const formData = {
          deliveryType: account?.deliveryType ?? OzonDeliveryType.FBS,
          isUpdatePrices: account?.isUpdatePrices ?? false,
          isGeneratingPayments: account?.isGeneratingPayments ?? false,
          isUsingCommissions: account?.isUsingCommissions ?? false,
          ordersCommissionsRecording:
            account?.ordersCommissionsRecording ?? CommissionsRecordingType.Nothing,
          documentsCommissionsRecording:
            account?.documentsCommissionsRecording ?? CommissionsRecordingType.Nothing,
          clientId: account?.clientId ?? 0,
          apiKey: account?.apiKey ?? '',
          name: account?.name ?? '',
          msSalesReturnStoreId: account?.msSalesReturnStoreId,
          msData: data
        } satisfies Omit<EditOzonAccountModel, 'id'>

        if (account) {
          await logic.editOzonAccount({id: account.id, ...formData})
        } else {
          await logic.createOzonAccount({id: 0, ...formData})
        }

        showSuccess('Сохранено!')
      } catch (e) {
        console.error('Failed to save Ozon account', e)
      }
    },
    [account, logic, showSuccess]
  )

  return (
    <StoreSettingsPage<OzonAccountModel>
      integrationTypeKey={'Ozon'}
      account={account}
      apiSettingsComponent={OzonApiSettings}
      companySettingsComponent={CompanySettings}
      ordersSettingsComponent={OzonOrdersSettings}
      warehousesSettingsComponent={OzonStoresForm}
      returnsSettingsComponent={OzonReturnsSettings}
      commissionsSettingsComponent={CommissionsSettings}
      pricesSettingsComponent={(props) => (
        <PricesSettings
          onCheckPrices={logic.api.prices.checkPriceActivationOzon}
          onEnablePriceSync={logic.api.prices.enablePriceSyncOzon}
          onForceUpdatePrices={() => logic.api.userSync.updatePricesOzon(account!.id)}
          {...props}
        />
      )}
      onSubmit={onSubmit}
    />
  )
})