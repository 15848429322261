import React from 'react'
import { observer } from 'mobx-react-lite'

import { makeStyles } from '@material-ui/core/styles'
import { TableCell, TableSortLabel } from '@material-ui/core'

import { MyHeadCellProps } from '../../types/tableTypes'
import { selectColumnId } from '../../types/myProductColumnIds'
import clsx from 'clsx'

export const MyHeadCell =
  observer(
    <TColumnId extends string, TRow extends object>
    ({
       table: {
         getColumnOrder, toggleOrder
       },
       column, isLastCellInGroup
     }: MyHeadCellProps<TColumnId, TRow>) => {
      const {id, title, titleHint, sortable} = column

      const classes = useStyles()

      if (id === selectColumnId) {
        return (
          <TableCell className={clsx(classes.cell, classes.emptyCell)}/>
        )
      }

      if (!sortable) {
        return (
          <TableCell align="left" className={isLastCellInGroup ? classes.lastCellInGroup : classes.cell}>{title}
            {
              !!titleHint &&
              <p className={classes.titleHint}>
                {titleHint}
              </p>
            }
          </TableCell>
        )
      }

      const {isOrdered, orderDirection} = getColumnOrder(id)

      return (
        <TableCell
          align="left" className={isLastCellInGroup ? classes.lastCellInGroup : classes.cell}
          sortDirection={orderDirection}>
          <TableSortLabel
            active={isOrdered}
            direction={orderDirection}
            onClick={() => toggleOrder(column)}
          >
            {title}

            {
              !!titleHint &&
              <p className={classes.titleHint}>
                {titleHint}
              </p>
            }
          </TableSortLabel>
        </TableCell>
      )
    })

const useStyles = makeStyles(
  ({palette}) => ({
    cell: {
      border: `2px solid ${palette.mpSkladPrimary.white}`,
      padding: '8px 10px',
      fontSize: '12px'
    },
      emptyCell: { 
         minWidth: '60px',
          width: '60px',
      },
    lastCellInGroup: {
      border: `2px solid ${palette.mpSkladPrimary.white}`,
      borderRight: `2px solid ${palette.mpSkladSecondary.lightGray}`,
      padding: '8px 10px',
      fontSize: '20px'
    },
    titleHint: {
      textAlign: 'left'
    },
    checkbox: {
      color: palette.mpSkladPrimary.blue
    }
  }))