import { AccountNavigation } from './AccountNavigation'
import { useStore } from '../hooks/storeHook'
import { observer } from 'mobx-react-lite'
import { Box, FormHelperText } from '@material-ui/core'
import { AddAccountButton } from './AddAccountButton'
import { required } from '../common/objectUtils'
import { appRoutes } from '../common/appRoutes'
import { Link, useHistory } from 'react-router-dom'
import { useCallback } from 'react'
import { HomeTab } from './HomeTabTitle'
import { exact } from '../common/tsUtils'
import { makeOzonAccountName, makeWbAccountName, makeYandexMarketAccountName } from '../common/accountUtils'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/core/styles'
import { getIntegrationTypeKey, isIntegrationType } from '../types/integrationTypeUtils'
import { IntegrationType } from '../server/mpsklad_core/Entity/IntegrationType'

export const SideBarNavigation = observer(() => {
  const store = useStore()
  const {
    syncStore: {moySkladAccount, ozonAccounts, wbAccounts, yandexMarketAccounts},
    allowedIntegrations
  } = store
  const history = useHistory()

  const routeParams = appRoutes.CommonStore.base.parse(history.location.pathname)
  const integration = routeParams?.integration
  const accountId = Number(routeParams?.accountId)

  const classes = useStyles()

  const getTabs = useCallback(() => {
    const result: HomeTab[] = []

    if (allowedIntegrations[IntegrationType.Ozon] &&
        (integration === 'Ozon' ||
         integration === 'MoySklad' ||
         !isIntegrationType(integration))) {
      result.push(
        ...ozonAccounts.map(ozonAccount => exact<HomeTab>({
          ozonAccount,
          id: ozonAccount.id,
          title: makeOzonAccountName(ozonAccount),
          integrationType: IntegrationType.Ozon
        })))
    }

    if (allowedIntegrations[IntegrationType.Wildberries]
        && (integration === 'Wildberries' ||
            integration === 'MoySklad' ||
            !isIntegrationType(integration))) {
      result.push(
        ...wbAccounts.map(wbAccount => exact<HomeTab>({
          wbAccount,
          id: wbAccount.id,
          title: makeWbAccountName(wbAccount),
          integrationType: IntegrationType.Wildberries
        })))
    }

    if (allowedIntegrations[IntegrationType.YandexMarket]
        && (integration === 'YandexMarket' ||
            integration === 'MoySklad' ||
            !isIntegrationType(integration))) {
      result.push(
        ...yandexMarketAccounts.map(yandexMarketAccount => exact<HomeTab>({
          yandexMarketAccount,
          id: yandexMarketAccount.id,
          title: makeYandexMarketAccountName(yandexMarketAccount),
          integrationType: IntegrationType.YandexMarket
        })))
    }

    return result
  }, [allowedIntegrations, integration, ozonAccounts, wbAccounts, yandexMarketAccounts])

  const tabs = getTabs()

  if (moySkladAccount == null) {
    return <Box className={clsx(classes.hint, 'default-border')}>
      <FormHelperText className={classes.helperText}>
        Для работы с товарами добавьте
        <Link
          to={appRoutes.MoySklad.settings.configuration.route({accountId: 0, integration: 'MoySklad'})}
          style={{textDecoration: 'none'}}
        >
          аккаунт МоегоСклада
        </Link>.
      </FormHelperText>
    </Box>
  }

  if (!store.getHomeNav()
      || (ozonAccounts.length === 0 && wbAccounts.length === 0 && yandexMarketAccounts.length === 0)) {
    return <Box padding={3} minWidth={200}>
      <AddAccountButton withHelpText integration={integration ?? 'Ozon'}/>
    </Box>
  }

  const onTabChange =
    (newTabIndex: number) => {
      const newTab = tabs[newTabIndex]

      const newAccount = required(newTab.ozonAccount ?? newTab.wbAccount ?? newTab.yandexMarketAccount)

      const accountId = newAccount.id
      const integration = getIntegrationTypeKey(newAccount.integrationType)

      const redirectTo = appRoutes.CommonStore.products.route(
        {accountId, integration})

      history.push(redirectTo)
    }

  const shouldBeAllUnselected = integration === 'MoySklad' || !isIntegrationType(integration)

  return (
    <div className="sidebar_container">
      <Box className="sticky_sidebar" style={{minWidth: 200}}>
        {
          tabs.map(
            (_, index) =>
              <AccountNavigation
                key={_.id}
                isSelected={_.id === accountId && !shouldBeAllUnselected}
                title={_.title}
                account={required(_.ozonAccount ?? _.wbAccount ?? _.yandexMarketAccount)}
                hasMoySkladApp={store.hasMoySkladApp}
                accountIndex={index}
                onAccountChange={onTabChange}
              />)
        }

        {
          !shouldBeAllUnselected && <AddAccountButton integration={integration ?? 'Ozon'}/>
        }
      </Box>
    </div>
  )
})

const useStyles = makeStyles(
  () => ({
    hint: {
      margin: 20,
      padding: 20,
      maxWidth: 160,
      maxHeight: 60,
      backgroundColor: '#FBFBFD'
    },
    helperText: {
      fontSize: 11,
      fontWeight: 400,
      marginTop: 5
    }
  })
)