import React, { useMemo } from 'react'
import { observer } from 'mobx-react-lite'
import { Redirect, Route, Switch } from 'react-router-dom'

import { appRoutes, StoreRouteParams } from '../../../common/appRoutes'

import { StatusScreen } from '../../StatusScreen'
import { MoySkladProductsTableScreen } from './MoySkladProductsTableScreen'
import { MoySkladSettingsScreen } from './MoySkladSettingsScreen'
import { RoutingTabs } from '../../RoutingTabs'
import { MoySkladAdditionalPropertiesSettings } from './MoySkladAdditionalPropertiesSettings'
import { RouteTabItem } from '../../RouteTabItem'
import { required } from '../../../common/objectUtils'
import { useStore } from '../../../hooks/storeHook'

export const MoySkladSettingsPage = observer(() => {
  const {homeNavRequired} = useStore()

  const routes: RouteTabItem[] = useMemo(
    () => {
      const params: StoreRouteParams = {
        accountId: homeNavRequired.accountId,
        integration: 'MoySklad'
      }

      return [
        {
          title: 'Статусы',
          route: 'status',
          path: appRoutes.MoySklad.settings.status.route(params),
          template: appRoutes.MoySklad.settings.status.template,
          render: () => <StatusScreen/>
        },
        {
          title: 'Товары',
          route: 'products',
          path: appRoutes.MoySklad.settings.products.route(params),
          template: appRoutes.MoySklad.settings.products.template,
          render: () => <MoySkladProductsTableScreen/>
        },
        {
          title: 'Настройка',
          route: 'configuration',
          path: appRoutes.MoySklad.settings.configuration.route(params),
          template: appRoutes.MoySklad.settings.configuration.template,
          render: () => <MoySkladSettingsScreen/>
        },
        {
          title: 'Доп. Поля',
          route: 'additional',
          path: appRoutes.MoySklad.settings.additional.route(params),
          template: appRoutes.MoySklad.settings.additional.template,
          render: () => <MoySkladAdditionalPropertiesSettings/>
        }
      ]
    },
    [homeNavRequired.accountId]
  )

  const firstRoutePath = required(routes[2].path)

  return (
    <RoutingTabs
      routeTabs={routes}
    >
      <Switch>
        <Route exact path={appRoutes.MoySklad.settings.template}>
          <Redirect to={firstRoutePath}/>
        </Route>
        {routes.map(({path, template, render}) => (
          <Route
            key={path}
            path={template}
            render={render}
          />
        ))}

        <Redirect to={appRoutes.MoySklad.settings.initial}/>
      </Switch>
    </RoutingTabs>
  )
})