//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

export enum OzonDeliveryType {
  /** Продажа со склада Ozon (FBO) */
  FBO = 0,
  /** Продажа со своего склада (FBS) */
  FBS = 1,
  /** Продажа со своего склада и доставка силами продавца (realFBS) */
  RFBS = 2
}
