import React from 'react'
import { observer } from 'mobx-react-lite'

import { TableHead, TableRow } from '@material-ui/core'

import { MyHeadCell } from './MyHeadCell'

import { MyTableHeadProps } from '../../types/tableTypes'
import { useLogic } from '../../hooks/storeHook'
import { useTheme } from '@material-ui/core/styles'

export const MyTableHead =
  observer(
    <TColumnId extends string, TRow extends object>
    ({table, visibleColumns, groupHeader}: MyTableHeadProps<TColumnId, TRow>) => {
      const {getLastColumnsIdsByColumnGroups} = useLogic()

      const {palette} = useTheme()

      const lastColumnIndexByGroup = getLastColumnsIdsByColumnGroups()

      return (
        <TableHead>
          {
            groupHeader &&
            <TableRow>
              {groupHeader}
            </TableRow>
          }

          <TableRow className="table_head_row" style={{background: palette.mpSkladSecondary.blue}}>
            {
              visibleColumns.map(column => {
                const isLastInGroup = Object.values(lastColumnIndexByGroup).includes(column.id)

                return (
                  <MyHeadCell
                    key={column.id}
                    table={table}
                    column={column}
                    isLastCellInGroup={isLastInGroup}
                  />
                )
              })
            }
          </TableRow>
        </TableHead>
      )
    }
  )