import { TableColumn } from './tableTypes'
import { createColumns } from './columnGroupCreator'
import { selectColumnId } from './myProductColumnIds'

import { TableDateCell } from '../components/tableCells/TableDateCell'
import { MoySkladIdCell } from '../components/tableCells/MoySkladIdCell'
import { TableSelectCell } from '../components/tableCells/TableSelectCell'
import { WbOrderSyncErrorCell } from '../components/tableCells/WbOrderSyncErrorCell'
import { WbOrderSyncLogsCell } from '../components/tableCells/StoreOrderSyncLogsCell'

import { formatPrice } from '../common/numberUtils'

import { WbAccountModel } from '../server/mpsklad_core/Models/WbAccountModel'
import { CurrencyISO4217 } from '../server/mpsklad_core/Entity/CurrencyISO4217'
import { WbOrderModel } from '../server/mpsklad_core/Models/Orders/WbOrderModel'
import { WbDeliveryType } from '../server/mpsklad_core/Entity/Wildberries/WbDeliveryType'

export type WbOrderColumnId =
  keyof WbOrderModel
  | typeof selectColumnId
  | 'moySkladId'
  | 'logs'

export type WbOrderColumn = TableColumn<WbOrderColumnId, WbOrderModel>

export const createWbOrderColumns =
  (wbAccount: WbAccountModel): WbOrderColumn[] =>
    createColumns<WbOrderColumnId, WbOrderModel>({
        id: selectColumnId,
        title: 'Выбрать',
        CellComponent: TableSelectCell
      }, {
        id: 'id',
        title: 'Id'
      }, {
        id: 'createdAt',
        title: 'Дата создания',
        CellComponent: TableDateCell
      }, {
        id: 'wbOrderId',
        title: 'Id в Wildberries'
      },
      wbAccount.deliveryType !== WbDeliveryType.FBW ? null : {
        id: 'wbSrId',
        title: 'Уникальный идентификатор заказа (FBW)'
      }, {
        id: 'wbRId',
        title: 'Идентификатор вещи'
      }, {
        id: 'chrtId',
        title: 'Код размера (chrtId)'
      }, {
        id: 'barcode',
        title: 'Штрихкод'
      }, {
        id: 'name',
        title: 'Наименование товара'
      }, {
        id: 'status',
        title: 'Статус'
      }, {
        id: 'price',
        title: 'Цена продажи',
        accessor: _ => formatPrice(_.price)
      }, {
        id: 'currency',
        title: 'Валюта заказа',
        accessor: _ => _.currency ? CurrencyISO4217[_.currency] : ''
      }, {
        id: 'deliveryType',
        title: 'Тип доставки'
      }, {
        id: 'isLargeCargo',
        title: 'сКГТ-признак товара'
      }, {
        id: 'wbSupplyId',
        title: 'Поставка'
      }, {
        id: 'moySkladId',
        title: 'GUID в МоёмСкладе',
        CellComponent: MoySkladIdCell
      }, {
        id: 'syncErrorId',
        title: 'Ошибки',
        CellComponent: WbOrderSyncErrorCell
      }, {
        id: 'logs',
        title: 'Логи',
        CellComponent: WbOrderSyncLogsCell
      })