import React from 'react'

import { TableCell, TableHead, TableRow } from '@material-ui/core'
import { makeStyles, useTheme } from '@material-ui/core/styles'

export const SyncLogsTableHead =
  () => {
    const {palette} = useTheme()
    const classes = useStyles()

    return <TableHead>
      <TableRow className="table_head_row" style={{background: palette.mpSkladSecondary.blue}}>
        <TableCell className={classes.cell}>
          Дата
        </TableCell>

        <TableCell className={classes.cell}>
          Тип
        </TableCell>

        <TableCell className={classes.cell}>
          Сообщение
        </TableCell>

        <TableCell className={classes.cell}>
          Id
        </TableCell>
      </TableRow>
    </TableHead>
  }

const useStyles = makeStyles(
  ({palette}) => ({
    cell: {
      border: `2px solid ${palette.mpSkladPrimary.white}`,
      padding: '8px 10px',
      fontSize: '12px',
      height: 30
    }
  }))