import React from 'react'
import { observer } from 'mobx-react-lite'

import { AutocompleteRenderInputParams } from '@material-ui/lab/Autocomplete/Autocomplete'

import { ExternalProductPicker, ProductSinglePickerBaseProps } from './ExternalProductPicker'

import { useLogic } from '../../hooks/storeHook'
import { BootstrapInput } from '../BootstrapInput'

export const YandexMarketProductSinglePicker =
  observer(
    ({msProductId, ...passProps}: ProductSinglePickerBaseProps) => {
      const logic = useLogic()

      return (
        <ExternalProductPicker
          loadOptions={searchTerm => logic.loadYandexMarketProductOptions(msProductId, searchTerm)}
          renderInput={renderYandexMarketInput}
          {...passProps}
        />
      )
    })

export const renderYandexMarketInput =
  (props: AutocompleteRenderInputParams) =>
    <BootstrapInput
      {...props}
      {...props.InputProps}
      autoFocus
      multiline
      maxRows={3}
      placeholder="Ваш SKU / Артикул производителя / Штрихкод"
      style={{fontSize: 12}}
    />