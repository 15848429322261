//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

import { UserAuthModel } from '../Models/UserAuthModel';
import { MoySkladAppState } from '../Models/MoySkladAppState';
import { LoginModel } from '../Models/ApiModels/LoginModel';
import { LoginMoySkladAppModel } from '../Models/ApiModels/LoginMoySkladAppModel';
import { RegisterModel } from '../Models/ApiModels/RegisterModel';
import { RequestPasswordResetModel } from '../Models/ApiModels/RequestPasswordResetModel';
import { VerifyResetCodeModel } from '../Models/ApiModels/VerifyResetCodeModel';
import { ResetPasswordModel } from '../Models/ApiModels/ResetPasswordModel';
import { EditUserInfoModel } from '../Models/ApiModels/EditUserInfoModel';

import { boundClass } from 'autobind-decorator';
import { AxiosInstance, AxiosResponse, AxiosRequestConfig } from 'axios';
import { encodeUrlParameter, getAxiosData } from '../../../common/urlUtility';
@boundClass export class AuthController
{
  
  private readonly axios: AxiosInstance
  
  constructor (axios: AxiosInstance)
  {
    this.axios = axios
  }
  check(_config?: AxiosRequestConfig) : Promise<UserAuthModel>
  {
    return this.axios.post<UserAuthModel>(`Auth/Check`, null, _config).then(getAxiosData)
  }
  

  checkMoySkladAppState(_config?: AxiosRequestConfig) : Promise<MoySkladAppState>
  {
    return this.axios.post<MoySkladAppState>(`Auth/CheckMoySkladAppState`, null, _config).then(getAxiosData)
  }
  

  login(model: LoginModel, _config?: AxiosRequestConfig) : Promise<AxiosResponse<unknown>>
  {
    return this.axios.post<unknown/*Microsoft.AspNetCore.Mvc.ActionResult*/>(`Auth/Login`, model, _config)
  }
  

  loginMoySkladApp(model: LoginMoySkladAppModel, _config?: AxiosRequestConfig) : Promise<string>
  {
    return this.axios.post<string>(`Auth/LoginMoySkladApp`, model, _config).then(getAxiosData)
  }
  

  register(model: RegisterModel, _config?: AxiosRequestConfig) : Promise<AxiosResponse<unknown>>
  {
    return this.axios.post<unknown/*Microsoft.AspNetCore.Mvc.ActionResult*/>(`Auth/Register`, model, _config)
  }
  

  logOut(_config?: AxiosRequestConfig) : Promise<AxiosResponse<unknown>>
  {
    return this.axios.post<unknown/*Microsoft.AspNetCore.Mvc.StatusCodeResult*/>(`Auth/LogOut`, null, _config)
  }
  

  requestPasswordReset(model: RequestPasswordResetModel, _config?: AxiosRequestConfig) : Promise<AxiosResponse<unknown>>
  {
    return this.axios.post<unknown/*Microsoft.AspNetCore.Mvc.ActionResult*/>(`Auth/RequestPasswordReset`, model, _config)
  }
  

  verifyResetCode(model: VerifyResetCodeModel, _config?: AxiosRequestConfig) : Promise<AxiosResponse<unknown>>
  {
    return this.axios.post<unknown/*Microsoft.AspNetCore.Mvc.ActionResult*/>(`Auth/VerifyResetCode`, model, _config)
  }
  

  resetPassword(model: ResetPasswordModel, _config?: AxiosRequestConfig) : Promise<AxiosResponse<unknown>>
  {
    return this.axios.post<unknown/*Microsoft.AspNetCore.Mvc.ActionResult*/>(`Auth/ResetPassword`, model, _config)
  }
  

  editUserInfo(userInfo: EditUserInfoModel, _config?: AxiosRequestConfig) : Promise<void>
  {
    return this.axios.post<void>(`Auth/EditUserInfo`, userInfo, _config).then(getAxiosData)
  }
  

}
