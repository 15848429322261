import {
  composeRoute,
  createRoute,
  RouteWithParams
} from './routeUtils'
import { StoreRouteParams } from './appRoutes'

export type StoreSettingsRoutes<TParams extends StoreRouteParams> = RouteWithParams<TParams> & {
  api: RouteWithParams<TParams>;
  company: RouteWithParams<TParams>;
  orders: RouteWithParams<TParams>;
  warehouses: RouteWithParams<TParams>;
  returns: RouteWithParams<TParams>;
  commissions: RouteWithParams<TParams>;
  prices: RouteWithParams<TParams>;
};

export type StorePrintRoutes<TParams extends StoreRouteParams> = RouteWithParams<TParams> & {
  labels: RouteWithParams<TParams>;
  files: RouteWithParams<TParams>;
  feeds: RouteWithParams<TParams>;
}

export type StoreRoutes<TParams extends StoreRouteParams> = {
  base: RouteWithParams<TParams>;
  settings: StoreSettingsRoutes<TParams>;
  orders: RouteWithParams<TParams>;
  products: RouteWithParams<TParams>;
  print: StorePrintRoutes<TParams>;
  import: RouteWithParams<TParams>;
  syncLogs: RouteWithParams<TParams>;
};

export const createStoreRoutes = (): StoreRoutes<StoreRouteParams> => {
  const baseRoute = createRoute<StoreRouteParams>()`/${'integration'}/${'accountId'}`
  const settingsRoute = composeRoute(baseRoute, '/settings')
  const printRoute = composeRoute<StoreRouteParams, {}>(baseRoute, '/print')

  return {
    base: baseRoute,
    settings: createStoreSettingsRoutes(settingsRoute),
    orders: composeRoute<StoreRouteParams, {}>(baseRoute, '/orders'),
    products: composeRoute<StoreRouteParams, {}>(baseRoute, '/products'),
    print: createStorePrintRoutes(printRoute),
    import: composeRoute<StoreRouteParams, {}>(baseRoute, '/import'),
    syncLogs: composeRoute<StoreRouteParams, {}>(baseRoute, '/syncLogs')
  }
}

const createStoreSettingsRoutes = <TParams extends StoreRouteParams>(baseSettingsRoute: RouteWithParams<TParams>) => ({
  ...baseSettingsRoute,
  api: composeRoute<TParams, {}>(baseSettingsRoute, '/api'),
  company: composeRoute<TParams, {}>(baseSettingsRoute, '/company'),
  orders: composeRoute<TParams, {}>(baseSettingsRoute, '/orders'),
  warehouses: composeRoute<TParams, {}>(baseSettingsRoute, '/warehouses'),
  returns: composeRoute<TParams, {}>(baseSettingsRoute, '/returns'),
  commissions: composeRoute<TParams, {}>(baseSettingsRoute, '/commissions'),
  prices: composeRoute<TParams, {}>(baseSettingsRoute, '/prices')
})

const createStorePrintRoutes = <TParams extends StoreRouteParams>(basePrintRoute: RouteWithParams<TParams>) => ({
  ...basePrintRoute,
  labels: composeRoute<TParams, {}>(basePrintRoute, '/labels'),
  files: composeRoute<TParams, {}>(basePrintRoute, '/files'),
  feeds: composeRoute<TParams, {}>(basePrintRoute, '/feeds')
})

export const storeRoutes: {
  CommonStore: StoreRoutes<StoreRouteParams>;
} = {
  CommonStore: createStoreRoutes()
}