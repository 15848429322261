import { observer } from 'mobx-react-lite'
import { Grid, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { AdditionalPropertiesSettingsField } from '../AdditionalPropertiesSettingsField'
import { required } from '../../../common/objectUtils'
import { MoySkladOrderFeeType } from '../../../server/mpsklad_core/Models/MoySkladOrderFeeType'
import React, { useEffect, useState } from 'react'
import { IntegrationType } from '../../../server/mpsklad_core/Entity/IntegrationType'
import { useBoolState } from '../../../hooks/commonHooks'
import { useLogic, useStore } from '../../../hooks/storeHook'
import { useMessages } from '../../../hooks/snackbarHooks'
import { AdditionalPropertiesSettingsActionButtons } from '../AdditionalPropertiesSettingsActionButtons'
import { MoySkladAttributeModel } from '../../../server/mpsklad_core/Models/MoySkladAttributeModel'

export type YandexMarketAdditionalPropertiesSettingsProps = {
  msAttributes: MoySkladAttributeModel[]
  validateMsAttributeId: (id: (string | undefined)) => (string | undefined)
  onCreateAttribute: (feeType: MoySkladOrderFeeType) => () => Promise<void>
}

export const YandexMarketAdditionalPropertiesSettings = observer(
  ({
     msAttributes,
     validateMsAttributeId,
     onCreateAttribute
   }: YandexMarketAdditionalPropertiesSettingsProps) => {
    const {showSuccess, showError} = useMessages()

    const store = useStore()
    const logic = useLogic()

    const [isLoading, setLoading, setLoaded] = useBoolState()

    const [feeId, setFeeId] = useState(
      validateMsAttributeId(store.syncStore.moySkladAccount?.feeAttributeId))

    const [loyaltyParticipationFeeId, setLoyaltyParticipationFeeId] = useState(
      validateMsAttributeId(store.syncStore.moySkladAccount?.loyaltyParticipationFeeAttributeId))

    const [deliveryToCustomerId, setDeliveryToCustomerId] = useState(
      validateMsAttributeId(store.syncStore.moySkladAccount?.deliveryToCustomerFeeAttributeId))

    const [expressDeliveryToCustomerId, setExpressDeliveryToCustomerId] = useState(
      validateMsAttributeId(store.syncStore.moySkladAccount?.expressDeliveryToCustomerFeeAttributeId))

    const [auctionPromotionId, setAuctionPromotionId] = useState(
      validateMsAttributeId(store.syncStore.moySkladAccount?.auctionPromotionFeeAttributeId))

    const [agencyId, setAgencyId] = useState(
      validateMsAttributeId(store.syncStore.moySkladAccount?.agencyFeeAttributeId))

    const [paymentTransferId, setPaymentTransferId] = useState(
      validateMsAttributeId(store.syncStore.moySkladAccount?.paymentTransferFeeAttributeId))

    const [returnedOrdersStorageId, setReturnedOrdersStorageId] = useState(
      validateMsAttributeId(store.syncStore.moySkladAccount?.returnedOrdersStorageFeeAttributeId))

    const [sortingId, setSortingId] = useState(
      validateMsAttributeId(store.syncStore.moySkladAccount?.sortingFeeAttributeId))

    const [intakeSortingId, setIntakeSortingId] = useState(
      validateMsAttributeId(store.syncStore.moySkladAccount?.intakeSortingFeeAttributeId))

    const [returnProcessingId, setReturnProcessingId] = useState(
      validateMsAttributeId(store.syncStore.moySkladAccount?.returnProcessingFeeAttributeId))

    const [illiquidGoodsSaleId, setIlliquidGoodsSaleId] = useState(
      validateMsAttributeId(store.syncStore.moySkladAccount?.illiquidGoodsSaleFeeAttributeId))

    const [isCreating] = useBoolState()

    const classes = useStyles()

    useEffect(() => {
        if (store.allowedIntegrations[IntegrationType.YandexMarket]) {
          setFeeId(validateMsAttributeId(store.syncStore.moySkladAccount?.feeAttributeId))
          setLoyaltyParticipationFeeId(
            validateMsAttributeId(store.syncStore.moySkladAccount?.loyaltyParticipationFeeAttributeId))
          setDeliveryToCustomerId(
            validateMsAttributeId(store.syncStore.moySkladAccount?.deliveryToCustomerFeeAttributeId))
          setExpressDeliveryToCustomerId(
            validateMsAttributeId(store.syncStore.moySkladAccount?.expressDeliveryToCustomerFeeAttributeId))
          setAuctionPromotionId(validateMsAttributeId(store.syncStore.moySkladAccount?.auctionPromotionFeeAttributeId))
          setAgencyId(validateMsAttributeId(store.syncStore.moySkladAccount?.agencyFeeAttributeId))
          setPaymentTransferId(validateMsAttributeId(store.syncStore.moySkladAccount?.paymentTransferFeeAttributeId))
          setReturnedOrdersStorageId(
            validateMsAttributeId(store.syncStore.moySkladAccount?.returnedOrdersStorageFeeAttributeId))
          setSortingId(validateMsAttributeId(store.syncStore.moySkladAccount?.sortingFeeAttributeId))
          setIntakeSortingId(validateMsAttributeId(store.syncStore.moySkladAccount?.intakeSortingFeeAttributeId))
          setReturnProcessingId(validateMsAttributeId(store.syncStore.moySkladAccount?.returnProcessingFeeAttributeId))
          setIlliquidGoodsSaleId(validateMsAttributeId(store.syncStore.moySkladAccount?.illiquidGoodsSaleFeeAttributeId))
        }
      },
      [store.allowedIntegrations, store.syncStore.moySkladAccount?.agencyFeeAttributeId, store.syncStore.moySkladAccount?.auctionPromotionFeeAttributeId, store.syncStore.moySkladAccount?.deliveryToCustomerFeeAttributeId, store.syncStore.moySkladAccount?.expressDeliveryToCustomerFeeAttributeId, store.syncStore.moySkladAccount?.feeAttributeId, store.syncStore.moySkladAccount?.illiquidGoodsSaleFeeAttributeId, store.syncStore.moySkladAccount?.intakeSortingFeeAttributeId, store.syncStore.moySkladAccount?.loyaltyParticipationFeeAttributeId, store.syncStore.moySkladAccount?.paymentTransferFeeAttributeId, store.syncStore.moySkladAccount?.returnProcessingFeeAttributeId, store.syncStore.moySkladAccount?.returnedOrdersStorageFeeAttributeId, store.syncStore.moySkladAccount?.sortingFeeAttributeId, validateMsAttributeId])

    const onSubmit = async (e: React.FormEvent) => {
      e.preventDefault()
      setLoading()

      try {
        if (store.allowedIntegrations[IntegrationType.YandexMarket]) {
          await logic.setYandexMarketAttributesForMoySkladAccount({
            feeAttributeId: feeId,
            loyaltyParticipationFeeAttributeId: loyaltyParticipationFeeId,
            deliveryToCustomerFeeAttributeId: deliveryToCustomerId,
            expressDeliveryToCustomerFeeAttributeId: expressDeliveryToCustomerId,
            auctionPromotionFeeAttributeId: auctionPromotionId,
            agencyFeeAttributeId: agencyId,
            paymentTransferFeeAttributeId: paymentTransferId,
            returnedOrdersStorageFeeAttributeId: returnedOrdersStorageId,
            sortingFeeAttributeId: sortingId,
            intakeSortingFeeAttributeId: intakeSortingId,
            returnProcessingFeeAttributeId: returnProcessingId,
            illiquidGoodsSaleFeeAttributeId: illiquidGoodsSaleId
          })
        }

        showSuccess('Поля сохранены!')
      } catch (e) {
        console.error('Failed to set MoySklad attrs', e)
        showError('Произошла ошибка при сохранении полей!')
      } finally {
        setLoaded()
      }
    }

    return (
      <Grid>
        <Typography className={classes.section}>ДОПОЛНИТЕЛЬНЫЕ ПОЛЯ ЗАКАЗА Я.МАРКЕТ</Typography>
        <Grid container spacing={3}>

          <AdditionalPropertiesSettingsField value={feeId} setValue={setFeeId} options={required(msAttributes)}
                                             isCreating={isCreating}
                                             onCreate={onCreateAttribute(MoySkladOrderFeeType.Fee)}
                                             labelName={'Размещение товара на маркетплейсе'}/>


          <AdditionalPropertiesSettingsField value={loyaltyParticipationFeeId}
                                             setValue={setLoyaltyParticipationFeeId}
                                             options={required(msAttributes)}
                                             isCreating={isCreating}
                                             onCreate={onCreateAttribute(MoySkladOrderFeeType.LoyaltyParticipationFee)}
                                             labelName={'Плата за участие в программе лояльности'}/>

          <AdditionalPropertiesSettingsField value={deliveryToCustomerId}
                                             setValue={setDeliveryToCustomerId}
                                             options={required(msAttributes)}
                                             isCreating={isCreating}
                                             onCreate={onCreateAttribute(MoySkladOrderFeeType.DeliveryToCustomer)}
                                             labelName={'Доставка покупателю'}/>

          <AdditionalPropertiesSettingsField value={expressDeliveryToCustomerId}
                                             setValue={setExpressDeliveryToCustomerId}
                                             options={required(msAttributes)}
                                             isCreating={isCreating}
                                             onCreate={onCreateAttribute(
                                               MoySkladOrderFeeType.ExpressDeliveryToCustomer)}
                                             labelName={'Экспресс-доставка покупателю'}/>

          <AdditionalPropertiesSettingsField value={auctionPromotionId}
                                             setValue={setAuctionPromotionId}
                                             options={required(msAttributes)}
                                             isCreating={isCreating}
                                             onCreate={onCreateAttribute(MoySkladOrderFeeType.AuctionPromotion)}
                                             labelName={'Плата за буст продаж'}/>

          <AdditionalPropertiesSettingsField value={agencyId}
                                             setValue={setAgencyId}
                                             options={required(msAttributes)}
                                             isCreating={isCreating}
                                             onCreate={onCreateAttribute(MoySkladOrderFeeType.Agency)}
                                             labelName={'Прием платежа покупателя'}/>

          <AdditionalPropertiesSettingsField value={paymentTransferId}
                                             setValue={setPaymentTransferId}
                                             options={required(msAttributes)}
                                             isCreating={isCreating}
                                             onCreate={onCreateAttribute(MoySkladOrderFeeType.PaymentTransfer)}
                                             labelName={'Перевод платежа покупателя'}/>

          <AdditionalPropertiesSettingsField value={returnedOrdersStorageId}
                                             setValue={setReturnedOrdersStorageId}
                                             options={required(msAttributes)}
                                             isCreating={isCreating}
                                             onCreate={onCreateAttribute(MoySkladOrderFeeType.ReturnedOrdersStorage)}
                                             labelName={'Хранение невыкупов и возвратов'}/>

          <AdditionalPropertiesSettingsField value={sortingId}
                                             setValue={setSortingId}
                                             options={required(msAttributes)}
                                             isCreating={isCreating}
                                             onCreate={onCreateAttribute(MoySkladOrderFeeType.Sorting)}
                                             labelName={'Обработка заказа'}/>

          <AdditionalPropertiesSettingsField value={intakeSortingId}
                                             setValue={setIntakeSortingId}
                                             options={required(msAttributes)}
                                             isCreating={isCreating}
                                             onCreate={onCreateAttribute(MoySkladOrderFeeType.IntakeSorting)}
                                             labelName={'Организация забора заказов со склада продавца'}/>

          <AdditionalPropertiesSettingsField value={returnProcessingId}
                                             setValue={setReturnProcessingId}
                                             options={required(msAttributes)}
                                             isCreating={isCreating}
                                             onCreate={onCreateAttribute(MoySkladOrderFeeType.ReturnProcessing)}
                                             labelName={'Обработка заказов на складе'}/>

          <AdditionalPropertiesSettingsField value={illiquidGoodsSaleId}
                                             setValue={setIlliquidGoodsSaleId}
                                             options={required(msAttributes)}
                                             isCreating={isCreating}
                                             onCreate={onCreateAttribute(MoySkladOrderFeeType.IlliquidGoodsSale)}
                                             labelName={'Вознаграждение за продажу невывезенных товаров'}/>

        </Grid>

        <AdditionalPropertiesSettingsActionButtons isLoading={isLoading} onSubmit={onSubmit}/>

      </Grid>
    )
  })

const useStyles = makeStyles(() => ({
  section: {
    fontSize: '18px',
    fontWeight: 600,
    lineHeight: '21.6px',
    textAlign: 'left',
    margin: '30px 10px 30px 10px'
  }
}))