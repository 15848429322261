import { exactKeyOf } from './tsUtils'
import { MoySkladAppScreenParams } from '../types/moySkladAppTypes'

const moySkladAppRouteBase = '/moySkladApp'

const moySkladAppRoute = {
  template: `${moySkladAppRouteBase}/:${exactKeyOf<MoySkladAppScreenParams>()('appType')}`
} as const

export const moySkladAppRoutes = {
  MoySkladApp: moySkladAppRoute,
  MoySkladAppOld: moySkladAppRouteBase
}