export enum MyProductColumnGroupId {
  MyStorage = 'MyStorage',
  Ozon = 'Ozon',
  Wildberries = 'Wildberries',
  YandexMarket = 'YandexMarket'
}

export const myProductColumnGroupNames: {
  [Group in MyProductColumnGroupId]: string
} = {
  [MyProductColumnGroupId.MyStorage]: 'МойСклад',
  [MyProductColumnGroupId.Ozon]: 'Ozon',
  [MyProductColumnGroupId.Wildberries]: 'Wildberries',
  [MyProductColumnGroupId.YandexMarket]: 'Yandex.Market'
}