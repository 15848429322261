import { makeObservable, reaction } from 'mobx'

import { ServerTableData } from './serverTableData'

import { NavStore } from '../types/navStore'
import { MyProductColumnId } from '../types/myProductColumnIds'

import { MyProductModel } from '../server/mpsklad_core/Models/MyProductModel'
import { MyProductsTableFilterColumn } from '../server/mpsklad_core/Models/MyProductsTableFilterColumn'
import { MyProductsTableOrderColumn } from '../server/mpsklad_core/Models/MyProductsTableOrderColumn'

export class MyProductsTableData
  extends ServerTableData<MyProductModel, MyProductColumnId,
    typeof MyProductsTableFilterColumn, typeof MyProductsTableOrderColumn> {
  constructor(getHomeNav: () => NavStore | null) {
    super('myProducts', MyProductsTableFilterColumn, MyProductsTableOrderColumn)

    makeObservable(this)

    reaction(() => getHomeNav()?.integrationType, () => this.reload())

    reaction(() => getHomeNav()?.accountId, () => this.reload())

    reaction(() => getHomeNav()?.productsStateTab, this.loadWithPageReset)
  }
}