import React from 'react'
import { observer } from 'mobx-react-lite'

import { TableCell } from '@material-ui/core'

import { makeMyProductRowId } from './MyProductSelectCell'

import { MyRowWithTableProps } from '../../types/tableTypes'
import { MyProductOnlyColumnId } from '../../types/myProductColumnIds'
import { MyProductModel } from '../../server/mpsklad_core/Models/MyProductModel'
import clsx from 'clsx'

export const MyProductNameCell =
  observer(
    ({row, isSelected, table: {toggleRowSelection}}: MyRowWithTableProps<MyProductOnlyColumnId, MyProductModel>) =>
      <TableCell
        scope="row"
        component="th"
        className={clsx('table-border', isSelected && 'selected_row')}
        id={makeMyProductRowId(row)}
        onClick={() => toggleRowSelection(row)}
        style={{padding: '8px', cursor: 'pointer', minWidth: 200}}
      >
        <span>{row.name}</span>
      </TableCell>
  )