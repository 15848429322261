import React, { useState } from 'react'
import { observer } from 'mobx-react-lite'
import clsx from 'clsx'

import { Box, FormHelperText, FormLabel, Grid, Typography } from '@material-ui/core'

import { IOSSwitch } from '../../IOSSwitch'
import { useMessages } from '../../../hooks/snackbarHooks'
import { useApi, useLogic, useStore } from '../../../hooks/storeHook'
import { useBoolState, useToggleState } from '../../../hooks/commonHooks'
import { DefaultSelect } from '../../DefaultSelect'

import { MoySkladApiEntityOption } from '../../../server/mpsklad_core/Models/MoySkladApiEntityOption'
import { useSettingsPageStyles } from '../../../hooks/settingsPageStylesHook'

export type OzonReturnsSettingsProps = {
  msStores: MoySkladApiEntityOption[]
}

export const OzonReturnsSettings =
  observer(({msStores}: OzonReturnsSettingsProps) => {
    const {showSuccess, showError} = useMessages()

    const logic = useLogic()
    const store = useStore()

    const {userSync: {setOzonReturnsSettings}} = useApi()

    const account = store.getHomeOzonAccount()

    const [isLoading, setIsLoading, setNotLoading] = useBoolState()

    const [msSalesReturnStoreId, setMsSalesReturn] = useState<string | undefined>(account?.msSalesReturnStoreId)

    const [autoReturnsEnabled, toggleAutoReturnEnabled] = useToggleState(account?.isAutoReturnEnabled ?? false)

    const classes = useSettingsPageStyles()

    const onSubmit = async () => {
      try {
        setIsLoading()

        await setOzonReturnsSettings({
          accountId: account.id,
          isAutoReturnEnabled: autoReturnsEnabled,
          msSalesReturnId: msSalesReturnStoreId
        })

        setTimeout(logic.loadUserAccounts)

        showSuccess('Настройки возвратов обновлены!')
      } catch (e) {
        showError('Произошла ошибка при настройке возвратов!')
      } finally {
        setNotLoading()
      }
    }

    const msStoreOptions = msStores.map(_ => ({
      value: _.id,
      label: _.name
    }))

    return (
      <Box className={classes.pageContainer}>
        <Typography className={classes.header}>НАСТРОЙКИ ВОЗВРАТОВ</Typography>

        <Grid container spacing={3}>
          <Grid item xs={7}>
            <Grid container className={clsx(classes.settingsTopic, 'default-border')}>
              <Grid item className={classes.root} xs={12}>
                <Box>
                  <FormLabel component="legend" className={classes.label}>
                    <span className={classes.dash}>—</span>
                    Склад возвратов МоегоСклада
                  </FormLabel>
                </Box>

                <DefaultSelect
                  value={msSalesReturnStoreId}
                  onChange={setMsSalesReturn}
                  options={msStoreOptions}
                  withUnselectedValue
                  fullWidth
                  placeholder="Выберите склад"
                />

                <Box marginTop={2}>
                  <FormHelperText>
                    Укажите отдельный склад для возвратов, отличный от основных настроек складов.
                    <br/>
                    Например, склад "Возвраты".
                  </FormHelperText>
                </Box>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={7}>
            <Grid container className={clsx(classes.settingsTopic, 'default-border')}>
              <Grid item className={classes.root} xs={12}>
                <Box width={'100%'} display="flex" justifyContent="space-between" alignItems="center">
                  <FormLabel className={classes.unifiedPricesLabel}>
                    Создание документов о возврате
                  </FormLabel>

                  <IOSSwitch checked={autoReturnsEnabled} onChange={toggleAutoReturnEnabled}/>
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Box display="flex">
          <button
            className={clsx(classes.saveButton, 'default-button')}
            disabled={isLoading}
            onClick={onSubmit}>
            <p>{isLoading ? 'Сохраняем...' : 'Сохранить'}</p>
          </button>
        </Box>
      </Box>
    )
  })