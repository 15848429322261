import React from 'react'
import { observer } from 'mobx-react-lite'

import { TableCell } from '@material-ui/core'

import { formatDateTimeUnix } from '../../common/dateTimeUtility'

import { MyRowProps } from '../../types/tableTypes'
import { UserModel } from '../../server/mpsklad_core/Models/UserModel'
import clsx from 'clsx'

export const TableDateCell =
  observer(
    <TProps extends MyRowProps<{createdAt: number}>>
    ({row, isSelected}: TProps) =>
      <TableCell className={clsx('table-border', isSelected && 'selected_row')}>
        <div>{formatDateTimeUnix(row.createdAt)}</div>
      </TableCell>
  )

export const TableDateCellLastOrder =
  observer(
    ({row, isSelected}: MyRowProps<UserModel>) =>
      <TableCell className={clsx('table-border', isSelected && 'selected_row')}>
        {
          row.lastOrderDateUnix
          ? formatDateTimeUnix(row.lastOrderDateUnix)
          : 'нет заказов'
        }
      </TableCell>
  )