import React from 'react'
import { observer } from 'mobx-react-lite'

import { createStyles, makeStyles } from '@material-ui/core/styles'
import { Checkbox, FormControlLabel, MenuItem } from '@material-ui/core'

import { useLogic, useStore } from '../hooks/storeHook'

import { MyProductColumn } from '../types/myProductColumnTypes'
import { useDefaultMenuItemStyles } from '../hooks/defaultSelectStylesHook'
import { CheckCircle } from '@material-ui/icons'
import clsx from 'clsx'
import CircleUnchecked from '@material-ui/icons/RadioButtonUnchecked'

export const MyProductsColumnCheckbox =
  observer(
    ({column}: {column: MyProductColumn}) => {
      const classes = useStyles()
      const defaultMenuClasses = useDefaultMenuItemStyles()

      const {hiddenMyProductColumnIds} = useStore()
      const {toggleMyProductsColumn} = useLogic()

      const isChecked = !hiddenMyProductColumnIds.has(column.id)

      const onClick = (e: React.BaseSyntheticEvent) => {
        // Fix multiple click events
        e.preventDefault()
        e.stopPropagation()
        toggleMyProductsColumn(column.id)
      }

      return (
        <MenuItem dense key={column.id} onClick={onClick}
                  classes={{root: clsx(defaultMenuClasses.menuItem, classes.menuItem)}}>
          <FormControlLabel
            control={
              <Checkbox
                className={classes.checkbox}
                size="small"
                checked={isChecked}
                checkedIcon={<CheckCircle/>}
                icon={<CircleUnchecked style={{color: '#3987CF'}}/>}
                disableRipple
              />}
            label={column.title}
            classes={{label: clsx(classes.label, isChecked && classes.checkedLabel)}}
          />
        </MenuItem>
      )
    })

const useStyles =
  makeStyles((/*theme: Theme*/) =>
    createStyles({
      menuItem: {
        padding: 0
      },
      label: {
        fontSize: 10,
        color: '#1F364D'
      },
      checkedLabel: {
        color: '#3987CF',
        fontWeight: 'bold'
      },
      checkbox: {
        backgroundColor: 'transparent !important',
        width: 10,
        height: 8
      }
    }))