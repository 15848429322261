import React, { useRef, useState, useCallback } from 'react'
import { observer } from 'mobx-react-lite'
import { ClickAwayListener, Grow, List, MenuItem, Paper } from '@material-ui/core'
import { MyProductsColumnItem } from './MyProductsColumnItem'
import { useStore } from '../hooks/storeHook'
import { useDefaultMenuItemStyles } from '../hooks/defaultSelectStylesHook'
import { keysOf } from '../common/tsUtils'
import column from '../assets/column.svg'

export const MyProductsColumnsMenu = observer(
  () => {
    const {myProductColumnsGrouped} = useStore()
    const classes = useDefaultMenuItemStyles()
    const anchorRef = useRef<HTMLDivElement>(null)
    const [open, setOpen] = useState(false)

    const handleToggle = useCallback(() => {
      setOpen(prevOpen => !prevOpen)
    }, [])

    const handleClose = useCallback((event: React.MouseEvent<Document, MouseEvent>) => {
      if (anchorRef.current && anchorRef.current.contains(event.target as Node)) {
        return
      }
      setOpen(false)
    }, [])

    return (
      <ClickAwayListener onClickAway={handleClose}>
        <div ref={anchorRef} style={{position: 'relative'}}>
          <button
            className={'btn'}
            onClick={handleToggle}
          >
            <p>Столбцы</p>
            <img src={column} alt={'Столбцы'}/>
          </button>

          {open && (
            <Grow in={open} style={{transformOrigin: 'top'}}>
              <Paper className={classes.menu} style={{position: 'absolute', zIndex: 1, minHeight: '400px'}}>
                <List component="nav">
                  {keysOf(myProductColumnsGrouped).map(groupId => (
                    !!myProductColumnsGrouped[groupId].length && (
                      <MenuItem key={groupId} className={classes.menuItem} style={{fontSize: '12px !important'}}>
                        <MyProductsColumnItem groupId={groupId}/>
                      </MenuItem>
                    )
                  ))}
                </List>
              </Paper>
            </Grow>
          )}
        </div>
      </ClickAwayListener>
    )
  }
)