import React from 'react'
import { observer } from 'mobx-react-lite'

import { TableCell } from '@material-ui/core'

import { SyncLogsButton } from './SyncLogsButton'
import { CombinedProductSyncLogsButton } from './CombinedProductSyncLogsButton'

import { useStore } from '../../hooks/storeHook'

import { MyRowWithTableProps } from '../../types/tableTypes'
import { MyProductOnlyColumnId } from '../../types/myProductColumnIds'

import { MyProductModel } from '../../server/mpsklad_core/Models/MyProductModel'
import { IntegrationType } from '../../server/mpsklad_core/Entity/IntegrationType'

export const MyProductsSyncLogsCell =
  observer(
    ({row: msProduct}: MyRowWithTableProps<MyProductOnlyColumnId, MyProductModel>) => {
      const {navState: {integrationType, accountId}} = useStore()

      return (
        <TableCell>
          <div style={{display: 'inline-flex'}}>
            <SyncLogsButton
              themed
              type={IntegrationType.MoySklad}
              productId={msProduct.id}
            />

            {
              msProduct.ozonProducts.map(
                ozonProduct =>
                  <SyncLogsButton
                    themed
                    key={ozonProduct.id}
                    type={IntegrationType.Ozon}
                    accountId={ozonProduct.accountId}
                    productId={ozonProduct.id}/>)
            }

            {
              msProduct.wbProducts.map(
                wbProduct =>
                  <SyncLogsButton
                    themed
                    key={wbProduct.id}
                    type={IntegrationType.Wildberries}
                    accountId={wbProduct.accountId}
                    productId={wbProduct.id}/>)
            }

            {
              msProduct.yandexMarketProducts.map(
                yandexMarketProduct =>
                  <SyncLogsButton
                    themed
                    key={yandexMarketProduct.id}
                    type={IntegrationType.YandexMarket}
                    accountId={yandexMarketProduct.accountId}
                    productId={yandexMarketProduct.id}/>)
            }

            <CombinedProductSyncLogsButton integrationType={integrationType} accountId={accountId}
                                           msProductId={msProduct.id}/>
          </div>
        </TableCell>
      )
    })