import React from 'react'
import { observer } from 'mobx-react-lite'
import { Box } from '@material-ui/core'
import { Route, Switch } from 'react-router-dom'

import { useStore } from '../../hooks/storeHook'

import { appRoutes } from '../../common/appRoutes'

import { WildberriesPrintPage } from './Wildberries/WildberriesPrintPage'
import { OzonPrintPage } from './Ozon/OzonPrintPage'
import { YandexMarketPrintPage } from './YandexMarket/YandexMarketPrintPage'

import { IntegrationType } from '../../server/mpsklad_core/Entity/IntegrationType'
import { useValidateStoreAccountRoute } from '../../hooks/validateStoreAccountRoute'

export const PrintRoute = observer(() => {
  const {integration} = useValidateStoreAccountRoute(appRoutes.CommonStore.print)

  const {allowedIntegrations} = useStore()

  return (
    <div className="body_wrapper_zero">
      <Box flex={1}>
        <Switch>
          {
            allowedIntegrations[IntegrationType.YandexMarket] &&
            integration === 'YandexMarket' &&
            <Route
              path={appRoutes.CommonStore.print.template}
              component={YandexMarketPrintPage}/>
          }

          {
            allowedIntegrations[IntegrationType.Wildberries] &&
            integration === 'Wildberries' &&
            <Route path={appRoutes.CommonStore.print.template}
                   component={WildberriesPrintPage}/>
          }

          {
            allowedIntegrations[IntegrationType.Ozon]
            && integration === 'Ozon' &&
            <Route path={appRoutes.CommonStore.print.template}
                   component={OzonPrintPage}/>
          }
        </Switch>
      </Box>
    </div>
  )
})