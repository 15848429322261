import React from 'react'
import { useDefaultInputStyles } from '../hooks/defaultInputStylesHook'
import { BootstrapInput } from './BootstrapInput'

export type PropFilterItemBase = {
  placeholder: string

  value: string | undefined

  setValue: (filterValue: string | undefined) => void
}

export type StringPropFilterItem =
  PropFilterItemBase & {
  isBoolean?: false
}

export const StringPropFilter =
  ({placeholder, value, setValue}: StringPropFilterItem) => {
    const classes = useDefaultInputStyles()

    return <BootstrapInput className={classes.input} type="text" placeholder={placeholder}
                           value={value ?? ''}
                           onChange={e => setValue(e.target.value)}/>
  }