import React from 'react'
import { observer } from 'mobx-react-lite'
import { appRoutes } from '../../common/appRoutes'
import { useValidateStoreAccountRoute } from '../../hooks/validateStoreAccountRoute'
import { ProductsScreenTable } from './ProductsScreenTable'

export const ProductsRoute =
  observer(() => {
    useValidateStoreAccountRoute(appRoutes.CommonStore.products)

    return (
      <div className="body_wrapper_zero border_left" style={{marginRight: 20}}>
        <ProductsScreenTable/>
      </div>
    )
  })