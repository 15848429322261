import { Box, CircularProgress, IconButton } from '@material-ui/core'
import { Add } from '@material-ui/icons'
import { useDefaultMenuItemStyles } from '../hooks/defaultSelectStylesHook'
import { observer } from 'mobx-react-lite'
import { MoySkladAttributeModel } from '../server/mpsklad_core/Models/MoySkladAttributeModel'
import { DefaultSelect } from './DefaultSelect'

export type MoySkladAttributeSelectProps = {
  value: string | undefined

  setValue: (value: string | undefined) => void

  options: MoySkladAttributeModel[]

  isCreating: boolean

  onCreate: () => Promise<unknown>
}

export const MoySkladAttributeSelect =
  observer(
    ({value, setValue, options, isCreating, onCreate}: MoySkladAttributeSelectProps) => {

      const classes = useDefaultMenuItemStyles()

      const selectOptions = options.map(option => ({
        value: option.id,
        label: option.name
      }))

      return (
        <Box display="flex" alignItems="center">
          <DefaultSelect<string>
            value={value}
            onChange={setValue}
            options={selectOptions}
            fullWidth
            placeholder="&mdash;"
          />

          <IconButton
            title="Создать новое поле"
            disabled={isCreating}
            onClick={onCreate}
            style={{marginLeft: 10}}
            className={classes.iconButtonOutlined}
          >
            {isCreating ? <CircularProgress size={20}/> : <Add/>}
          </IconButton>
        </Box>
      )
    })