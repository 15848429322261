import React from 'react'
import { observer } from 'mobx-react-lite'

import { TableCell } from '@material-ui/core'

import { FormValidationErrors } from '../FormValidationErrors'

import { useLogic } from '../../hooks/storeHook'
import { useSubmitWithValidation } from '../../hooks/submitWithValidationHook'

import { MyRowProps } from '../../types/tableTypes'
import { MyProductModel } from '../../server/mpsklad_core/Models/MyProductModel'
import { ExternalProductOption } from '../../server/mpsklad_core/Models/ExternalProductOption'
import { YandexMarketProductPicker } from '../Tables/YandexMarketProductPicker'
import { makeYandexMarketProductLabel } from '../../store/logic/productUtils'
import clsx from 'clsx'

export const MyProductYandexMarketCell =
  observer(
    ({row, isSelected}: MyRowProps<MyProductModel>) => {
      const {setYandexMarketProducts} = useLogic()

      const {
        submit,
        validationErrors
      } = useSubmitWithValidation(
        (yandexMarketProducts: ExternalProductOption[]) =>
          setYandexMarketProducts(row.id, yandexMarketProducts.map(_ => _.id)))

      const yandexMarketProducts = row.yandexMarketProducts.map(
        _ => ({
          id: _.id,
          label: makeYandexMarketProductLabel(_)
        }))

      return (
        <TableCell className={clsx('table-border', isSelected && 'selected_row')} style={{padding: 8}}>
          <YandexMarketProductPicker
            size="small"
            msProductId={row.id}
            products={yandexMarketProducts}
            onChange={submit}
          />

          <FormValidationErrors validationErrors={validationErrors}/>
        </TableCell>
      )
    })