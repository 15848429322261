import React from 'react'
import { observer } from 'mobx-react-lite'
import { addDays, fromUnixTime, getUnixTime, startOfDay } from 'date-fns'

import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date'

import { useLogic } from '../hooks/storeHook'
import { useBoolState } from '../hooks/commonHooks'

import { dateUtils } from '../common/dateTimeUtility'

import { AccountModelBase } from '../server/mpsklad_core/Models/AccountModelBase'
import { SetSyncStartDateModel } from '../server/mpsklad_core/Models/SetSyncStartDateModel'
import IOSDateTimePicker from './IOSDateTimePicker'

const maxDate = startOfDay(dateUtils.now)
const minDate = addDays(maxDate, -30)

export type SyncStartTableRowProps = {
  account: AccountModelBase

  onSubmit?: (model: SetSyncStartDateModel) => Promise<void>
}

export const SyncStartTableRow =
  observer(
    ({account, onSubmit}: SyncStartTableRowProps) => {
      const {showDialog} = useLogic()

      const [isSubmitting, setSubmitting, setSubmitted] = useBoolState()

      if (!account.ordersTrackStartUnix) {
        return null
      }

      const syncStartDate = fromUnixTime(account.ordersTrackStartUnix)

      const onSyncStartDateChange =
        async (newDate: MaterialUiPickersDate) => {
          if (newDate == null || isSubmitting || !onSubmit) {
            return
          }

          if (!await showDialog('При изменении даты на более позднюю недостающие заказы будут созданы в МоёмСкладе. '
                                + 'Статусы существующих заказов будут обновлены.')) {
            return
          }

          setSubmitting()

          try {
            newDate = startOfDay(newDate)
            const syncStartDateUnix = getUnixTime(newDate)

            await onSubmit({accountId: account.id, syncStartDateUnix})

            account.ordersTrackStartUnix = syncStartDateUnix
          } finally {
            setSubmitted()
          }
        }

      return (
        <IOSDateTimePicker
          maxDate={maxDate}
          minDate={minDate}
          value={syncStartDate}
          disabled={!account.syncEnabled || !onSubmit || isSubmitting}
          onChange={onSyncStartDateChange}
          fullWidth
        />
      )
    })