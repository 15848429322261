import React from 'react'
import clsx from 'clsx'

import { makeStyles } from '@material-ui/core/styles'
import { Box, FormHelperText, FormLabel } from '@material-ui/core'

import { IOSSwitch, IOSSwitchProps } from './IOSSwitch'

export type LabeledIOSSwitchProps =
  IOSSwitchProps
  & {
  label: string

  switchLabel: string

  helperText?: string

  classes?: {
    label?: string

    dash?: string

    toggleLabel?: string

    helperText?: string
  }
}

export const LabeledIOSSwitch =
  ({
     label,
     switchLabel,
     helperText,
     classes = {},
    ...passProps
   }: LabeledIOSSwitchProps) => {
    const defaultClasses = useSettingToggleSectionStyles()

    return <>
      <Box>
        <FormLabel component="legend" className={clsx(defaultClasses.label, classes.label)}>
          {/* @formatter:off */}
          <span className={clsx(defaultClasses.dash, classes.dash)}>&mdash;</span>{label}
          {/* @formatter:on */}
        </FormLabel>
      </Box>

      <Box width="100%" display="flex" justifyContent="space-between" alignItems="center">
        <FormLabel className={clsx(defaultClasses.switchLabel, classes.toggleLabel)}>
          {switchLabel}
        </FormLabel>

        <IOSSwitch {...passProps}/>
      </Box>

      {
        !!helperText &&
        <FormHelperText className={clsx(defaultClasses.helperText, classes.helperText)}>
          {helperText}
        </FormHelperText>
      }
    </>
  }

const useSettingToggleSectionStyles =
  makeStyles(() => ({
    label: {
      fontSize: '12px',
      fontWeight: 400,
      lineHeight: '14.4px',
      textAlign: 'left',
      marginBottom: 15
    },
    dash: {
      color: '#3987CF',
      marginRight: 3
    },
    switchLabel: {
      fontSize: '12px',
      fontWeight: 600,
      lineHeight: '14.4px',
      textAlign: 'left',
      color: '#1F364D'
    },
    helperText: {
      fontSize: '11px',
      fontWeight: 400,
      lineHeight: '12px',
      textAlign: 'left',
      marginTop: 5
    }
  }))