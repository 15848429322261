import React from 'react'
import { observer } from 'mobx-react-lite'
import { sortBy } from 'lodash'

import { FormHelperText } from '@material-ui/core'

import { CustomSelectOption, DefaultSelect } from './DefaultSelect'

import { required } from '../common/objectUtils'
import { emDash } from '../common/stringUtility'

import { EditStoreModel } from '../server/mpsklad_core/Models/EditStoreModel'
import { WarehouseModel } from '../server/mpsklad_core/Models/WarehouseModel'
import { EditStoresModel } from '../server/mpsklad_core/Models/EditStoresModel'

export type StorePickerBaseProps = {
  formData: EditStoresModel
}

export type WarehouseDisplayProps<TWarehouse extends WarehouseModel> = {
  formatWarehouseDisplayName: (warehouse: TWarehouse) => string
}

export type WarehouseKey = number | string

export type WarehouseKeyProps<TWarehouse extends WarehouseModel> = {
  whKeySelector: (warehouse: TWarehouse) => WarehouseKey

  storeWhKeySelector: (store: EditStoreModel) => WarehouseKey
}

export type WarehousePickerProps<TWarehouse extends WarehouseModel> =
  StorePickerBaseProps
  & WarehouseKeyProps<TWarehouse>
  & WarehouseDisplayProps<TWarehouse>
  & {
  formStore: EditStoreModel

  warehouses: TWarehouse[]

  onChange: (warehouse: TWarehouse) => void
}

export const WarehousePicker =
  observer(
    <TWarehouse extends WarehouseModel>
    ({
       formStore, formData, warehouses,
       whKeySelector, storeWhKeySelector,
       formatWarehouseDisplayName,
       onChange
     }: WarehousePickerProps<TWarehouse>) => {
      const selectedWhKey = storeWhKeySelector(formStore)
      const selectedWarehouse = warehouses.find(_ => whKeySelector(_) === selectedWhKey)

      const warehouseOptions =
        sortBy(
          warehouses.filter(wh => formData.stores.every(_ => storeWhKeySelector(_) !== whKeySelector(wh)))
                    .concat(selectedWarehouse ?? []),
          whKeySelector)
          .map(warehouse => ({
              value: warehouse,
              label: formatWarehouseDisplayName(warehouse)
            } satisfies CustomSelectOption<TWarehouse>
          ))

      const onSelectChange =
        (newWarehouse: TWarehouse | undefined) =>
          onChange(required(newWarehouse))

      return <>
        {
          !selectedWarehouse &&
          <FormHelperText error>Не найден в маркетплейсе: {selectedWhKey}</FormHelperText>
        }

        <DefaultSelect
          fullWidth
          placeholder={emDash}
          value={selectedWarehouse}
          options={warehouseOptions}
          onChange={onSelectChange}
        />
      </>
    })