import { useHistory } from 'react-router-dom'
import { useLogic, useStore } from './storeHook'
import { useEffect } from 'react'
import { required } from '../common/objectUtils'

import { RouteWithParams } from '../common/routeUtils'
import { IntegrationType } from '../server/mpsklad_core/Entity/IntegrationType'

import { isIntegrationType } from '../types/integrationTypeUtils'

import { appRoutes, StoreRouteParams } from '../common/appRoutes'

export const useValidateStoreAccountRoute = (
  baseRouteForStore: RouteWithParams<StoreRouteParams>) => {
  const history = useHistory()
  const params = baseRouteForStore.parse(history.location.pathname)
  const store = useStore()
  const logic = useLogic()

  useEffect(() => {
    if (params) {
      let accountId: number | undefined = Number(params.accountId)
      const integration = required(params.integration)

      const possibleAccount = logic.tryGetAccount(accountId, integration)

      if ((!possibleAccount && accountId !== 0) || !isIntegrationType(integration)) {

        const route = integration === 'MoySklad' ? appRoutes.MoySklad.settings : baseRouteForStore

        history.push(route.route({accountId: 0, integration}))

        accountId = undefined
      }

      const navStoreType = integration !== 'MoySklad'
                           ? IntegrationType[integration]
                           : IntegrationType.Ozon

      store.setHomeNav(
        navStoreType,
        accountId)
    }
  }, [history, params, store, logic, baseRouteForStore])

  return {...params}
}