import React, { useCallback } from 'react'
import { observer } from 'mobx-react-lite'

import { WbStoresForm } from '../../WbStoresForm'
import { PricesSettings } from '../PricesSettings'
import { CompanySettings } from '../CompanySettings'
import { StoreSettingsPage } from '../StoreSettingsPage'
import { CommissionsSettings } from '../CommissionsSettings'
import { WildberriesApiSettings } from './WildberriesApiSettings'
import { WildberriesOrdersSettings } from './WildberriesOrdersSettings'
import { WildberriesReturnsSettings } from './WildberriesReturnsSettings'

import { useMessages } from '../../../hooks/snackbarHooks'
import { useLogic, useStore } from '../../../hooks/storeHook'

import { EditWbAccountModel } from '../../../server/mpsklad_core/Models/EditWbAccountModel'
import { WbDeliveryType } from '../../../server/mpsklad_core/Entity/Wildberries/WbDeliveryType'
import { CommissionsRecordingType } from '../../../server/mpsklad_core/Entity/Base/CommissionsRecordingType'
import { EditStoreAccountMsDataModel } from '../../../server/mpsklad_core/Models/EditStoreAccountMsDataModel'

export const WildberriesSettingsPage = observer(() => {
  const logic = useLogic()
  const store = useStore()
  const {showSuccess} = useMessages()

  const accountId = store.getHomeNav()?.accountId
  const account = logic.tryGetWbAccount(accountId)

  const onSubmit = useCallback(
    async (data: EditStoreAccountMsDataModel) => {
      try {
        const formData: Omit<EditWbAccountModel, 'id'> = {
          deliveryType: account?.deliveryType ?? WbDeliveryType.FBW,
          isUpdatePrices: account?.isUpdatePrices ?? false,
          isGeneratingPayments: account?.isGeneratingPayments ?? false,
          isUsingCommissions: account?.isUsingCommissions ?? false,
          ordersCommissionsRecording:
            account?.ordersCommissionsRecording ?? CommissionsRecordingType.Nothing,
          documentsCommissionsRecording:
            account?.documentsCommissionsRecording ?? CommissionsRecordingType.Nothing,
          tokenCombined: account?.tokenCombined,
          name: account?.name,
          msSalesReturnStoreId: account?.msSalesReturnStoreId,
          msData: data
        }

        if (account) {
          await logic.editWbAccount({id: account.id, ...formData})
        } else {
          await logic.createWbAccount({id: 0, ...formData})
        }

        showSuccess('Сохранено!')
      } catch (e) {
        console.error('Failed to save Wildberries account', e)
      }
    },
    [account, logic, showSuccess]
  )

  return (
    <StoreSettingsPage
      integrationTypeKey={'Wildberries'}
      account={account}
      apiSettingsComponent={WildberriesApiSettings}
      companySettingsComponent={CompanySettings}
      ordersSettingsComponent={WildberriesOrdersSettings}
      warehousesSettingsComponent={WbStoresForm}
      returnsSettingsComponent={WildberriesReturnsSettings}
      commissionsSettingsComponent={CommissionsSettings}
      pricesSettingsComponent={(props) => (
        <PricesSettings
          {...props}
          onCheckPrices={logic.api.prices.checkPriceActivationWb}
          onEnablePriceSync={logic.api.prices.enablePriceSyncWb}
          onForceUpdatePrices={() => logic.api.userSync.updatePricesWb(props.account.id)}
        />
      )}
      onSubmit={onSubmit}
    />
  )
})