import React from 'react'
import { observer } from 'mobx-react-lite'

import { TablePagination2 } from './TablePagination'

import { useStore } from '../hooks/storeHook'

export const MyProductsTopPagination =
  observer(() => {
    const {productStore: {myProducts}} = useStore()

    return (
      <div
        style={{
          position: 'absolute',
          top: '80px',
          right: '0'
        }}
      >
        <TablePagination2
          table={myProducts}
          labelRowsPerPage="Товаров на странице:"
          style={{
            position: 'absolute',
            top: 0,
            right: 90,
            color: '#888888',
            fontSize: 10
          }}
        />
      </div>
    )
  })