import React from 'react'

import { MatchProductsButton } from './MatchProductsButton'
import { MatchButtonProps } from './MatchOzonToMoySkladButton'

import { useLogic } from '../hooks/storeHook'

import { YandexMarketProductProp } from '../server/mpsklad_core/Models/YandexMarketProductProp'
import { MsProductProp } from '../server/mpsklad_core/Models/MsProductProp'

export const MatchYandexMarketToMoySkladButton =
  (props: MatchButtonProps) => {
    const logic = useLogic()

    return <MatchProductsButton
      buttonText={`С ${props.accountIdFromTitle ?? ''}`}
      title={`Сопоставление товаров ${props.accountIdToTitle ?? ''} и МоегоСклада`}
      fromProps={[
        [YandexMarketProductProp.ShopSku, 'Ваш SKU'],
        [YandexMarketProductProp.Name, 'Название'],
        [YandexMarketProductProp.VendorCode, 'Артикул производителя'],
        [YandexMarketProductProp.Barcode, 'Штриход']
      ]}
      toProps={[
        [MsProductProp.Id, 'GUID'],
        [MsProductProp.Code, 'Код'],
        [MsProductProp.Article, 'Артикул'],
        [MsProductProp.Name, 'Название'],
        [MsProductProp.BarcodeGtin, 'Штрихкод GTIN'],
        [MsProductProp.BarcodeEan8, 'Штрихкод EAN8'],
        [MsProductProp.BarcodeEan13, 'Штрихкод EAN13'],
        [MsProductProp.BarcodeCode128, 'Штрихкод Code128']
      ]}
      submit={logic.matchYandexMarketToMoySkladProducts}
      {...props}
    />
  }