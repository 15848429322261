import React from 'react'
import { observer } from 'mobx-react-lite'

import { ListItem, ListItemText } from '@material-ui/core'

import { DropdownButton } from './DropdownButton'

import { useApi, useStore } from '../hooks/storeHook'
import { useDefaultMenuItemStyles } from '../hooks/defaultSelectStylesHook'

export const ExportProductsButton =
  observer(() => {
    const menuClasses = useDefaultMenuItemStyles()

    const api = useApi()

    const {hasMoySkladApp, navState: {integrationType, accountId}} = useStore()

    let exportUrl = api.product.exportAllToXlsUrl(integrationType, accountId)

    if (hasMoySkladApp && api.headerAuthToken != null) {
      exportUrl += `${(exportUrl.includes('?') ? '&' : '?')}Authorization=${encodeURIComponent(api.headerAuthToken)}`
    }

    return (
      <DropdownButton text="Экспорт" popupWidth={120}>
        {
          buttonProps =>
            <ListItem
              className={menuClasses.menuItem}
              button
              component="a"
              href={exportUrl}
              target="_blank"
              onClick={buttonProps.setClosed}
            >
              <ListItemText primary="В файл .xls"/>
            </ListItem>
        }
      </DropdownButton>
    )
  })