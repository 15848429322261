import React from 'react'
import { observer } from 'mobx-react-lite'

import clsx from 'clsx'

import { makeStyles } from '@material-ui/core/styles'
import { TableCell } from '@material-ui/core'

import { MyTableHead } from './MyTableHead'

import { useStore } from '../../hooks/storeHook'

import { MyProductColumnGroupId } from '../../types/myProductColumnGroups'

export const MyProductsTableHead =
  observer(() => {
    const classes = useStyles()

    const {
      myProductColumns,
      visibleMyProductColumns,
      productStore: {myProducts}
    } = useStore()

    const getColSpan = (group: MyProductColumnGroupId) =>
      myProductColumns.filter(_ => _.group === group && visibleMyProductColumns.includes(_)).length

    const myColSpan = getColSpan(MyProductColumnGroupId.MyStorage)
    const ozonColSpan = getColSpan(MyProductColumnGroupId.Ozon)
    const wbColSpan = getColSpan(MyProductColumnGroupId.Wildberries)
    const ymColSpan = getColSpan(MyProductColumnGroupId.YandexMarket)

    // Utility to check if it's the last visible column group
    const isLastGroupVisible = (group: MyProductColumnGroupId) => {
      const allVisibleGroups = [myColSpan, ozonColSpan, wbColSpan, ymColSpan].filter(colSpan => colSpan > 0)
      return allVisibleGroups.length === 1 || allVisibleGroups[allVisibleGroups.length - 1] === getColSpan(group)
    }

    return (
      <MyTableHead
        table={myProducts}
        visibleColumns={visibleMyProductColumns}
        groupHeader={<>
          {
            myColSpan > 0 &&
            <TableCell
              colSpan={myColSpan}
              className={clsx(
                classes.groupCell,
                classes.moySklad,
            isLastGroupVisible(MyProductColumnGroupId.MyStorage) && classes.groupCellWithoutRightBorder
              )}
            >
              МойСклад
            </TableCell>
          }

          {
            ozonColSpan > 0 &&
            <TableCell
              colSpan={ozonColSpan}
              className={clsx(
                classes.groupCell,
                classes.ozon,
            isLastGroupVisible(MyProductColumnGroupId.Ozon) && classes.groupCellWithoutRightBorder
              )}
            >
              Ozon
            </TableCell>
          }

          {
            wbColSpan > 0 &&
            <TableCell
              colSpan={wbColSpan}
              className={clsx(
                classes.groupCell,
                classes.wildberries,
            isLastGroupVisible(MyProductColumnGroupId.Wildberries) && classes.groupCellWithoutRightBorder
              )}
            >
              Wildberries
            </TableCell>
          }

          {
            ymColSpan > 0 &&
            <TableCell
              colSpan={ymColSpan}
              className={clsx(
                classes.groupCell,
                classes.yandexMarket,
            isLastGroupVisible(MyProductColumnGroupId.YandexMarket) && classes.groupCellWithoutRightBorder
              )}
            >
              Yandex.Market
            </TableCell>
          }
        </>}
      />
    )
  })

const useStyles = makeStyles(
  ({palette}) => ({
      groupCell: {
        padding: '4px 15px',
        borderRight: `2px solid ${palette.mpSkladSecondary.lightGray}`
      },
      groupCellWithoutRightBorder: {
        borderRight: 'none',
        borderTopRightRadius: '5px'
      },
      moySklad: {
        background: palette.mpSkladPrimary.blue,
        color: palette.mpSkladSecondary.lightBlue,
        borderTopLeftRadius: '5px',
        height: '32px'
      },
      ozon: {
        background: '#005BFF',
        color: palette.mpSkladSecondary.lightBlue
      },
      wildberries: {
        background: '#BC3D96',
        color: palette.mpSkladSecondary.lightBlue
      },
      yandexMarket: {
        background: '#FC0',
        color: palette.mpSkladSecondary.lightBlue
      }
    }
  ))