import React, { useState } from 'react'
import { makeStyles, Theme } from '@material-ui/core/styles'
import {
  Dialog,
  DialogActions,
  DialogContent,
  Button,
  Box,
  Typography
} from '@material-ui/core'
import EventIcon from '@material-ui/icons/Event'
import AccessTimeIcon from '@material-ui/icons/AccessTime'
import { Calendar, MuiPickersUtilsProvider } from '@material-ui/pickers'
import clsx from 'clsx'
import DateFnsUtils from '@date-io/date-fns'
import { BootstrapInput } from './BootstrapInput'

interface CustomDateTimePickerProps {
  value: Date;

  onChange: (date: Date) => void;

  minDate?: Date;

  maxDate?: Date;

  disabled?: boolean;

  fullWidth?: boolean;
}

const CustomDateTimePicker: React.FC<CustomDateTimePickerProps> = ({
                                                                     value,
                                                                     onChange,
                                                                     minDate,
                                                                     maxDate,
                                                                     disabled = false,
                                                                     fullWidth = false
                                                                   }) => {
  const classes = useStyles()
  const pickerClasses = usePickerToolbarStyles()

  const [open, setOpen] = useState(false)
  const [selectedDate, setSelectedDate] = useState<Date | null>(value)
  const [currentView, setView] = useState<'date' | 'hours'>('date')
  const [activeInput, setActiveInput] = useState<'hours' | 'minutes' | null>(null)

  const [hours, setHours] = useState(() => String(selectedDate?.getHours()).padStart(2, '0'))
  const [minutes, setMinutes] = useState(() => String(selectedDate?.getMinutes()).padStart(2, '0'))

  const handleDateChange = (date: Date | null) => {
    if (date) {
      setSelectedDate(date)
    }
  }

  const handleFocus = (input: 'hours' | 'minutes') => {
    setActiveInput(input)
    if (input === 'hours') {
      setHours('')
    } else {
      setMinutes('')
    }
  }

  const handleBlur = () => {
    setActiveInput(null)
    setHours((prev) => String(Math.max(0, Math.min(23, Number(prev) || 0))).padStart(2, '0'))
    setMinutes((prev) => String(Math.max(0, Math.min(59, Number(prev) || 0))).padStart(2, '0'))
  }

  const handleTimeChange = (type: 'hours' | 'minutes', value: string) => {
    const cleanedValue = value.replace(/\D/g, '')
    const number = Math.max(0, Number(cleanedValue))

    if (selectedDate) {
      const newDate = new Date(selectedDate)
      if (type === 'hours') {
        const validHours = Math.min(23, number)
        setHours(validHours.toString())
        newDate.setHours(validHours)
      } else if (type === 'minutes') {
        const validMinutes = Math.min(59, number)
        setMinutes(validMinutes.toString())
        newDate.setMinutes(validMinutes)
      }
      setSelectedDate(newDate)
    }
  }

  const handleClose = () => {
    if (selectedDate && selectedDate !== value) {
      onChange(selectedDate)
    }
    setOpen(false)
  }

  const openPicker = () => {
    if (!disabled) {
      setOpen(true)
    }
  }

  return (
    <>
      <div className={classes.dateTimePicker}>
        <BootstrapInput
          value={selectedDate?.toLocaleString()}
          onClick={openPicker}
          disabled={disabled}
          fullWidth={fullWidth}
        />
      </div>
      <Dialog open={open} onClose={handleClose}>
        <Box padding={6}>
          <Box className={classes.dateTimePicker}>
            <Box className={clsx(pickerClasses.dateDisplay,
              currentView === 'date' ? pickerClasses.selected : pickerClasses.unselected)}
                 onClick={() => setView('date')}>
              <EventIcon className={pickerClasses.icon}/>
              <Typography className={pickerClasses.text} variant="body1">
                {selectedDate?.toLocaleDateString('ru-RU', {
                  day: 'numeric',
                  month: 'long',
                  year: 'numeric'
                })}
              </Typography>
            </Box>
            <Box className={clsx(pickerClasses.timeDisplay,
              currentView === 'hours' ? pickerClasses.selected : pickerClasses.unselected)}
                 onClick={() => setView('hours')}>
              <AccessTimeIcon className={pickerClasses.icon}/>
              <Typography className={pickerClasses.text} variant="body1">
                {selectedDate?.toLocaleTimeString([], {hour: '2-digit', minute: '2-digit'})}
              </Typography>
            </Box>
          </Box>
        </Box>
        <DialogContent>
          {currentView === 'date' && (
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <Box className={classes.calendarContainer}>
                <Calendar
                  date={selectedDate}
                  onChange={handleDateChange}
                  minDate={minDate}
                  maxDate={maxDate}
                />
              </Box>
            </MuiPickersUtilsProvider>
          )}
          {currentView === 'hours' && (
            <div className={classes.timePickerContainer}>
              <Box className={classes.hhmmLabel}>
                Время
              </Box>
              <Box>
                <input
                  className={clsx(classes.timeItem, classes.timeItemBorderLeft,
           activeInput === 'hours' && classes.activeInput)}
                  value={hours}
                  onChange={(e) => handleTimeChange('hours', e.target.value)}
                  min={0}
                  max={23}
                  onFocus={() => handleFocus('hours')}
                  onClick={() => handleFocus('hours')}
                  onBlur={handleBlur}
                  pattern="[0-9]*"
                  inputMode="numeric"
                />
                <input
                  className={clsx(classes.timeItem, classes.timeItemBorderRight,
           activeInput === 'minutes' && classes.activeInput)}
                  value={minutes}
                  onChange={(e) => handleTimeChange('minutes', e.target.value)}
                  min={0}
                  max={59}
                  onFocus={() => handleFocus('minutes')}
                  onClick={() => handleFocus('minutes')}
                  onBlur={handleBlur}
                  pattern="[0-9]*"
                  inputMode="numeric"
                />
              </Box>
            </div>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} disableRipple>
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

const usePickerToolbarStyles = makeStyles({
  dateTimePicker: {
    marginTop: 20,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '8px',
    backgroundColor: '#fff',
    borderRadius: '4px'
  },
  selected: {
    backgroundColor: '#AED8FF',
    '& p': {
      color: '#4A7DAC'
    },
    '& svg': {
      color: '#4A7DAC'
    }
  },
  unselected: {
    backgroundColor: '#fff',
    border: '1px solid #E7F4FF',
    '& svg': {
      color: '#888'
    }
  },
  icon: {
    marginRight: '8px'
  },
  text: {
    fontSize: 13
  },
  dateDisplay: {
    minWidth: 90,
    display: 'flex',
    alignItems: 'center',
    padding: '8px 20px 8px 8px',
    borderTopLeftRadius: '5px',
    borderBottomLeftRadius: '5px'
  },
  timeDisplay: {
    minWidth: 90,
    display: 'flex',
    alignItems: 'center',
    padding: '8px 20px 8px 8px',
    borderTopRightRadius: '5px',
    borderBottomRightRadius: '5px'
  }
})

const useStyles = makeStyles((theme: Theme) => ({
  hhmmLabel: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    paddingBottom: 30,
    fontSize: '14px',
    color: theme.palette.mpSkladSecondary.darkBlue1,
    textTransform: 'uppercase',
    fontWeight: 'bold'
  },
  dateTimePicker: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  timePickerContainer: {
    paddingBottom: 60,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    marginTop: theme.spacing(2),
    position: 'relative',
    height: 'fit-content'
  },
  timeItem: {
    border: '1px solid #E7F4FF',
    width: '160px',
    fontSize: '140px',
    fontWeight: 400,
    lineHeight: '86.4px',
    textAlign: 'left',
    color: theme.palette.mpSkladSecondary.darkBlue2,
    outline: 'none',
    appearance: 'none',
    caretColor: 'transparent',
    userSelect: 'none'
  },
  timeItemBorderLeft: {
    borderTopLeftRadius: '8px',
    borderBottomLeftRadius: '8px'
  },
  timeItemBorderRight: {
    borderTopRightRadius: '8px',
    borderBottomRightRadius: '8px'
  },
  activeInput: {
    border: '1px solid #3987CF'
  },
  timeSeparator: {
    fontSize: '72px',
    margin: theme.spacing(1),
    fontWeight: 400
  },
  calendarContainer: {
    '& .MuiTypography-root': {
      fontSize: '14px !important'
    },
    '& .MuiPickersCalendarHeader-transitionContainer .MuiTypography-root': {
      fontSize: '15px',
      color: '#1F364D',
      fontWeight: 'bold'
    },
    '& .MuiPickersDay-daySelected': {
      backgroundColor: '#3987CF',
      color: '#fff'
    }
  }
}))

export default CustomDateTimePicker