//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

export enum YandexMarketDeliveryType {
  None = 0,
  /** Fulfillment By Yandex. */
  FBY = 1,
  /** Fulfillment By Seller. */
  FBS = 2,
  /** FBS with extra steps. */
  Express = 3,
  /** Delivery By Seller */
  DBS = 4
}
