import React from 'react'
import { observer } from 'mobx-react-lite'

import { AutocompleteRenderInputParams } from '@material-ui/lab/Autocomplete/Autocomplete'

import { ExternalProductPicker, ProductSinglePickerBaseProps } from './ExternalProductPicker'

import { useLogic } from '../../hooks/storeHook'
import { makeStyles } from '@material-ui/core/styles'
import { BootstrapInput } from '../BootstrapInput'

export const OzonProductSinglePicker =
  observer(
    ({msProductId, ...passProps}: ProductSinglePickerBaseProps) => {
      const logic = useLogic()

      return (
        <ExternalProductPicker
          loadOptions={searchTerm => logic.loadOzonProductOptions(msProductId, searchTerm)}
          renderInput={renderOzonInput}
          {...passProps}
        />
      )
    })

export const renderOzonInput = (props: AutocompleteRenderInputParams) => {
  return (
    <OzonInput {...props}/>
  )
}

const OzonInput = (props: AutocompleteRenderInputParams) => {
  const classes = useStyles()

  return (
    <BootstrapInput
      {...props}
      {...props.InputProps}
      multiline
      maxRows={3}
      placeholder="Артикул / Штрихкод"
      className={classes.input}
      autoFocus
      style={{fontSize: 12}}
    />
  )
}

const useStyles = makeStyles({
  root: {
    maxHeight: '64px',
    lineHeight: '12px !important',
    letterSpacing: '0.01071em',
    overflow: 'none',
    padding: 0,
    margin: 0
  },
  input: {
    maxHeight: '64px',
    overflowY: 'hidden',
    overflowX: 'hidden'
  }
})