import React from 'react'
import { observer } from 'mobx-react-lite'

import { WbAccountInfo } from './WbAccountInfo'
import { OzonAccountInfo } from './OzonAccountInfo'
import { MyProductsTable } from './MyProductsTable'
import { YandexMarketAccountInfo } from './YandexMarketAccountInfo'

import { useStore } from '../hooks/storeHook'

import { MyProductState } from '../server/mpsklad_core/Models/MyProductState'
import { IntegrationType } from '../server/mpsklad_core/Entity/IntegrationType'

export const HomeProductsTable =
  observer(() => {
    const {
      homeNavRequired: {productsStateTab, integrationType},
      getHomeOzonAccount, getHomeWbAccount, getHomeYmAccount
    } = useStore()

    if (productsStateTab !== MyProductState.FromMarketplace) {
      return <MyProductsTable/>
    }

    switch (integrationType) {
      case IntegrationType.Ozon:
        return <OzonAccountInfo account={getHomeOzonAccount()}/>

      case IntegrationType.Wildberries:
        return <WbAccountInfo account={getHomeWbAccount()}/>

      case IntegrationType.YandexMarket:
        return <YandexMarketAccountInfo account={getHomeYmAccount()}/>

      default:
        throw new Error(`Unprocessed store type: ${integrationType}`)
    }
  })