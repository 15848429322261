import React from 'react'
import { observer } from 'mobx-react-lite'

import { makeStyles } from '@material-ui/core/styles'

import { useStore } from '../hooks/storeHook'

import { MyServerTable } from './MyServerTable'
import { MyProductsTableHead } from './Tables/MyProductsTableHead'

export const MyProductsTable =
  observer(() => {
    const classes = useStyles()

    const {visibleMyProductColumns, productStore: {myProducts}} = useStore()

    return (
      <MyServerTable
        hideTopPagination
        classes={classes}
        table={myProducts}
        columns={visibleMyProductColumns}
        HeadComponent={MyProductsTableHead}
        labelNoRows="Товаров нет"
        labelNotFound="Товары не найдены"
        labelRowsPerPage="Товаров на странице:"
      />
    )
  })

const useStyles =
  makeStyles(
    (theme) => ({
      paper: {
        minWidth: 1280,
        boxShadow: 'unset'
      },
      tableContainer: {
        width: 'calc(100vw - 300px)'
      },
      table: {
        minWidth: 1580,
        fontSize: 12,
        color: '#5f5f5f'
      }
    }))