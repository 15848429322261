import React from 'react'
import { observer } from 'mobx-react-lite'
import fromUnixTime from 'date-fns/fromUnixTime'

import clsx from 'clsx'
import { makeStyles } from '@material-ui/core/styles'
import { TableCell } from '@material-ui/core'

import { SyncStateText } from './SyncStateText'

import { useStore } from '../hooks/storeHook'
import { useSyncError } from '../hooks/syncErrorHook'

import { formatDateTime } from '../common/dateTimeUtility'
import { IntegrationTypeKey } from '../types/integrationTypeUtils'
import { SyncStateModel } from '../server/mpsklad_core/Models/SyncStateModel'

export type LastSyncAtTextProps = {
  type: IntegrationTypeKey

  state: SyncStateModel | undefined
}

export const SyncStateCell =
  observer(
    ({type, state}: LastSyncAtTextProps) => {
      const classes = useStyles()

      const {user} = useStore()

      const {onErrorClick, SyncErrorDialog} = useSyncError(type, state?.errorId)

      if (!state) {
        return <TableCell className={clsx(classes.cell, classes.disabledCell)}>&mdash;</TableCell>
      }

      const syncDate = fromUnixTime(state.date)

      if (state.success) {
        return (
          <TableCell
            className={clsx(classes.cell)}
            title={user?.isSuperAdmin || user?.isImpersonating
                   ? formatDateTime(syncDate)
                   : undefined}
          >
            <span>OK</span>
          </TableCell>
        )
      }

      // NOTE: Can't make the cell clickable - messes up the dialog
      return (
        <TableCell className={clsx(classes.cell, classes.errorCell)}>
          <span className={classes.clickable} title={'Показать ошибку'} onClick={onErrorClick}>
            Ошибка! <SyncStateText syncDate={syncDate}/>
          </span>

          <SyncErrorDialog/>
        </TableCell>
      )
    })

export const useStyles = makeStyles(
  (/*theme*/) => ({
    cell: {
      border: '1px solid #E7F4FF',
      minWidth: '120px !important'
    },
    disabledCell: {
      background: '#E7F4FF'
      // NOTE: min-width reset adds a scroll for some reason
    },
    successCell: {
      background: '#EFF7FF'
    },
    errorCell: {
      background: '#F5998C'
    },
    clickable: {
      cursor: 'pointer'
    }
  }))