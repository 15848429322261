import { exactKeyOf } from './tsUtils'
import { RoutesPath } from './routeUtils'

import { MoySkladAppScreenParams } from '../types/moySkladAppTypes'
import { getIntegrationTypeKey, IntegrationTypeKey } from '../types/integrationTypeUtils'
import { StoreRoutes, storeRoutes } from './appRoutes.Stores'
import { moySkladRoutes } from './appRoutes.MoySklad'
import { moySkladAppRoutes } from './appRoutes.MoySkladApp'
import { IntegrationType } from '../server/mpsklad_core/Entity/IntegrationType'

const moySkladAppRouteBase = '/moySkladApp'

const moySkladAppRoute = {
  template: `${moySkladAppRouteBase}/:${exactKeyOf<MoySkladAppScreenParams>()('appType')}`
} as const

export const appAuthRoutes = {
  Login: '/login',
  Register: '/register',
  PasswordReset: '/password-reset',
  MoySkladApp: moySkladAppRoute,
  MoySkladAppOld: moySkladAppRouteBase
} as const

export const appRoutes = {
  ...storeRoutes,
  ...moySkladRoutes,
  ...moySkladAppRoutes,
  Account: '/account',
  AdminUsers: '/admin/users',
  Diffs: '/diffs'
}

export const isMoySkladAppRoute = () =>
  window.location.pathname.toLowerCase().startsWith(moySkladAppRouteBase.toLowerCase())

export type AppAuthRoutePath = RoutesPath<typeof appAuthRoutes>

const getStoreRoute = (
  routeType: keyof StoreRoutes<StoreRouteParams>,
  integrationType: IntegrationType,
  accountId: number | undefined
) => {
  const integration = getIntegrationTypeKey(integrationType)

  return {
    route: appRoutes.CommonStore[routeType].route({
      accountId: accountId ?? 0,
      integration
    }),
    template: appRoutes.CommonStore[routeType].template
  }
}

export const accountNavigationLinks = (navStoreType: IntegrationType, accountId: number | undefined) => [
  getStoreRoute('products', navStoreType, accountId),
  getStoreRoute('orders', navStoreType, accountId),
  getStoreRoute('settings', navStoreType, accountId),
  getStoreRoute('print', navStoreType, accountId),
  getStoreRoute('import', navStoreType, accountId),
  getStoreRoute('syncLogs', navStoreType, accountId)
]

export type StoreRouteParams = {
  integration: IntegrationTypeKey;
  accountId?: number
};