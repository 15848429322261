import React from 'react'
import { observer } from 'mobx-react-lite'

import { DropdownButton } from './DropdownButton'
import { UnmatchProductsButton } from './UnmatchProductsButton'
import { MatchWbToMoySkladButton } from './MatchWbToMoySkladButton'
import { MatchOzonToMoySkladButton } from './MatchOzonToMoySkladButton'

import { useLogic, useStore } from '../hooks/storeHook'

import { makeOzonAccountName, makeWbAccountName, makeYandexMarketAccountName } from '../common/accountUtils'

import { WbAccountModel } from '../server/mpsklad_core/Models/WbAccountModel'
import { OzonAccountModel } from '../server/mpsklad_core/Models/OzonAccountModel'
import { YandexMarketAccountModel } from '../server/mpsklad_core/Models/YandexMarketAccountModel'
import { MatchYandexMarketToMoySkladButton } from './MatchYandexMarketToMoySkladButton'
import { IntegrationType } from '../server/mpsklad_core/Entity/IntegrationType'

export const MatchProductsDropdownButton =
  observer(() => {
    const {
      syncStore: {ozonAccounts, wbAccounts, yandexMarketAccounts},
      navState: {integrationType, accountId}
    } = useStore()

    const logic = useLogic()

    let visibleOzonAccounts: OzonAccountModel[]
    let visibleWbAccounts: WbAccountModel[]
    let visibleYandexMarketAccounts: YandexMarketAccountModel[]

    switch (integrationType) {
      case IntegrationType.Ozon:
        visibleOzonAccounts = ozonAccounts.filter(_ => _.id === accountId)
        visibleWbAccounts = []
        visibleYandexMarketAccounts = []
        break

      case IntegrationType.Wildberries:
        visibleOzonAccounts = []
        visibleWbAccounts = wbAccounts.filter(_ => _.id === accountId)
        visibleYandexMarketAccounts = []
        break

      case IntegrationType.YandexMarket:
        visibleOzonAccounts = []
        visibleWbAccounts = []
        visibleYandexMarketAccounts = yandexMarketAccounts.filter(_ => _.id === accountId)
        break

      default:
        throw new Error(`Unprocessed IntegrationType ${integrationType}`)
    }

    return (
      <DropdownButton text="Матчинг" loadingText="Загрузка..." popupWidth={300}>
        {
          buttonProps => <>
            {
              visibleOzonAccounts.map(_ =>
                <MatchOzonToMoySkladButton
                  key={_.id}
                  accountIdFromTitle={makeOzonAccountName(_)}
                  accountIdFrom={_.id}
                  accountIdToTitle={``}
                  accountIdTo={0}
                  {...buttonProps}/>)
            }

            {
              visibleWbAccounts.map(_ =>
                <MatchWbToMoySkladButton
                  key={_.id}
                  accountIdFromTitle={makeWbAccountName(_)}
                  accountIdFrom={_.id}
                  accountIdToTitle={``}
                  accountIdTo={0}
                  {...buttonProps}/>)
            }

            {
              visibleYandexMarketAccounts.map(_ =>
                <MatchYandexMarketToMoySkladButton
                  key={_.id}
                  accountIdFromTitle={makeYandexMarketAccountName(_)}
                  accountIdFrom={_.id}
                  accountIdToTitle={``}
                  accountIdTo={0}
                  {...buttonProps}/>)
            }

            {
              visibleOzonAccounts.map(_ =>
                <UnmatchProductsButton
                  key={_.id}
                  account={_}
                  makeAccountName={makeOzonAccountName}
                  onSubmit={logic.unmatchOzonProducts}
                  {...buttonProps}/>)
            }

            {
              visibleWbAccounts.map(_ =>
                <UnmatchProductsButton
                  key={_.id}
                  account={_}
                  makeAccountName={makeWbAccountName}
                  onSubmit={logic.unmatchWbProducts}
                  {...buttonProps}/>)
            }

            {
              visibleYandexMarketAccounts.map(_ =>
                <UnmatchProductsButton
                  key={_.id}
                  account={_}
                  makeAccountName={makeYandexMarketAccountName}
                  onSubmit={logic.unmatchYandexMarketProducts}
                  {...buttonProps}/>)
            }
          </>
        }
      </DropdownButton>
    )
  })