import React, { useMemo } from 'react'
import { observer } from 'mobx-react-lite'
import { Redirect, Route, RouteComponentProps, Switch } from 'react-router-dom'

import { Box } from '@material-ui/core'

import { WbFilesTab } from '../../WbFilesTab'
import { RoutingTabs } from '../../RoutingTabs'
import { RouteTabItem } from '../../RouteTabItem'
import { WildberriesLabelsPrint } from './WildberriesLabelsPrint'

import { useLogic, useStore } from '../../../hooks/storeHook'
import { appRoutes, StoreRouteParams } from '../../../common/appRoutes'

import { required } from '../../../common/objectUtils'

import { WbDeliveryType } from '../../../server/mpsklad_core/Entity/Wildberries/WbDeliveryType'

export const WildberriesPrintPage =
  observer(() => {
    const logic = useLogic()
    const {homeNavRequired} = useStore()

    const account = logic.tryGetWbAccount(homeNavRequired.accountId)

    const routes =
      useMemo((): RouteTabItem[] => {
          if (!account) {
            return []
          }

          const params: StoreRouteParams = {
            accountId: homeNavRequired.accountId,
            integration: 'Wildberries'
          }

          // TODO: Why useMemo if all routes are statically known?
          return [{
            title: 'Этикетки',
            path: appRoutes.CommonStore.print.labels.route(params),
            template: appRoutes.CommonStore.print.labels.template,
            render: (props: RouteComponentProps) => <WildberriesLabelsPrint account={account} {...props} />
          }, {
            title: 'Файлы',
            path: appRoutes.CommonStore.print.files.route(params),
            template: appRoutes.CommonStore.print.files.template,
            render: (props: RouteComponentProps) => <WbFilesTab wbAccountId={account.id} {...props} />
          }]
        },
        [account, homeNavRequired.accountId])

    if (!account) {
      return (
        <Box padding={3}>
          Аккаунт не найден
        </Box>
      )
    }

    switch (account.deliveryType) {
      case WbDeliveryType.FBW:
        return (
          <Box padding={3}>
            Печать этикеток недоступна при схеме работы FBW.
          </Box>
        )

      case WbDeliveryType.FBS:
      case WbDeliveryType.DBS: {
        const firstRoutePath = required(routes[0].path)

        return (
          <RoutingTabs routeTabs={routes}>
            <Switch>
              <Route exact path={appRoutes.CommonStore.print.template}>
                <Redirect to={firstRoutePath}/>
              </Route>

              {
                routes.map(
                  ({template, render}, index) =>
                    <Route key={index} path={template} render={render}/>
                )
              }
            </Switch>
          </RoutingTabs>
        )
      }

      default:
        throw new Error(`Unprocessed enum value for WbDeliveryType: ${account.deliveryType}`)
    }
  })