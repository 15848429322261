import React from 'react'
import { observer } from 'mobx-react-lite'

import {
  Box,
  FormLabel,
  Grid
} from '@material-ui/core'

import { IntegrationTypeKey } from '../types/integrationTypeUtils'
import { SyncStatesModel } from '../server/mpsklad_core/Models/SyncStatesModel'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/core/styles'
import { SyncStateInput } from './SyncStateInput'

export type ImportStatusTableProps = {
  type: Exclude<IntegrationTypeKey, 'MoySklad'>

  states: SyncStatesModel
}

export const ImportStatusTable =
  observer(
    ({type, states}: ImportStatusTableProps) => {
      const classes = useStyles()

      return (
        <Box className={classes.container}>

          <Grid
            container
            className={clsx(classes.settingsBlock, 'default-border')}
            direction="column"
          >
            <FormLabel component="legend" className={classes.settingsBlockLabel}>
              Импорт товаров в МС
            </FormLabel>

            <Grid container justifyContent="space-between">
              <Grid item xs={5}>

                <Box>
                  <FormLabel component="legend" className={classes.settingLabel}>
                    <span className={classes.dash}>—</span>
                    Импорт товаров
                  </FormLabel>
                </Box>


                <SyncStateInput
                  type={type}
                  state={states.importProductsState}
                />

              </Grid>

              <Grid item xs={5}>

                <Box>
                  <FormLabel component="legend" className={classes.settingLabel}>
                    <span className={classes.dash}>—</span>
                    Импорт остатков
                  </FormLabel>
                </Box>

                <SyncStateInput
                  type={type}
                  state={states.importStocksState}
                />

              </Grid>
            </Grid>
          </Grid>

        </Box>
      )
    })

const useStyles = makeStyles(
  () => ({
    container: {
      padding: '16px 0px 0px 0px',
      width: 'calc(100vw - 350px)'
    },
    settingsBlockLabel: {
      color: '#1F364D',
      fontSize: 12,
      fontWeight: 600,
      marginBottom: 15
    },
    settingsBlock: {
      flexWrap: 'unset',
      marginTop: 20,
      marginBottom: 20,
      marginLeft: 0,
      padding: 30,
      borderRadius: 12
    },
    settingLabel: {
      fontSize: 12,
      marginBottom: 15,
      marginTop: 10,
      marginLeft: 5
    },
    dash: {
      color: '#3987CF',
      marginRight: 3
    },
    input: {
      fontSize: 12
    },
    textField: {
      '& .MuiInputBase-input': {
        opacity: 1,
        color: '#000'
      }
    }
  }))