import React, { ReactText } from 'react'

import { DropdownItemProps } from './DropdownButton'
import { MatchProductsButton } from './MatchProductsButton'

import { useLogic } from '../hooks/storeHook'

import { MsProductProp } from '../server/mpsklad_core/Models/MsProductProp'
import { OzonProductProp } from '../server/mpsklad_core/Models/OzonProductProp'

export type MatchButtonProps =
  DropdownItemProps
  & {
  accountIdFromTitle: ReactText | undefined
  accountIdFrom: number

  accountIdToTitle: ReactText | undefined
  accountIdTo: number
}

export const MatchOzonToMoySkladButton =
  (props: MatchButtonProps) => {
    const logic = useLogic()

    return <MatchProductsButton
      buttonText={`С ${props.accountIdFromTitle ?? ''}`}
      title={`Сопоставление товаров ${props.accountIdFromTitle ?? ''} и МоегоСклада`}
      fromProps={[
        [OzonProductProp.Article, 'Артикул'],
        [OzonProductProp.Name, 'Название'],
        [OzonProductProp.Barcode, 'Штрихкод']
      ]}
      toProps={[
        [MsProductProp.Id, 'GUID'],
        [MsProductProp.Code, 'Код'],
        [MsProductProp.Article, 'Артикул'],
        [MsProductProp.Name, 'Название'],
        [MsProductProp.BarcodeGtin, 'Штрихкод GTIN'],
        [MsProductProp.BarcodeEan8, 'Штрихкод EAN8'],
        [MsProductProp.BarcodeEan13, 'Штрихкод EAN13'],
        [MsProductProp.BarcodeCode128, 'Штрихкод Code128']
      ]}
      submit={logic.matchOzonToMoySkladProducts}
      {...props}
    />
  }