import React from 'react'
import { observer } from 'mobx-react-lite'

import { Box, Grid } from '@material-ui/core'

import { ImpersonationBar } from './ImpersonationBar'
import { MoySkladStatusMapPanel } from './MoySkladStatusMapPanel'
import { MoySkladNamePhoneFormPanel } from './MoySkladNamePhoneFormPanel'

import { useStore } from '../hooks/storeHook'
import { useBoolState } from '../hooks/commonHooks'

import { required } from '../common/objectUtils'
import { createStyles, makeStyles } from '@material-ui/core/styles'

export const MoySkladAppSettingsScreen =
  observer(
    () => {
      const classes = useStyles()
      const user = required(useStore().user)
      const [hasPersonalData, setHasPersonalData] = useBoolState()

      const step = (!hasPersonalData && !user.phoneNumber) ? 1 : 2

      return (
        <div className="body_wrapper_zero">
          <ImpersonationBar/>

          <Box padding={3}
               style={{
                 display: 'flex',
                 flexDirection: 'column',
                 alignItems: 'center',
                 justifyContent: 'center'
               }}>
            <h1 style={{textTransform: 'uppercase'}}>
              <span className={classes.stepLabel}>Шаг {step}/2</span> Настройка приложения
            </h1>

            <Grid container spacing={3} alignItems="center" justifyContent="center">
              <Grid item lg={6} xl={4} xs={6}>
                <Grid container direction="column" spacing={3}>
                  {!hasPersonalData && !user.phoneNumber ?
                   <Grid item lg={12} xs={10}>
                     <MoySkladNamePhoneFormPanel onFinish={setHasPersonalData}/>
                   </Grid> :
                   <Grid item lg={12}>
                     <Box style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                       <MoySkladStatusMapPanel shouldSkipMapButton shouldShowHelpHints/>
                     </Box>
                   </Grid>
                  }
                </Grid>
              </Grid>

            </Grid>
          </Box>
        </div>
      )
    })

const useStyles =
  makeStyles(() =>
    createStyles({
      stepLabel: {
        color: '#F19F94'
      }
    }))