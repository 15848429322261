import React from 'react'
import { observer } from 'mobx-react-lite'
import clsx from 'clsx'

import { makeStyles } from '@material-ui/core/styles'
import { FormLabel, Grid, IconButton } from '@material-ui/core'

import addIcon from '../assets/addIcon.svg'
import arrowRight from '../assets/arrowRight.svg'
import deleteIcon from '../assets/deleteIcon.svg'

import { BootstrapInput } from './BootstrapInput'
import { MoySkladStorePicker } from './MoySkladStorePicker'
import { AccountStoresServerData } from './AccountStoresForm'
import { WarehouseDisplayProps, WarehouseKeyProps, WarehousePicker } from './WarehousePicker'

import { useLogic } from '../hooks/storeHook'
import { useMessages } from '../hooks/snackbarHooks'
import { useDefaultMenuItemStyles } from '../hooks/defaultSelectStylesHook'

import { required } from '../common/objectUtils'

import { getIntegrationTypeName } from '../types/navStore'

import { EditStoreModel } from '../server/mpsklad_core/Models/EditStoreModel'
import { WarehouseModel } from '../server/mpsklad_core/Models/WarehouseModel'
import { IntegrationType } from '../server/mpsklad_core/Entity/IntegrationType'
import { EditStoresModel } from '../server/mpsklad_core/Models/EditStoresModel'
import { MoySkladStoreModel } from '../server/mpsklad_core/Models/MoySkladStoreModel'

export type StoresFormBaseProps<TWarehouse extends WarehouseModel> =
  WarehouseKeyProps<TWarehouse>
  & WarehouseDisplayProps<TWarehouse>
  & {
  disabled: boolean

  storeType: Exclude<IntegrationType, IntegrationType.MoySklad>

  serverData: AccountStoresServerData<TWarehouse>

  formData: EditStoresModel

  onImport: (warehouse: TWarehouse) => Promise<void>
}

export type StoresFormDirectProps<TWarehouse extends WarehouseModel> =
  StoresFormBaseProps<TWarehouse>
  & {
  onImportAll: () => Promise<void>
}

export const StoresFormDirect =
  observer(
    <TWarehouse extends WarehouseModel>
    ({
       disabled,
       storeType,
       serverData, formData,
       whKeySelector, storeWhKeySelector,
       formatWarehouseDisplayName,
       onImport, onImportAll
     }: StoresFormDirectProps<TWarehouse>) => {
      const classes = useStoresFormStyles()
      const menuClasses = useDefaultMenuItemStyles()

      const {showWarning} = useMessages()

      const {showDialog} = useLogic()

      const storeTypeName = getIntegrationTypeName(storeType)

      let lastWarehouse: TWarehouse | undefined = undefined

      if (formData.stores.length > 0) {
        const lastStore = formData.stores[formData.stores.length - 1]

        lastWarehouse = required(serverData.warehouses.find(_ => whKeySelector(_) === storeWhKeySelector(lastStore)))
      }

      const unmatchedWarehouse =
        serverData.warehouses.find(
          warehouse => formData.stores.every(store => storeWhKeySelector(store) !== whKeySelector(warehouse)))

      const onAddStore =
        () => {
          if (!unmatchedWarehouse) {
            showWarning(`Все склады ${storeTypeName} сопоставлены!`)
            return
          }

          formData.stores.push({
            storeId: undefined,
            warehouseId: unmatchedWarehouse.id,
            warehouseName: unmatchedWarehouse.name,
            msStoreIds: [/* Start without selection to allow import into MS */],
            stocksSplitPercentage: undefined
          })
        }

      const onDeleteStore =
        async (formStore: EditStoreModel) => {
          if (formStore.storeId != null && !await showDialog(
            `Заказы для склада ${storeTypeName} будут удалены из MPsklad после сохранения!`, {
              title: 'Удалить сопоставление складов?',
              acceptButton: 'Удалить'
            })) {
            return
          }

          formData.stores = formData.stores.filter(_ => _ !== formStore)
        }

      const onChangeWarehouse =
        (formStore: EditStoreModel, warehouse: TWarehouse) => {
          formStore.warehouseId = warehouse.id
          formStore.warehouseName = warehouse.name
        }

      const onChangeMsStore =
        (formStore: EditStoreModel, msStore: MoySkladStoreModel | null) =>
          formStore.msStoreIds = msStore != null ? [msStore.id] : []

      const onImportWarehouse =
        async () => {
          if (!lastWarehouse) {
            showWarning(`Нет выбран склад ${storeTypeName}!`)
            return
          }

          await onImport(lastWarehouse)
        }

      return (
        <Grid container direction="column">
          <Grid item xs={12}>
            <Grid container spacing={1}>
              <Grid item xs={6}>
                <FormLabel component="legend" className={classes.label}>
                  <span className={classes.dash}>—</span>
                  Склад МоегоСклада
                </FormLabel>
              </Grid>

              <Grid item xs={6}>
                <FormLabel component="legend" className={classes.label}>
                  <span className={classes.dash}>&mdash;</span>
                  Склад {storeTypeName}
                </FormLabel>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Grid container direction="column" spacing={3}>
              {
                formData.stores.map((formStore, index) =>
                  <Grid item xs={12} key={index}>
                    <Grid container spacing={1}>
                      <Grid item xs={4}>
                        <MoySkladStorePicker
                          filterMatchedStores
                          allowEmpty={formStore.storeId == null}
                          msStoreId={formStore.msStoreIds[0]}
                          formData={formData}
                          msStores={serverData.msStores}
                          onChange={msStore => onChangeMsStore(formStore, msStore)}
                        />
                      </Grid>

                      <Grid item xs={1}>
                        <IconButton
                          title="Удалить"
                          disabled={disabled}
                          className={clsx(menuClasses.iconButtonOutlined)}
                          onClick={() => onDeleteStore(formStore)}
                        >
                          <img src={deleteIcon} alt=""/>
                        </IconButton>
                      </Grid>

                      <Grid item xs={1} alignContent="center">
                        <img src={arrowRight} alt="" style={{width: 20, height: 20}}/>
                      </Grid>

                      <Grid item xs={6}>
                        <Grid container spacing={1}>
                          <Grid item xs={8}>
                            <WarehousePicker
                              formStore={formStore}
                              formData={formData}
                              warehouses={serverData.warehouses}
                              whKeySelector={whKeySelector}
                              storeWhKeySelector={storeWhKeySelector}
                              formatWarehouseDisplayName={formatWarehouseDisplayName}
                              onChange={warehouse => onChangeWarehouse(formStore, warehouse)}
                            />
                          </Grid>

                          <Grid item xs={2}>
                            <IconButton
                              title="Удалить"
                              disabled={disabled}
                              className={clsx(menuClasses.iconButtonOutlined)}
                              onClick={() => onDeleteStore(formStore)}
                            >
                              <img src={deleteIcon} alt=""/>
                            </IconButton>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                )
              }

              {
                !!unmatchedWarehouse &&
                <Grid item xs={12}>
                  <Grid container spacing={1}>
                    <Grid item xs={6}>
                      {/* spacer */}
                    </Grid>

                    <Grid item xs={4}>
                      <BootstrapInput readOnly className={menuClasses.input} onClick={onAddStore}/>
                    </Grid>

                    <Grid item xs={1}>
                      <IconButton
                        title="Добавить"
                        disabled={disabled}
                        className={clsx(menuClasses.iconButtonOutlined)}
                        onClick={onAddStore}
                      >
                        <img src={addIcon} alt=""/>
                      </IconButton>
                    </Grid>
                  </Grid>
                </Grid>
              }

              <Grid item xs={12}>
                <Grid container spacing={1}>
                  <Grid item xs={6}>
                    {/* spacer */}
                  </Grid>

                  <Grid item xs={4}>
                    <Grid container spacing={1}>
                      <Grid item xs={6} className={classes.buttonsItem}>
                        <button
                          disabled={disabled}
                          className={clsx(classes.createButton, 'default-button')}
                          onClick={onImportAll}>
                          <p>
                            Создать все
                          </p>
                        </button>
                      </Grid>

                      {
                        !!lastWarehouse &&
                        <Grid item xs={6}>
                          <button
                            disabled={disabled}
                            className={clsx(classes.createButton, 'default-button')}
                            onClick={onImportWarehouse}>
                            <p>Создать выбранный</p>
                          </button>
                        </Grid>
                      }
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      )
    })

export const useStoresFormStyles =
  makeStyles(
    ({spacing}) => ({
      dash: {
        color: '#3987CF',
        marginRight: 3
      },
      label: {
        fontSize: '12px',
        fontWeight: 400,
        lineHeight: '14.4px',
        textAlign: 'left',
        marginTop: spacing(4)
      },
      buttonsItem: {
        '& button': {
          marginTop: 0,
          marginBottom: 0,
        }
      },
      createButton: {
        flex: 1,
        display: 'inline-flex',
        maxWidth: 'none',
        marginTop: 0,
        marginBottom:0,
        fontSize: 14,
        color: '#FFFFFF',
        alignItems: 'center',
        padding: '8px 32px'
      }
    }))