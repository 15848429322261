import React, { useMemo } from 'react'
import { useSnackbar } from 'notistack'
import { Box } from '@material-ui/core'

import warningIcon from '../assets/warning.svg'
import errorIcon from '../assets/close.svg'
import successIcon from '../assets/check.svg'
import { makeStyles } from '@material-ui/core/styles'

export const useMessages =
  () => {
    const snackbar = useSnackbar()

    return useMemo(
      () => ({
        ...snackbar,

        showInfo:
          (message: string) =>
            snackbar.enqueueSnackbar(
              <MessageComponent message={message} icon={warningIcon}/>,
              {variant: 'info', autoHideDuration: 5000}),

        showSuccess:
          (message: string) =>
            snackbar.enqueueSnackbar(
              <MessageComponent message={message} icon={successIcon}/>,
              {variant: 'success', autoHideDuration: 5000}),

        showWarning:
          (message: string) =>
            snackbar.enqueueSnackbar(
              <MessageComponent message={message} icon={warningIcon}/>,
              {variant: 'warning', autoHideDuration: 5000}),

        showError:
          (message: string) =>
            snackbar.enqueueSnackbar(
              <MessageComponent message={message} icon={errorIcon}/>,
              {variant: 'error', autoHideDuration: 10000})
      }),
      [snackbar])
  }

interface MessageComponentProps {
  message: string,

  icon: string
}

const MessageComponent = ({message, icon}: MessageComponentProps) => {
  const classes = useStyles()

  return <Box className={classes.container}>
    <img src={icon} className={classes.icon} alt={'icon'}/>
    <span>{message}</span>
  </Box>
}

const useStyles = makeStyles(
  () => ({
    container: {
      display: 'flex',
      alignItems: 'center',
      paddingLeft: 24
    },
    icon: {
      marginRight: 10,
      width: 17,
      height: 17
    }
  }))