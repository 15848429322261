import React, { useMemo } from 'react'
import { observer } from 'mobx-react-lite'
import { Redirect, Route, RouteComponentProps, Switch } from 'react-router-dom'

import { useLogic, useStore } from '../../../hooks/storeHook'
import { appRoutes, StoreRouteParams } from '../../../common/appRoutes'
import { RoutingTabs } from '../../RoutingTabs'
import { OzonFilesTab } from '../../OzonFilesTab'
import { OzonLabelsPrintForm } from './OzonLabelsPrint'
import { Box } from '@material-ui/core'
import { required } from '../../../common/objectUtils'
import { RouteTabItem } from '../../RouteTabItem'
import { OzonDeliveryType } from '../../../server/mpsklad_core/Entity/Ozon/OzonDeliveryType'

export const OzonPrintPage =
  observer(() => {
    const store = useStore()
    const logic = useLogic()
    const account = logic.tryGetOzonAccount(store.homeNavRequired.accountId)

    const routes: RouteTabItem[] = useMemo(() => {
      const params: StoreRouteParams = {
        accountId: store.homeNavRequired.accountId,
        integration: 'Ozon'
      }

      if (!account) {
        return []
      }

      const routes = []

      if (account.deliveryType === OzonDeliveryType.FBS || account.deliveryType === OzonDeliveryType.RFBS) {
        routes.unshift({
          title: 'Этикетки',
          route: 'labels',
          path: appRoutes.CommonStore.print.labels.route(params),
          template: appRoutes.CommonStore.print.labels.template,
          render: (props: RouteComponentProps) =>
            <OzonLabelsPrintForm ozonAccountId={account.id} {...props} />
        }, {
          title: 'Файлы',
          route: 'files',
          path: appRoutes.CommonStore.print.files.route(params),
          template: appRoutes.CommonStore.print.files.template,
          render: (props: RouteComponentProps) =>
            <OzonFilesTab ozonAccountId={account.id} {...props} />
        })
      }

      return routes
    }, [account, store.homeNavRequired.accountId])

    if (!account) {
      return (
        <Box padding={3}>
          Аккаунт не найден
        </Box>
      )
    }

    if (account?.deliveryType === OzonDeliveryType.FBO) {
      return (
        <Box padding={3}>
          Печать этикеток недоступна при схеме работы FBO.
        </Box>
      )
    }

    const firstRoutePath = required(routes[0].path)

    return (
      <RoutingTabs routeTabs={routes}>
        <Switch>
          <Route exact path={appRoutes.CommonStore.print.template}>
            <Redirect to={firstRoutePath}/>
          </Route>
          {routes.map(({template, render}, index) => (
            <Route key={index} path={template} render={render}/>
          ))}
        </Switch>
      </RoutingTabs>
    )
  })