import React from 'react'
import { observer } from 'mobx-react-lite'
import clsx from 'clsx'

import { Box, ClickAwayListener } from '@material-ui/core'

import { EditProductsButton } from './EditProductsButton'
import { ExportProductsButton } from './ExportProductsButton'
import { MyProductsPropFilter } from './MyProductsPropFilter'
import { MyProductsColumnsMenu } from './MyProductsColumnsMenu'
import { MatchProductsDropdownButton } from './MatchProductsDropdownButton'

import { useStore } from '../hooks/storeHook'
import { useBoolState, useLazyUnEffect } from '../hooks/commonHooks'

import search from '../assets/search.svg'
import filter from '../assets/filter.svg'
import { BootstrapInput } from './BootstrapInput'

export const MyProductsFilter =
  observer(() => {
    const {
      productStore: {myProducts: {selectedRowCount, setGlobalFilter}}
    } = useStore()

    const [isFilterOpen, setFilterOpen, setFilterClosed] = useBoolState()

    useLazyUnEffect(() => setGlobalFilter(null))

    return <>
      <Box display="flex" alignItems="center">

        <div className="main_input_container">
          <BootstrapInput
            placeholder="Название, код или артикул..."
            onChange={e => setGlobalFilter(e.target.value)}
            inputProps={{'aria-label': 'search'}}
            fullWidth
            style={{marginRight: '20px'}}
          />
          <img className="search-icon" src={search} alt="Поиск"/>
        </div>


        <button
          onClick={isFilterOpen ? setFilterClosed : setFilterOpen}
          className={clsx('btn', isFilterOpen && 'btn_isactive')}
        >
          <p>Фильтр</p>
          <img src={filter} alt={'Фильтр'}/>
        </button>

        <MyProductsColumnsMenu/>

        {
          selectedRowCount > 0 &&
          <>
            <button
              disabled
              type="button"
              className="button_counter"
            >
              Выбрано: {selectedRowCount}
            </button>

            <EditProductsButton/>
          </>
        }

        <ExportProductsButton/>

        <MatchProductsDropdownButton/>
      </Box>

      {
        isFilterOpen &&
        <ClickAwayListener onClickAway={setFilterClosed}>
          <MyProductsPropFilter/>
        </ClickAwayListener>
      }
    </>
  })