import React from 'react'

import { DropdownButton } from './DropdownButton'
import { ProductsSyncButtons, SyncMessages } from './ProductsSyncButtons'

import { useLogic, useStore } from '../hooks/storeHook'
import { IntegrationType } from '../server/mpsklad_core/Entity/IntegrationType'

export const EditProductsButton =
  () => {
    const logic = useLogic()
    const {homeHasStoreType} = useStore()

    return (
      <DropdownButton text="Изменить" loadingText="Изменяем..." popupWidth={300}>
        {
          buttonProps => <>
            {
              homeHasStoreType(IntegrationType.Ozon) &&
              <ProductsSyncButtons
                messages={messages.ozon}
                toggleSync={logic.ozonSyncSelectedProducts}
                {...buttonProps}
              />
            }

            {
              homeHasStoreType(IntegrationType.Wildberries) &&
              <ProductsSyncButtons
                messages={messages.wb}
                toggleSync={logic.wbSyncSelectedProducts}
                {...buttonProps}
              />
            }

            {
              homeHasStoreType(IntegrationType.YandexMarket) &&
              <ProductsSyncButtons
                messages={messages.yandexMarket}
                toggleSync={logic.yandexMarketSyncSelectedProducts}
                {...buttonProps}
              />
            }
          </>
        }
      </DropdownButton>
    )
  }

const makeMessages =
  (name: string): SyncMessages => ({
    on: `Включить синхронизацию ${name}`,
    off: `Отключить синхронизацию ${name}`,

    onSuccess: 'Включено',
    offSuccess: 'Отключено',

    onError: `Не удалось включить синхронизацию ${name}`,
    offError: `Не удалось отключить синхронизацию ${name}`
  })

const messages = {
  ozon: makeMessages('Ozon'),
  wb: makeMessages('Wildberries'),
  yandexMarket: makeMessages('Yandex.Market')
}