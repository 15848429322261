import React, { ReactNode } from 'react'
import clsx from 'clsx'

import { makeStyles } from '@material-ui/core/styles'
import { Box, FormHelperText, FormLabel } from '@material-ui/core'

import { BootstrapInput } from './BootstrapInput'

export type LabeledInputProps = {
  id?: string

  label: ReactNode

  placeholder?: string

  value: string

  onChange?: (value: string) => void

  helperText?: ReactNode

  required?: boolean

  disabled?: boolean

  readOnly?: boolean

  classes?: {
    label?: string

    input?: string

    helperText?: string

    dash?: string

    star?: string
  }
}

export const LabeledInput =
  ({
     id,
     required, disabled, readOnly,
     label, placeholder, helperText,
     value, onChange,
     classes = {}
   }: LabeledInputProps) => {
    const defaultClasses = useStyles()

    return <>
      <Box>
        <FormLabel
          component="legend"
          disabled={disabled}
          htmlFor={id}
          className={clsx(defaultClasses.label, classes.label)}>
          {/* @formatter:off */}
          <span className={clsx(defaultClasses.dash, classes.dash)}>&mdash;</span>{label}
          {required && <span className={clsx(defaultClasses.star, classes.star)}>*</span>}
          {/* @formatter:on */}
        </FormLabel>
      </Box>

      <BootstrapInput
        fullWidth
        id={id}
        required={required}
        disabled={disabled}
        readOnly={readOnly}
        placeholder={placeholder}
        value={value}
        onChange={e => onChange?.(e.target.value)}
      />

      {
        !!helperText &&
        <FormHelperText disabled={disabled} className={clsx(defaultClasses.helperText, classes.helperText)}>
          {helperText}
        </FormHelperText>
      }
    </>
  }

const useStyles =
  makeStyles(() => ({
    label: {
      fontSize: '12px',
      fontWeight: 400,
      lineHeight: '14.4px',
      textAlign: 'left',
      marginBottom: 15
    },
    helperText: {
      fontSize: '11px',
      fontWeight: 400,
      lineHeight: '12px',
      textAlign: 'left',
      marginTop: 5
    },
    dash: {
      color: '#3987CF',
      marginRight: 3
    },
    star: {
      color: '#3987CF',
      marginLeft: 2
    }
  }))