import React, { FormEvent } from 'react'
import { observer, useLocalObservable } from 'mobx-react-lite'

import { makeStyles } from '@material-ui/core/styles'

import {
  Box,
  FormControl,
  FormLabel,
  Grid
} from '@material-ui/core'

import { ImportProductsProps } from './ImportScreen'

import { useImportEvents } from '../hooks/importHooks'

import { IServerTableData } from '../types/tableTypes'
import { StoreAccountBaseProps } from '../types/accountProps'
import { StoreProductModel } from '../server/mpsklad_core/Models/StoreProductModel'
import { ImportStocksOptionsModel } from '../server/mpsklad_core/Models/ImportModels/ImportStocksModels/ImportStocksOptionsModel'
import clsx from 'clsx'
import { DefaultRadioGroup } from './DefaultRadioGroup'
import { useRadioGroupStyles } from '../hooks/radioGroupStylesHook'

export type ImportStocksFormProps<
  TProducts extends IServerTableData<TRow, TColumnId>,
  TRow extends StoreProductModel,
  TColumnId> =
  StoreAccountBaseProps
  & ImportProductsProps<TProducts, TRow, TColumnId>
  & {
  importStocksFunc: (accountId: number, productOptionsModel: ImportStocksOptionsModel) => Promise<void>
}

export const ImportStocksForm =
  observer(
    <TProducts extends IServerTableData<TRow, TColumnId>, TRow extends StoreProductModel, TColumnId>
    ({account, importStocksFunc, products}: ImportStocksFormProps<TProducts, TRow, TColumnId>) => {
      const classes = useStyles()
      const radioGroupClasses = useRadioGroupStyles()

      const {onStockImport, isImportDisabled} = useImportEvents(account, products)

      const stockImportOptions =
        useLocalObservable<ImportStocksOptionsModel>(
          () => ({
            sumStocks: false,
            accountForReserved: false,
            somethingAboutMatchedWarehouses: false,
            productIds: []
          }) satisfies ImportStocksOptionsModel)

      const onImportStocks = onStockImport(importStocksFunc, stockImportOptions)

      const onSubmit =
        async (e: FormEvent) => {
          e.preventDefault()
          await onImportStocks()
        }

      const radioOptions = [
        {
          value: false,
          label: 'Перезаписать остатки МоегоСклада на остатки маркетплейса'
        },
        {
          value: true,
          label: 'Прибавить остатки маректплейса к остаткам МоегоСклада'
        }
      ]

      const getRadioButtonClasses =
        (checked: boolean) => clsx(radioGroupClasses.radioButton, checked ? radioGroupClasses.selectedRadioButton : '')

      return (
        <Box className={classes.container}>
          <form onSubmit={onSubmit}>
            <Grid container className={clsx(classes.settingsBlock, 'default-border')} direction={'column'}>
              <FormLabel className={classes.headerLabel}>
                Импорт остатков в МойСклад
              </FormLabel>

              <Grid item container direction="row">
                <Grid item xs={6}>
                  <FormControl component="fieldset">
                    <Box>
                      <FormLabel component="legend" className={classes.label}>
                        <span className={classes.dash}>—</span>
                        Что делать с остатками?
                      </FormLabel>
                    </Box>

                    <DefaultRadioGroup
                      name="sumStocks"
                      options={radioOptions}
                      value={stockImportOptions.sumStocks}
                      onChange={(value: boolean) => stockImportOptions.sumStocks = value}
                      getOptionClassName={getRadioButtonClasses}
                      getOptionLabelClassName={() => radioGroupClasses.radioLabel}
                    />

                  </FormControl>
                </Grid>
              </Grid>
            </Grid>

            <button
              type="submit"
              className={clsx(classes.printButton, 'default-button')}
              disabled={isImportDisabled}>
              <p>
                {isImportDisabled ? 'Импортируем...' : 'Импортировать остатки'}
              </p>
            </button>
          </form>
        </Box>
      )
    })

const useStyles = makeStyles(
  theme => ({
    container: {
      padding: '16px 0px 0px 0px',
      width: 'calc(100vw - 350px)'
    },
    settingsBlock: {
      marginBottom: 20,
      marginLeft: 0,
      padding: 30,
      borderRadius: 12
    },
    settingsBlockLabel: {
      color: '#1F364D',
      fontSize: 12,
      fontWeight: 600,
      marginBottom: 15,
      marginLeft: 12
    },
    printButton: {
      width: 221,
      height: 40,
      color: '#FFFFFF',
      alignItems: 'center'
    },
    radioGroup: {
      marginTop: theme.spacing(1)
    },
    dash: {
      color: '#3987CF',
      marginRight: 3
    },
    label: {
      fontSize: 12,
      '&.Mui-focused': {
        color: 'rgba(0, 0, 0, 0.54) !important'
      }
    },
    headerLabel: {
      fontSize: 12,
      fontWeight: 600,
      color: '#1F364D',
      marginBottom: 20
    }
  }))