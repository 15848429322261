import React, { useState } from 'react'
import { useLocation } from 'react-router-dom'

import { Box, Table, TableBody, TableContainer, Typography } from '@material-ui/core'

import { ScreenLoader } from './ScreenLoader'
import { SyncLogRow } from './SyncLogRow'
import { SyncLogsTableHead } from './SyncLogsTableHead'

import { useApi, useLogic } from '../hooks/storeHook'
import { useBoolState, useLazyEffect } from '../hooks/commonHooks'

import { integrationNames, integrationTypeByKey } from '../types/integrationTypeUtils'

import { SyncLogModel } from '../server/mpsklad_core/Models/SyncLogModel'
import { appRoutes } from '../common/appRoutes'
import { observer } from 'mobx-react-lite'

export const SyncLogsScreen =
  observer(() => {
    const logic = useLogic()
    const location = useLocation()
    const {integration, accountId} = {...appRoutes.CommonStore.syncLogs.parse(location.pathname)}
    const account = logic.tryGetAccount(Number(accountId), integration)

    const api = useApi()

    const [isLoading, , setLoaded] = useBoolState(true)
    const [syncErrors, setSyncErrors] = useState<SyncLogModel[]>([])

    useLazyEffect(
      () => {
        if (!integration || !account) {
          return
        }

        return api.userSync.syncLogs({integration: integrationTypeByKey[integration], accountId})
                  .then(setSyncErrors)
                  .finally(setLoaded)
      })

    if (!account || !integration) {
      return <Box margin={5} height={'calc(100vh - 270px)'}>Выбранного аккаунта не существует</Box>
    }

    if (isLoading) {
      return <ScreenLoader/>
    }

    if (syncErrors.length === 0) {
      return (
        <div className="body_wrapper_zero">
          <div className="body_wrapper__two">
            Нет логов синхронизации {integrationNames[integration]}
          </div>
        </div>
      )
    }

    // TODO: Pagination and stuff
    return (
      <div className="body_wrapper_zero">
        <div className="body_wrapper">
          <Box display="flex" alignItems="center" marginBottom={2}>
            <Typography style={{color: '#1F364D', fontSize: 18, fontWeight: 'bold', textTransform: 'uppercase'}}>
              Логи синхронизации {integrationNames[integration]}
            </Typography>
          </Box>
        </div>

        <div
          style={{borderRadius: '10px', width: 'calc(100vw - 400px)'}}>
          <TableContainer>
            <Table>
              <SyncLogsTableHead/>

              <TableBody>
                {
                  syncErrors.map(error => <SyncLogRow key={error.id} log={error}/>)
                }
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </div>
    )
  })