import { RefObject } from 'react'
import { comparer, reaction, runInAction, when } from 'mobx'
import { SnackbarProvider } from 'notistack'
import { AxiosRequestConfig } from 'axios'
import debounce from 'lodash/debounce'

import { ApiLogic } from './apiLogic'
import { HandlersContainer } from './handlersContainer'

import { Store } from '../store'

import { required } from '../../common/objectUtils'
import { isLocalStorageAllowed } from '../../common/commonUtils'
import { appRoutes } from '../../common/appRoutes'

import { MyProductColumn } from '../../types/myProductColumnTypes'
import { IntegrationTypeKey } from '../../types/integrationTypeUtils'
import { MyProductColumnId, syncErrorsColumnId } from '../../types/myProductColumnIds'

import { MoySkladAppType } from '../../server/mpsklad_common/MoySkladAppType'
import { MsProductProp } from '../../server/mpsklad_core/Models/MsProductProp'
import { WbProductProp } from '../../server/mpsklad_core/Models/WbProductProp'
import { UserAuthModel } from '../../server/mpsklad_core/Models/UserAuthModel'
import { MyProductModel } from '../../server/mpsklad_core/Models/MyProductModel'
import { MyProductState } from '../../server/mpsklad_core/Models/MyProductState'
import { WbAccountModel } from '../../server/mpsklad_core/Models/WbAccountModel'
import { IntegrationType } from '../../server/mpsklad_core/Entity/IntegrationType'
import { OzonProductProp } from '../../server/mpsklad_core/Models/OzonProductProp'
import { AccountModelBase } from '../../server/mpsklad_core/Models/AccountModelBase'
import { MoySkladAppState } from '../../server/mpsklad_core/Models/MoySkladAppState'
import { OzonAccountModel } from '../../server/mpsklad_core/Models/OzonAccountModel'
import { TablePageOptions } from '../../server/mpsklad_core/Models/TablePageOptions'
import { StoreProductModel } from '../../server/mpsklad_core/Models/StoreProductModel'
import { CommisionsSettings } from '../../server/mpsklad_core/Models/CommisionsSettings'
import { EditWbAccountModel } from '../../server/mpsklad_core/Models/EditWbAccountModel'
import { RegisterModel } from '../../server/mpsklad_core/Models/ApiModels/RegisterModel'
import { EditOzonAccountModel } from '../../server/mpsklad_core/Models/EditOzonAccountModel'
import { StoreAccountModelBase } from '../../server/mpsklad_core/Models/StoreAccountModelBase'
import { MoySkladStatusMapModel } from '../../server/mpsklad_core/Models/MoySkladStatusMapModel'
import { YandexMarketProductProp } from '../../server/mpsklad_core/Models/YandexMarketProductProp'
import { EditMoySkladAccountModel } from '../../server/mpsklad_core/Models/EditMoySkladAccountModel'
import { YandexMarketAccountModel } from '../../server/mpsklad_core/Models/YandexMarketAccountModel'
import { SetStoreProductsSyncModel } from '../../server/mpsklad_core/Models/SetStoreProductsSyncModel'
import { MoySkladSetAttributesModel } from '../../server/mpsklad_core/Models/MoySkladSetAttributesModel'
import { MyProductsTableOrderColumn } from '../../server/mpsklad_core/Models/MyProductsTableOrderColumn'
import { MyProductsTableFilterColumn } from '../../server/mpsklad_core/Models/MyProductsTableFilterColumn'
import { EditYandexMarketAccountModel } from '../../server/mpsklad_core/Models/EditYandexMarketAccountModel'
import { YandexMarketAttributesForMoySkladModel } from '../../server/mpsklad_core/Models/YandexMarketAttributesForMoySkladModel'

export class Logic {
  private readonly hiddenMyProductColumnIdsKey = 'hiddenMyProductColumnIds'

  private readonly store: Store

  private readonly showUiError: (uiMessage: string) => void

  readonly api: ApiLogic

  readonly wbProductReloaders = new HandlersContainer()

  readonly ozonOrderLoaders = new HandlersContainer()

  readonly wbOrderLoaders = new HandlersContainer()

  readonly yandexMarketOrderLoaders = new HandlersContainer()

  readonly msOrderLoaders = new HandlersContainer()

  constructor(store: Store, snackbarRef: RefObject<SnackbarProvider>) {
    this.showUiError = (uiMessage: string) =>
      snackbarRef.current?.enqueueSnackbar(uiMessage, {variant: 'error', autoHideDuration: 7000})

    this.api = new ApiLogic(this.showUiError)

    this.store = store

    this.store.productStore.init(
      this.myProductsPageLoader,
      this.api.product.getOzonPage, this.api.product.getWbPage, this.api.product.getYandexMarketPage,
      this.api.product.getMoySkladPage)

    this.store.orderStore.init(this.api.order.getOzonPage, this.api.order.getWbPage, this.api.order.getYandexMarketPage,
      this.api.order.getMoySkladPage)

    this.store.adminStore.users.init(this.api.admin.users)

    this.loadMyProductColumnIds()
    this.trackMyProductColumnIds()

    this.trackAuthToken()
    this.trackAccounts()
  }

  healthCheck =
    (): Promise<boolean> =>
      this.api.healthCheck().catch(_ => false)

  loadUserAccounts =
    async () => {
      const {ozonAccounts, wbAccounts, yandexMarketAccounts, moySkladAccount} = await this.api.userSync.getSync()

      runInAction(() => {
        this.store.syncStore.ozonAccounts = ozonAccounts
        this.store.syncStore.wbAccounts = wbAccounts
        this.store.syncStore.yandexMarketAccounts = yandexMarketAccounts
        this.store.syncStore.moySkladAccount = moySkladAccount ?? null
      })
    }

  setOzonAccountSync =
    async (syncEnabled: boolean, ozonAccountId: number) => {
      await this.api.userSync.setOzonSync({isEnabled: syncEnabled, accountId: ozonAccountId})

      const account = required(this.store.syncStore.ozonAccounts.find(_ => _.id === ozonAccountId))
      account.syncEnabled = syncEnabled

      setTimeout(this.loadUserAccounts)
    }

  setOzonMultiProducts =
    async (allow: boolean) => {
      await this.api.userSync.setOzonMultiProducts(allow)

      const user = required(this.store.user)
      user.allowMultiOzonProducts = allow
    }

  setWbMultiProducts =
    async (allow: boolean) => {
      await this.api.userSync.setWbMultiProducts(allow)

      const user = required(this.store.user)
      user.allowMultiWbProducts = allow
    }

  setWbAccountSync =
    async (syncEnabled: boolean, wbAccountId: number) => {
      await this.api.userSync.setWbSync({isEnabled: syncEnabled, accountId: wbAccountId})

      const account = required(this.store.syncStore.wbAccounts.find(_ => _.id === wbAccountId))
      account.syncEnabled = syncEnabled

      setTimeout(this.loadUserAccounts)
    }

  setYandexMarketAccountSync =
    async (syncEnabled: boolean, yandexMarketAccountId: number) => {
      await this.api.userSync.setYandexMarketSync({isEnabled: syncEnabled, accountId: yandexMarketAccountId})

      const account = required(this.store.syncStore.yandexMarketAccounts.find(_ => _.id === yandexMarketAccountId))
      account.syncEnabled = syncEnabled

      setTimeout(this.loadUserAccounts)
    }

  setYandexMarketMultiProducts =
    async (allow: boolean) => {
      await this.api.userSync.setYandexMarketMultiProducts(allow)

      const user = required(this.store.user)
      user.allowMultiYandexMarketProducts = allow
    }

  setMoySkladAccountSync =
    async (syncEnabled: boolean) => {
      await this.api.userSync.setMoySkladSync(syncEnabled)

      const account = required(this.store.syncStore.moySkladAccount)
      account.syncEnabled = syncEnabled

      if (syncEnabled) {
        setTimeout(this.loadUserAccounts)
      }
    }

  setMsOrderAttributes =
    async (model: MoySkladSetAttributesModel) => {
      await this.api.userSync.setMoySkladAttributes(model)

      setTimeout(this.loadUserAccounts)
    }

  setYandexMarketAttributesForMoySkladAccount = async (model: YandexMarketAttributesForMoySkladModel) => {
    await this.api.userSync.setYandexMarketAttributesForMoySkladAccount(model)

    setTimeout(this.loadUserAccounts)
  }

  setOzonProductSync =
    async (msProductId: number, ozonProductId: number, syncEnabled: boolean) => {
      const product = await this.api.product.setOzonSync({
        msProductId,
        storeProductId: ozonProductId,
        syncEnabled,
        ...this.store.navState
      })

      this.store.productStore.replaceProduct(product)
      setTimeout(this.loadMyProductsInfo)
    }

  setWbProductSync =
    async (msProductId: number, wbProductId: number, syncEnabled: boolean) => {
      const product = await this.api.product.setWbSync({
        msProductId,
        storeProductId: wbProductId,
        syncEnabled,
        ...this.store.navState
      })

      this.store.productStore.replaceProduct(product)
      setTimeout(this.loadMyProductsInfo)
    }

  setYandexMarketProductSync =
    async (msProductId: number, yandexMarketProductId: number, syncEnabled: boolean) => {
      const product = await this.api.product.setYandexMarketSync({
        msProductId,
        storeProductId: yandexMarketProductId,
        syncEnabled,
        ...this.store.navState
      })

      this.store.productStore.replaceProduct(product)
      setTimeout(this.loadMyProductsInfo)
    }

  syncUser =
    async () => {
      const response = await this.api.syncCurrentUser()

      await this.loadUserAccounts()

      return response
    }

  loadMyProductsInfo =
    async () => {
      if (this.store.navState.accountId > 0) {
        this.store.productStore.myProductsInfo = await this.api.product.getMyInfo(this.store.navState)
      }
    }

  private loadMyProductsInfoDebounced =
    debounce(this.loadMyProductsInfo, 50)

  reloadMyProductsInfo =
    () => {
      this.store.productStore.myProductsInfo = null
      this.loadMyProductsInfoDebounced()
    }

  private myProductsPageLoader =
    (options: TablePageOptions<typeof MyProductsTableFilterColumn, typeof MyProductsTableOrderColumn>,
     config: AxiosRequestConfig | undefined) =>
      this.api.product.getMyPage({
        ...options,
        ...this.store.navState,
        productsStateTab: this.store.homeNavRequired.productsStateTab
      }, config)

  refreshMyProductsTab =
    () => this.store.productStore.myProducts.load()

  loadProductDiffs =
    async () => {
      if (this.store.syncStore.moySkladAccount != null) {
        this.store.productStore.productDiffs = await this.api.product.getDiffs()
      }
    }

  loadOzonProductOptions =
    (msProductId: number | undefined, searchTerm: string | undefined) =>
      this.store.navState.integrationType === IntegrationType.Ozon
      ? this.api.product.getOzonProductOptions({
        externalAccountId: this.store.navState.accountId,
        msProductId,
        searchTerm
      })
      : Promise.resolve([])

  loadWbProductOptions =
    (msProductId: number | undefined, searchTerm: string | undefined) =>
      this.store.navState.integrationType === IntegrationType.Wildberries
      ? this.api.product.getWbProductOptions({
        externalAccountId: this.store.navState.accountId,
        msProductId,
        searchTerm
      })
      : Promise.resolve([])

  loadYandexMarketProductOptions =
    (msProductId: number | undefined, searchTerm: string | undefined) =>
      this.store.navState.integrationType === IntegrationType.YandexMarket
      ? this.api.product.getYandexMarketProductOptions({
        externalAccountId: this.store.navState.accountId,
        msProductId,
        searchTerm
      })
      : Promise.resolve([])

  checkAuth =
    async () => {
      // Split the variable for proper IDE analysis
      let user: UserAuthModel

      try {
        user = await this.api.auth.check()
      } catch (e) {
        console.error('Auth check failed', e)
        this.store.user = null
        return
      }

      if (user.isImpersonating && user.moySkladAppState != null && user.moySkladAppState !== MoySkladAppState.Active) {
        this.showUiError(`Неактивное приложение МоегоСклада: ${MoySkladAppState[user.moySkladAppState]}`)
        user.moySkladAppState = MoySkladAppState.Active
      }

      this.store.user = user
      // this.store.user.moySkladAppType = MoySkladAppType.Wb
      await this.loadUserAccounts()
    }

  private checkMoySkladAppState =
    async () => {
      if (this.store.user?.moySkladAppState === MoySkladAppState.SettingsRequired) {
        this.store.user.moySkladAppState = await this.api.auth.checkMoySkladAppState()
      }
    }

  private ensureNoAuth = () => {
    if (this.store.hasAuth) {
      throw new Error('Already has auth!')
    }
  }

  login =
    async (email: string, password: string) => {
      this.ensureNoAuth()

      await this.api.auth.login({email, password})
      await this.checkAuth()
    }

  loginMoySkladApp =
    async (appType: MoySkladAppType, contextKey: string) => {
      this.ensureNoAuth()

      this.store.authToken = await this.api.auth.loginMoySkladApp({appType, contextKey})
      await this.checkAuth()
    }

  register =
    async (model: RegisterModel) => {
      this.ensureNoAuth()

      await this.api.auth.register(model)
      await this.checkAuth()
    }

  logout =
    async () => {
      await this.api.auth.logOut()
      this.store.authToken = null

      // Reload is simpler than resetting the store
      window.location.assign(appRoutes.MoySklad.settings.initial)
    }

  sendPasswordResetCodeEmail =
    async (email: string) => {
      this.ensureNoAuth()

      await this.api.auth.requestPasswordReset({email})

      this.store.passwordResetStore.setEmail(email)
    }

  verifyResetCode =
    async (resetCode: string) => {
      this.ensureNoAuth()

      const {passwordResetStore} = this.store

      await this.api.auth.verifyResetCode({
        email: required(passwordResetStore.email),
        resetCode
      })

      passwordResetStore.setResetCode(resetCode)
    }

  resetPassword =
    async (newPassword: string) => {
      this.ensureNoAuth()

      const {passwordResetStore} = this.store

      await this.api.auth.resetPassword({
        email: required(passwordResetStore.email),
        resetCode: required(passwordResetStore.resetCode),
        newPassword
      })

      passwordResetStore.setFinished()
      await this.checkAuth()
    }

  setOzonProducts =
    async (msProductId: number, ozonProductIds: number[]) => {
      const product = this.store.productStore.findProduct(msProductId)

      product.ozonProducts =
        await this.api.product.setOzonProducts({
          msProductId,
          externalProductIds: ozonProductIds,
          externalAccountId: this.store.navState.accountId
        })

      setTimeout(this.loadMyProductsInfo)
    }

  setWbProducts =
    async (msProductId: number, wbProductIds: number[]) => {
      const product = this.store.productStore.findProduct(msProductId)

      product.wbProducts =
        await this.api.product.setWbProducts({
          msProductId,
          externalProductIds: wbProductIds,
          externalAccountId: this.store.navState.accountId
        })

      setTimeout(this.loadMyProductsInfo)
    }

  setYandexMarketProducts =
    async (msProductId: number, yandexMarketProductIds: number[]) => {
      const product = this.store.productStore.findProduct(msProductId)

      product.yandexMarketProducts =
        await this.api.product.setYandexMarketProducts({
          msProductId,
          externalProductIds: yandexMarketProductIds,
          externalAccountId: this.store.navState.accountId
        })

      setTimeout(this.loadMyProductsInfo)
    }

  ozonSyncSelectedProducts =
    (syncEnabled: boolean) =>
      this.syncSelectedProducts(syncEnabled, IntegrationType.Ozon,
        _ => _.ozonProducts, this.api.product.setOzonSyncMultiple)

  wbSyncSelectedProducts =
    (syncEnabled: boolean) =>
      this.syncSelectedProducts(syncEnabled, IntegrationType.Wildberries,
        _ => _.wbProducts, this.api.product.setWbSyncMultiple)

  yandexMarketSyncSelectedProducts =
    (syncEnabled: boolean) =>
      this.syncSelectedProducts(syncEnabled, IntegrationType.YandexMarket,
        _ => _.yandexMarketProducts,
        this.api.product.setYandexMarketSyncMultiple)

  private syncSelectedProducts =
    async (syncEnabled: boolean,
           storeAccountType: IntegrationType,
           mapStoreProducts: (myProduct: MyProductModel) => StoreProductModel[],
           invokeApi: (model: SetStoreProductsSyncModel) => Promise<void>): Promise<void> => {
      const {productStore, navState} = this.store

      if (navState.integrationType !== storeAccountType) {
        throw new Error(`Bad store type: ${navState.integrationType} vs ${storeAccountType}`)
      }

      await invokeApi({
        syncEnabled,
        accountId: navState.accountId,
        productIds: productStore.myProducts.selectedRows.flatMap(mapStoreProducts).map(_ => _.id)
      })

      setTimeout(this.loadMyProductsInfo)
      setTimeout(this.refreshMyProductsTab)
    }

  createOzonAccount =
    async (model: EditOzonAccountModel): Promise<OzonAccountModel> => {
      const createdAccount = await this.api.userSync.createOzonAccount(model)
      this.store.syncStore.ozonAccounts.push(createdAccount)

      return createdAccount
    }

  editOzonAccount =
    async (model: EditOzonAccountModel) => {
      await this.api.userSync.editOzonAccount(model)
      await this.loadUserAccounts()
    }

  deleteOzonAccount =
    async (accountId: number) => {
      await this.api.userSync.deleteOzonAccount(accountId)
      await this.loadUserAccounts()
    }

  createWbAccount =
    async (model: EditWbAccountModel): Promise<WbAccountModel> => {
      const createdAccount = await this.api.userSync.createWbAccount(model)
      this.store.syncStore.wbAccounts.push(createdAccount)

      return createdAccount
    }

  editWbAccount =
    async (model: EditWbAccountModel) => {
      await this.api.userSync.editWbAccount(model)
      await this.loadUserAccounts()
    }

  deleteWbAccount =
    async (accountId: number) => {
      await this.api.userSync.deleteWbAccount(accountId)
      await this.loadUserAccounts()
    }

  createYandexMarketAccount =
    async (model: EditYandexMarketAccountModel): Promise<YandexMarketAccountModel> => {
      const createdAccount = await this.api.userSync.createYandexMarketAccount(model)
      this.store.syncStore.yandexMarketAccounts.push(createdAccount)

      return createdAccount
    }

  editYandexMarketAccount =
    async (model: EditYandexMarketAccountModel) => {
      await this.api.userSync.editYandexMarketAccount(model)
      await this.loadUserAccounts()
    }

  deleteYandexMarketAccount =
    async (accountId: number) => {
      await this.api.userSync.deleteYandexMarketAccount(accountId)
      await this.loadUserAccounts()
    }

  deleteAccount =
    async (accountId: number) => {
      const storeType = this.store.getHomeNav()?.integrationType

      switch (storeType) {
        case IntegrationType.Ozon:
          await this.deleteOzonAccount(accountId)
          break

        case IntegrationType.Wildberries:
          await this.deleteWbAccount(accountId)
          break

        case IntegrationType.YandexMarket:
          await this.deleteYandexMarketAccount(accountId)
          break

        default:
          throw new Error(`Bad enum value for IntegrationType: ${storeType}.`)
      }
    }

  editMoySkladAccount =
    async (model: EditMoySkladAccountModel) => {
      this.store.syncStore.moySkladAccount = await this.api.userSync.editMoySkladAccount(model)
    }

  // TODO: UI Logic?
  confirmSyncEnable =
    () => this.showDialog('Внимание, после включения синхронизации остатки на маркетплейсах будут перезаписаны!')

  confirmSyncDisable =
    () => this.showDialog('Синхронизация будет отключена. Заказы и остатки перестанут обновляться.')

  showDialog =
    (text: string,
     options?: {
       acceptButton?: string
       declineButton?: string
       title?: string
     }): Promise<boolean> => {
      if (this.store.dialog !== null) {
        throw new Error('Another dialog is already shown')
      }

      return new Promise(resolve =>
        this.store.dialog = {
          text,
          title: options?.title ?? 'Продолжить?',
          acceptButton: options?.acceptButton ?? 'Продолжить',
          declineButton: options?.declineButton ?? 'Отмена',
          onClose: resolve
        })
    }

  acceptDialog = () => {
    required(this.store.dialog).onClose(true)
    this.store.dialog = null
  }

  declineDialog = () => {
    required(this.store.dialog).onClose(false)
    this.store.dialog = null
  }

  toggleMyProductsColumn =
    (columnId: MyProductColumnId) => {
      if (this.store.hiddenMyProductColumnIds.has(columnId)) {
        this.store.hiddenMyProductColumnIds.delete(columnId)
      } else {
        this.store.hiddenMyProductColumnIds.add(columnId)
      }
    }

  matchOzonToMoySkladProducts =
    async (ozonProp: OzonProductProp, moySkladProp: MsProductProp, accountIdFrom: number,
           accountIdTo: number): Promise<number> =>
      this.matchProducts(
        () => this.api.userSync.matchOzonToMoySkladProducts(
          {fromProp: ozonProp, toProp: moySkladProp, accountIdFrom, accountIdTo}))

  matchWbToMoySkladProducts =
    async (wbProp: WbProductProp, moySkladProp: MsProductProp, accountIdFrom: number,
           accountIdTo: number): Promise<number> =>
      this.matchProducts(() => this.api.userSync.matchWbToMoySkladProducts(
        {fromProp: wbProp, toProp: moySkladProp, accountIdFrom, accountIdTo}))

  matchYandexMarketToMoySkladProducts =
    async (yandexMarketProp: YandexMarketProductProp, moySkladProp: MsProductProp, accountIdFrom: number,
           accountIdTo: number): Promise<number> =>
      this.matchProducts(
        () => this.api.userSync.matchYandexMarketToMoySkladProducts(
          {fromProp: yandexMarketProp, toProp: moySkladProp, accountIdFrom, accountIdTo}))

  private matchProducts =
    async (invokeApi: () => Promise<number>): Promise<number> => {
      const productCount = await invokeApi()

      setTimeout(this.loadMyProductsInfo)
      setTimeout(this.refreshMyProductsTab)

      return productCount
    }

  unmatchOzonProducts =
    async (ozonAccountId: number) => {
      await this.api.userSync.unmatchOzonProducts(ozonAccountId)

      await this.loadMyProductsInfo()
      await this.refreshMyProductsTab()
    }

  unmatchWbProducts =
    async (wbAccountId: number) => {
      await this.api.userSync.unmatchWbProducts(wbAccountId)

      await this.loadMyProductsInfo()
      await this.refreshMyProductsTab()
    }

  unmatchYandexMarketProducts =
    async (yandexMarketAccountId: number) => {
      await this.api.userSync.unmatchYandexMarketProducts(yandexMarketAccountId)

      await this.loadMyProductsInfo()
      await this.refreshMyProductsTab()
    }

  setMoySkladStatusMaps =
    async (model: MoySkladStatusMapModel[]) => {
      await this.api.userSync.setMoySkladStatusMaps(model)
      setTimeout(this.checkMoySkladAppState)
    }

  verifyMoySkladWebhooks =
    async () => {
      await this.api.userSync.verifyMoySkladWebhooks()
    }

  setUserSync =
    async (userId: string, allowSync: boolean) => {
      const user = required(this.store.adminStore.users.data.find(_ => _.id === userId))
      await this.api.admin.setUserSync({userId, allowSync})
      user.allowSync = allowSync
    }

  setFakeApiFlag =
    async (userId: string, useFakeApi: boolean) => {
      const user = required(this.store.adminStore.users.data.find(_ => _.id === userId))
      await this.api.admin.setFakeApiFlag({userId, useFakeApi})
      user.isUsingFakeApi = useFakeApi
    }

  private loadMyProductColumnIds =
    () => {
      if (!isLocalStorageAllowed) {
        return
      }

      try {
        const hiddenColumnIdsJson = localStorage.getItem(this.hiddenMyProductColumnIdsKey)

        if (!hiddenColumnIdsJson) {
          return
        }

        const hiddenColumnIds = new Set<MyProductColumnId>(JSON.parse(hiddenColumnIdsJson))

        if (hiddenColumnIds.size > 0) {
          this.store.hiddenMyProductColumnIds = hiddenColumnIds
        }
      } catch (e) {
        console.error('Failed to load hiddenMyProductColumnIds', e)

        try {
          localStorage.removeItem(this.hiddenMyProductColumnIdsKey)
        } catch (e2) {
          console.error('Failed to clear hiddenMyProductColumnIds', e2)
        }
      }
    }

  private trackMyProductColumnIds =
    () => {
      if (!isLocalStorageAllowed) {
        return
      }

      return reaction(
        () => Array.from(this.store.hiddenMyProductColumnIds),
        hiddenColumnIds => {
          try {
            localStorage.setItem(this.hiddenMyProductColumnIdsKey, JSON.stringify(hiddenColumnIds))
          } catch (e) {
            console.error('Failed to save hiddenMyProductColumnIds', e)
          }
        },
        {equals: comparer.shallow})
    }

  private trackAuthToken =
    () => reaction(
      () => this.store.authToken,
      authToken => this.api.headerAuthToken = authToken)

  private trackAccounts =
    () => {
      const {syncStore} = this.store

      when(() => syncStore.ozonAccounts.length > 0 || syncStore.wbAccounts.length > 0
                 || syncStore.yandexMarketAccounts.length > 0,
        this.setHomeNavInitial)

      reaction(
        () => syncStore,
        () => {
          if (!syncStore.ozonAccounts.length && !syncStore.wbAccounts.length
              && !syncStore.yandexMarketAccounts.length) {
            return
          }

          const homeNav = this.store.getHomeNav()

          if (!homeNav) {
            return
          }

          switch (homeNav.integrationType) {
            case IntegrationType.Ozon: {
              if (homeNav.accountId && syncStore.ozonAccounts.some(_ => _.id === homeNav.accountId)) {
                // Valid
                return
              }

              this.setHomeNavFirstAccount()
              return
            }

            case IntegrationType.Wildberries: {
              if (homeNav.accountId && syncStore.wbAccounts.some(_ => _.id === homeNav.accountId)) {
                // Valid
                return
              }

              this.setHomeNavFirstAccount()
              return
            }

            case IntegrationType.YandexMarket: {
              if (homeNav.accountId && syncStore.yandexMarketAccounts.some(
                account => account.id === homeNav.accountId)) {
                // Valid
                return
              }

              this.setHomeNavFirstAccount()
              return
            }

            default:
              throw new Error(`Unprocessed IntegrationType: ${homeNav.integrationType}`)
          }
        })
    }

  setHomeNavInitial = () => {
    this.setHomeNavFirstAccount()
  }

  setHomeNavFirstAccount = () => {
    const {syncStore, setHomeNav} = this.store

    if (syncStore.ozonAccounts.length > 0) {
      setHomeNav(IntegrationType.Ozon, syncStore.ozonAccounts[0].id)
    } else if (syncStore.wbAccounts.length > 0) {
      setHomeNav(IntegrationType.Wildberries, syncStore.wbAccounts[0].id)
    } else if (syncStore.yandexMarketAccounts.length > 0) {
      setHomeNav(IntegrationType.YandexMarket, syncStore.yandexMarketAccounts[0].id)
    }
  }

  setNavAccountFromSpecifiedStore =
    (integrationType: IntegrationType,
     accountIndex: number = 0) => {
      const {syncStore, setNavFirstAccount} = this.store
      const setHomeNavForStore = (accounts: {id: number}[]) => {
        setNavFirstAccount(integrationType, accounts[accountIndex]?.id)
      }

      switch (integrationType) {
        case IntegrationType.YandexMarket:
          setHomeNavForStore(syncStore.yandexMarketAccounts)
          break
        case IntegrationType.Wildberries:
          setHomeNavForStore(syncStore.wbAccounts)
          break
        case IntegrationType.Ozon:
          setHomeNavForStore(syncStore.ozonAccounts)
          break
      }
    }

  pullOzonOrder =
    async (ozonAccountId: number, ozonOrderId: number) => {
      await this.api.order.pullOzon(ozonOrderId)
      await this.ozonOrderLoaders.trigger()
    }

  pullWbOrder =
    async (wbAccountId: number, wbOrderId: number) => {
      await this.api.order.pullWb(wbOrderId)
      await this.wbOrderLoaders.trigger()
    }

  pullYandexMarketOrder =
    async (yandexMarketAccountId: number, yandexMarketOrderId: number) => {
      await this.api.order.pullYandexMarket(yandexMarketOrderId)
      await this.yandexMarketOrderLoaders.trigger()
    }

  pullMoySkladOrder =
    async (msOrderId: number) => {
      await this.api.order.pullMoySklad(msOrderId)
      await this.msOrderLoaders.trigger()
    }

  loadOzonFiles =
    async (ozonAccountId: number): Promise<void> => {
      this.store.labelStore.ozonFiles.data = await this.api.label.ozonFiles(ozonAccountId)
    }

  loadWbFiles =
    async (wbAccountId: number): Promise<void> => {
      this.store.labelStore.wbFiles.data = await this.api.label.wbFiles(wbAccountId)
    }

  loadYandexMarketFiles =
    async (yandexMarketAccountId: number): Promise<void> => {
      this.store.labelStore.yandexMarketFiles.data = await this.api.label.yandexMarketFiles(yandexMarketAccountId)
    }

  // TODO: (redesign) Move these methods to store. Inline accountId?
  tryGetYandexMarketAccount =
    (accountId: number | undefined): YandexMarketAccountModel | undefined =>
      this.tryGetStoreAccount(accountId, this.store.syncStore.yandexMarketAccounts)

  tryGetOzonAccount =
    (accountId: number | undefined): OzonAccountModel | undefined =>
      this.tryGetStoreAccount(accountId, this.store.syncStore.ozonAccounts)

  tryGetWbAccount =
    (accountId: number | undefined): WbAccountModel | undefined =>
      this.tryGetStoreAccount(accountId, this.store.syncStore.wbAccounts)

  tryGetAccount =
    (accountId: number | undefined, integration: IntegrationTypeKey | undefined): AccountModelBase | undefined => {
      switch (integration) {
        case 'Ozon':
          return this.tryGetOzonAccount(accountId)

        case 'Wildberries':
          return this.tryGetWbAccount(accountId)

        case 'YandexMarket':
          return this.tryGetYandexMarketAccount(accountId)

        case 'MoySklad':
          const moySkladAccount = this.store.syncStore.moySkladAccount as AccountModelBase
          return moySkladAccount?.id === accountId ? moySkladAccount : undefined

        case undefined:
          return undefined
      }
    }

  private tryGetStoreAccount =
    <TAccount extends StoreAccountModelBase>
    (accountId: number | undefined, storeAccounts: TAccount[]): TAccount | undefined =>
      accountId ? storeAccounts.find(_ => _.id === accountId)
                : undefined

  setCommissionsSettings =
    async (settings: CommisionsSettings) => {
      await this.api.userSync.setCommissionsSettings(settings)

      setTimeout(this.loadUserAccounts)
    }

  getSetAccountSyncFunc = (integrationType: IntegrationType) => {
    switch (integrationType) {
      case IntegrationType.Ozon:
        return this.setOzonAccountSync
      case IntegrationType.YandexMarket:
        return this.setYandexMarketAccountSync
      case IntegrationType.Wildberries:
        return this.setWbAccountSync
      default:
        throw new Error('Unsupported IntegrationType')
    }
  }

  getLastColumnsIdsByColumnGroups = () => {
    const lastColumnIndexByGroup: Record<string, string> = {}

    const groupedColumns: {
      MyStorage: MyProductColumn[];
      Ozon: MyProductColumn[];
      Wildberries: MyProductColumn[];
      YandexMarket: MyProductColumn[]
    } = this.store.myProductColumnsGrouped

    Object.entries(groupedColumns).forEach(([groupId, columns]) => {
      const filteredColumns = columns
        .filter(column => !this.store.hiddenMyProductColumnIds.has(column.id))
        .filter(column => this.store.homeNavRequired.productsStateTab !== MyProductState.Error
                          ? true
                          : column.id !== syncErrorsColumnId)
      if (filteredColumns.length > 0) {
        lastColumnIndexByGroup[groupId] = filteredColumns[filteredColumns.length - 1].id as string
      }
    })

    const visibleGroups = Object.keys(lastColumnIndexByGroup)
    const lastGroup = visibleGroups[visibleGroups.length - 1]
    delete lastColumnIndexByGroup[lastGroup]

    return lastColumnIndexByGroup
  }
}