import React from 'react'
import { observer } from 'mobx-react-lite'
import fromUnixTime from 'date-fns/fromUnixTime'

import clsx from 'clsx'
import { makeStyles } from '@material-ui/core/styles'
import { Box } from '@material-ui/core'

import { SyncStateText } from './SyncStateText'

import { useStore } from '../hooks/storeHook'
import { useSyncError } from '../hooks/syncErrorHook'

import { formatDateTime } from '../common/dateTimeUtility'
import { IntegrationTypeKey } from '../types/integrationTypeUtils'
import { SyncStateModel } from '../server/mpsklad_core/Models/SyncStateModel'

export type LastSyncAtTextProps = {
  type: IntegrationTypeKey

  state: SyncStateModel | undefined
}

export const SyncStateInput =
  observer(
    ({type, state}: LastSyncAtTextProps) => {
      const classes = useStyles()

      const {user} = useStore()

      const {onErrorClick, SyncErrorDialog} = useSyncError(type, state?.errorId)

      if (!state) {
        return <Box
          className={clsx(classes.cell, classes.disabledCell)}>
          <span className={classes.text}>&mdash;</span>
        </Box>
      }

      const syncDate = fromUnixTime(state.date)

      if (state.success) {
        return (
          <Box
            className={clsx(classes.cell, classes.successCell)}
            title={user?.isSuperAdmin || user?.isImpersonating
                   ? formatDateTime(syncDate)
                   : undefined}
          >
            <span className={classes.text}>OK</span>
          </Box>
        )
      }

      // NOTE: Can't make the cell clickable - messes up the dialog
      return (
        <Box
          className={clsx(classes.cell, classes.errorCell)}>
          <span className={clsx(classes.clickable, classes.text)} title={'Показать ошибку'} onClick={onErrorClick}>
            Ошибка! <SyncStateText syncDate={syncDate}/>
          </span>

          <SyncErrorDialog/>
        </Box>
      )
    })

export const useStyles = makeStyles(
  (/*theme*/) => ({
    cell: {
      minWidth: 160,
      border: '1px solid rgba(0, 0, 0, 0.26)',
      borderRadius: 4,
      height: 35,
      fontSize: 12,
      display: 'flex',
      alignItems: 'center'
    },
    text: {
      paddingLeft: 10
    },
    disabledCell: {
      // NOTE: min-width reset adds a scroll for some reason
    },
    successCell: {
      background: '#EFF7FF'
    },
    errorCell: {
      background: '#F5998C'
    },
    clickable: {
      cursor: 'pointer'
    }
  }))