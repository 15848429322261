import React from 'react'
import { observer } from 'mobx-react-lite'

import { Box, FormControl, FormHelperText, Grid, InputLabel } from '@material-ui/core'

import { MyServerTable } from './MyServerTable'
import { OzonProductsArchivedFilter } from './OzonProductsArchivedFilter'
import { OzonTableHasMsProductFilter } from './OzonTableHasMsProductFilter'

import { OzonProductsTableData } from '../store/productStore'

import { TableColumn } from '../types/tableTypes'
import { OzonProductColumnId } from '../types/ozonColumns'
import { OzonProductModel } from '../server/mpsklad_core/Models/OzonProductModel'
import { makeStyles } from '@material-ui/core/styles'
import clsx from 'clsx'
import { BootstrapInput } from './BootstrapInput'

export type OzonProductsTableProps = {
  products: OzonProductsTableData

  columns: Array<TableColumn<OzonProductColumnId, OzonProductModel>>
}

export const OzonProductsTable =
  observer(
    ({products, columns}: OzonProductsTableProps) => {
      const classes = useStyles()

      return <Grid item lg={12} xl={12}>
        <Box className={clsx(classes.container, 'default-border')}>
          <Box marginY={2} display={'flex'}>
            <Box>
              <FormControl fullWidth>
                <InputLabel shrink htmlFor="search-ozon">
                  Поиск
                </InputLabel>
                <BootstrapInput
                  id="search-ozon"
                  placeholder="Поиск"
                  onChange={e => products.setGlobalFilter(e.target.value)}
                  inputProps={{
                    'aria-label': 'Поиск'
                  }}
                />
                <FormHelperText>Id / Артикул / Наименование / Штрихкод</FormHelperText>
              </FormControl>
            </Box>

            <Box marginLeft={5}>
              <OzonTableHasMsProductFilter products={products}/>
            </Box>

            <Box marginX={5}>
              <OzonProductsArchivedFilter products={products}/>
            </Box>
          </Box>

          <MyServerTable
            tableProps={{size: 'small'}}
            table={products}
            columns={columns}
            labelNoRows="Нет товаров"
            labelNotFound="Товары не найдены"
            labelRowsPerPage="Товаров на странице:"
          />
        </Box>
      </Grid>
    })

const useStyles = makeStyles(() => ({
  container: {
    minWidth: 'calc(100vw - 410px)',
    borderRadius: 12,
    padding: 30,
    marginTop: 10
  },
  label: {
    fontSize: 12,
    padding: 10
  },
  input: {
    fontSize: 12,
    height: 40
  }
}))