import { observer } from 'mobx-react-lite'

import { Box } from '@material-ui/core'

import { Route, Switch } from 'react-router-dom'

import { useStore } from '../../hooks/storeHook'

import { appRoutes } from '../../common/appRoutes'

import { IntegrationType } from '../../server/mpsklad_core/Entity/IntegrationType'

import { YandexMarketSettingsPage } from './YandexMarket/YandexMarketSettingsPage'
import { WildberriesSettingsPage } from './Wildberries/WildberriesSettingsPage'
import { MoySkladSettingsPage } from './MoySklad/MoySkladSettingsPage'
import { OzonSettingsPage } from './Ozon/OzonSettingsPage'
import { useValidateStoreAccountRoute } from '../../hooks/validateStoreAccountRoute'
import React from 'react'

export const SettingsRoute = observer(() => {
  const {integration} = useValidateStoreAccountRoute(appRoutes.CommonStore.settings)

  const {allowedIntegrations} = useStore()

  return (
    <div className="body_wrapper_zero body_wrapper__home">
      <Box flex={1}>
        <Switch>
          {
            allowedIntegrations[IntegrationType.YandexMarket] &&
            integration === 'YandexMarket' &&
            <Route
              path={appRoutes.CommonStore.settings.template}
              component={YandexMarketSettingsPage}/>
          }

          {
            allowedIntegrations[IntegrationType.Wildberries] &&
            integration === 'Wildberries' &&
            <Route path={appRoutes.CommonStore.settings.template}
                   component={WildberriesSettingsPage}/>
          }

          {
            allowedIntegrations[IntegrationType.Ozon] &&
            integration === 'Ozon' &&
            <Route path={appRoutes.CommonStore.settings.template}
                   component={OzonSettingsPage}/>
          }

          {
            integration === 'MoySklad' &&
            <Route
              path={appRoutes.MoySklad.settings.template}
              component={MoySkladSettingsPage}
            />
          }

        </Switch>
      </Box>
    </div>
  )
})