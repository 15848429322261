import { makeObservable, observable } from 'mobx'

import { WbAccountModel } from '../server/mpsklad_core/Models/WbAccountModel'
import { OzonAccountModel } from '../server/mpsklad_core/Models/OzonAccountModel'
import { MoySkladAccountModel } from '../server/mpsklad_core/Models/MoySkladAccountModel'
import { StoreAccountModelBase } from '../server/mpsklad_core/Models/StoreAccountModelBase'
import { YandexMarketAccountModel } from '../server/mpsklad_core/Models/YandexMarketAccountModel'
import { IntegrationType } from '../server/mpsklad_core/Entity/IntegrationType'

export class SyncStore {
  @observable
  ozonAccounts: OzonAccountModel[]

  @observable
  wbAccounts: WbAccountModel[]

  @observable
  yandexMarketAccounts: YandexMarketAccountModel[]

  @observable
  moySkladAccount: MoySkladAccountModel | null

  constructor() {
    makeObservable(this)

    this.ozonAccounts = []
    this.wbAccounts = []
    this.yandexMarketAccounts = []
    this.moySkladAccount = null
  }

  getStoreAccounts(storeType: IntegrationType): StoreAccountModelBase[] {
    switch (storeType) {
      case IntegrationType.Ozon:
        return this.ozonAccounts

      case IntegrationType.Wildberries:
        return this.wbAccounts

      case IntegrationType.YandexMarket:
        return this.yandexMarketAccounts

      default:
        throw new Error(`Unprocessed store type: ${storeType}`)
    }
  }
}