import React, { useRef, useState } from 'react'
import { MaterialDesignContent, SnackbarProvider } from 'notistack'
import ruLocale from 'date-fns/locale/ru'

import { styled, ThemeProvider } from '@material-ui/core/styles'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'

import './App.css'

import { AppDialog } from './AppDialog'
import { AppContent } from './AppContent'
import { RuDateFnsUtils } from './RuDateFnsUtils'

import { Store } from '../store/store'
import { Logic } from '../store/logic/logic'
import { AppContext } from '../store/appContext'
import { configureMobx } from '../store/mobxConfig'

import { appTheme } from '../common/appTheme'
import { checkTranspilerForClassProperties } from '../common/tsUtils'

configureMobx()

if (!checkTranspilerForClassProperties()) {
  console.error('Transpiler is not configured correctly for class properties')
}

export const App = () => {
  const snackbarRef = useRef<SnackbarProvider>(null)

  const [appContext] = useState<AppContext>(() => {
    const store = new Store()

    return ({
      store,
      logic: new Logic(store, snackbarRef)
    })
  })

  return (
    <AppContext.Provider value={appContext}>
      <ThemeProvider theme={appTheme}>
        <MuiPickersUtilsProvider utils={RuDateFnsUtils} locale={ruLocale}>
          <SnackbarProvider maxSnack={3} ref={snackbarRef} anchorOrigin={{vertical: 'bottom', horizontal: 'center'}}
                            Components={{success: StyledMaterialDesignContent}} hideIconVariant>
            <AppContent/>

            <AppDialog/>
          </SnackbarProvider>
        </MuiPickersUtilsProvider>
      </ThemeProvider>
    </AppContext.Provider>
  )
}

const StyledMaterialDesignContent = styled(MaterialDesignContent)(() => ({
  '&.notistack-MuiContent-success': {
    backgroundColor: '#61BD83'
  },
  '&.notistack-MuiContent-error': {
    backgroundColor: '#F5998C'
  },
  '&.notistack-MuiContent-warning': {
    backgroundColor: '#F0AD65'
  }
}))