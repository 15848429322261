import React from 'react'
import { observer } from 'mobx-react-lite'
import { sortBy } from 'lodash'

import { FormHelperText, MenuItem } from '@material-ui/core'
import { InputBaseProps } from '@material-ui/core/InputBase/InputBase'

import { StorePickerBaseProps } from './WarehousePicker'
import { CustomSelectOption, DefaultSelect } from './DefaultSelect'

import { useDefaultMenuItemStyles } from '../hooks/defaultSelectStylesHook'

import { required } from '../common/objectUtils'
import { emDash } from '../common/stringUtility'

import { MoySkladStoreModel } from '../server/mpsklad_core/Models/MoySkladStoreModel'

export type MsStorePickerProps =
  StorePickerBaseProps
  & Pick<InputBaseProps, 'disabled'>
  & {
  msStores: MoySkladStoreModel[]

  filterMatchedStores?: boolean

  onChange: (msStore: MoySkladStoreModel | null) => void
} & ({
  allowEmpty: true

  msStoreId: string | undefined
} | {
  allowEmpty?: false

  msStoreId: string
})

export const MoySkladStorePicker =
  observer(
    ({
       disabled,
       allowEmpty, msStoreId,
       formData, msStores,
       filterMatchedStores,
       onChange
     }: MsStorePickerProps) => {
      const menuClasses = useDefaultMenuItemStyles()

      const selectedMsStore = msStores.find(_ => _.id === msStoreId)

      const availableMsStoreOptions =
        sortBy(
          filterMatchedStores
          ? msStores.filter(msStore => formData.stores.every(store => !store.msStoreIds.includes(msStore.id)))
                    .concat(selectedMsStore ?? [])
          : msStores,
          _ => _.name, _ => _.id)
          .map(msStore => ({
              value: msStore,
              label: msStore.name
            } satisfies CustomSelectOption<MoySkladStoreModel>
          ))

      const onSelectChange =
        (newMsStore: MoySkladStoreModel | undefined) =>
          onChange(allowEmpty && !newMsStore ? null : required(newMsStore))

      return <>
        {
          !allowEmpty && !selectedMsStore &&
          <MenuItem disabled className={menuClasses.menuItem} value={msStoreId}>
            <FormHelperText error>Не найден в МоёмСкладе: {msStoreId}</FormHelperText>
          </MenuItem>
        }

        <DefaultSelect
          fullWidth
          placeholder={emDash}
          value={selectedMsStore}
          withUnselectedValue={allowEmpty}
          options={availableMsStoreOptions}
          onChange={onSelectChange}
          inputProps={{disabled}}
        />
      </>
    })