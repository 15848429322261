import React from 'react'
import { observer } from 'mobx-react-lite'

import { ReactiveTabs } from '../ReactiveTabs'
import { MyProductsFilter } from '../MyProductsFilter'
import { ProductsTabTitle } from '../ProductsTabTitle'
import { HomeProductsTable } from '../HomeProductsTable'
import { MyProductsTopPagination } from '../MyProductsTopPagination'

import { useLazyEffect } from '../../hooks/commonHooks'
import { useLogic, useStore } from '../../hooks/storeHook'

import { MyProductState } from '../../server/mpsklad_core/Models/MyProductState'

export const ProductsScreenTable =
  observer(() => {
    const logic = useLogic()
    const store = useStore()

    const homeNav = store.homeNavRequired
    const accountId = homeNav.accountId

    useLazyEffect(() => logic.reloadMyProductsInfo())

    const {productStore: {myProductsInfo}} = store

    const onTabChange =
      (newTab: MyProductState) => {
        switch (newTab) {
          case MyProductState.Active:
          case MyProductState.New:
          case MyProductState.Error:
          case MyProductState.FromMarketplace:
            store.setHomeTab(newTab)
            return

          default:
            throw new Error(`Unprocessed products tab: ${newTab}`)
        }
      }

    if (!homeNav || !accountId) {
      return null
    }

    return (
      <ReactiveTabs
        tab={homeNav.productsStateTab}
        titles={[
          <ProductsTabTitle title="Активные товары" count={myProductsInfo?.activeCount}/>,
          <ProductsTabTitle title="Не сопоставлено" count={myProductsInfo?.newCount}/>,
          <ProductsTabTitle title="Товары с ошибками" count={myProductsInfo?.errorCount}/>,
          <ProductsTabTitle title="C маркетплейсов" count={myProductsInfo?.fromMarketPlaceCount}/>
        ]}
        appBarProps={{elevation: 1}}
        onTabChange={onTabChange}
        AfterTabsComponent={
          homeNav.productsStateTab !== MyProductState.FromMarketplace
          ? MyProductsTopPagination
          : undefined
        }
      >
        {
          homeNav.productsStateTab !== MyProductState.FromMarketplace &&
          <div className="body_wrapper">
            <MyProductsFilter/>
          </div>
        }

        <HomeProductsTable/>
      </ReactiveTabs>
    )
  })