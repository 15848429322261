import { add, isBefore, isSameDay, set, startOfDay } from 'date-fns'

import { dateUtils } from './dateTimeUtility'
import { OrdersAtDateCount } from '../types/ordersAtDateCount'
import { PrintStoreOrderWithShipmentModel } from '../server/mpsklad_core/Models/PrintStoreOrderWithShipmentModel'
import { maxShipmentDate, minShipmentDate } from '../components/LabelsPostDateFilters'

export const filterPreviewByShipmentDate =
  <TOrder extends PrintStoreOrderWithShipmentModel>(
    newDate: Date | null,
    preview: TOrder[] | null,
    dateCounts: OrdersAtDateCount[]
  ): {
    previewWithShipmentFilters: TOrder[] | null
    shipmentDateMin: Date
    shipmentDateMax: Date
  } => {
    if (preview == null || newDate == null) {
      return {
        previewWithShipmentFilters: preview,
        shipmentDateMin: minShipmentDate,
        shipmentDateMax: maxShipmentDate
      }
    }

    const maxLocalDate = set(dateCounts[dateCounts.length - 1]?.date ?? minShipmentDate, {hours: 24})

    if (isBefore(maxLocalDate, newDate)) {
      return {
        shipmentDateMin: maxLocalDate,
        shipmentDateMax: maxShipmentDate,
        previewWithShipmentFilters: preview.filter(order => isBeforeByDate(maxLocalDate, order.shipmentDate))
      }
    }

    const previewFiltered = preview.filter(
      order => order.shipmentDate && isSameDay(new Date(order.shipmentDate), newDate))

    // This relies on preview being ordered by shipmentDate by the backend
    const filteredShipmentDateMin = previewFiltered[0]?.shipmentDate
    const filteredShipmentDateMax = previewFiltered[previewFiltered.length - 1]?.shipmentDate

    return {
      previewWithShipmentFilters: previewFiltered,
      shipmentDateMin: filteredShipmentDateMin ? new Date(filteredShipmentDateMin) : minShipmentDate,
      shipmentDateMax: filteredShipmentDateMax ? new Date(filteredShipmentDateMax) : maxShipmentDate
    }
  }

/**
 * Computes amounts of orders that have shipment date that equals today, tomorrow and the day after tomorrow.
 */
export const getOrderShipmentDateCounts =
  (orders: PrintStoreOrderWithShipmentModel[] | null): OrdersAtDateCount[] => {
    if (orders == null) {
      return []
    }

    const now = dateUtils.now

    const dateCounts = [
      {date: now, count: 0},
      {date: add(now, {days: 1}), count: 0},
      {date: add(now, {days: 2}), count: 0}
    ]

    for (const order of orders) {
      if (!order.shipmentDate) {
        continue
      }

      const orderShipmentDate = new Date(order.shipmentDate)

      const orderDateCount = dateCounts.find(_ => isSameDay(_.date, orderShipmentDate))

      if (orderDateCount) {
        orderDateCount.count++
      }
    }

    return dateCounts.filter(_ => _.count > 0)
  }

/**
 * Determines whether leftDate is less than rightDate with date precision.
 * TODO: Make rightDate a Date?
 */
const isBeforeByDate =
  (leftDate: Date, rightDate: string | null | undefined): boolean =>
    !!rightDate && isBefore(startOfDay(leftDate), startOfDay(new Date(rightDate)))