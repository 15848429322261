import React, { ReactElement, useCallback } from 'react'

import { matchPath, useHistory } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'

import { Tab, Tabs } from '@material-ui/core'

import { RouteTabItem } from './RouteTabItem'

export type RouteTabsProps = {
  /**
   * Must be a single Switch component.
   */
  children: ReactElement,

  routeTabs: RouteTabItem[]
}

export const RoutingTabs = ({routeTabs, children}: RouteTabsProps) => {
  const tabClasses = useRouteTabsStyles()

  const history = useHistory()

  const activeTabIndex = Math.max(
    0,
    routeTabs.findIndex(route =>
      matchPath(history.location.pathname, {
        path: route.template,
        exact: true,
        strict: false
      })
    )
  )
  const onTabChange = useCallback(
    (_: unknown, newActiveTabIndex: number) => {
      const newPath = routeTabs[newActiveTabIndex]?.path

      if (newPath && history.location.pathname !== newPath) {
        history.push(newPath)
      }
    }, [history, routeTabs])

  return (<>
      <div style={{width: '100%', paddingTop: '20px', paddingBottom: '20px'}}>
        <Tabs
          value={activeTabIndex}
          onChange={onTabChange}
          className={tabClasses.tabs}
          TabIndicatorProps={{hidden: true}}
        >
          {routeTabs.map((route, index) => (
            <Tab key={index} label={<p>{route.title}</p>} disableRipple
                 disabled={route.isDisabled}/>
          ))}
        </Tabs>
      </div>

      {children}
    </>
  )
}

const useRouteTabsStyles = makeStyles(
  (theme) => ({
    tabs: {
      maxWidth: '100%',
      minWidth: 0,
      '& button': {
        maxWidth: '100px'
      },
      '& p': {
        fontSize: 12,
        fontWeight: 400,
        color: theme.palette.mpSkladPrimary.gray
      },
      '& .Mui-selected p': {
        color: theme.palette.mpSkladPrimary.blue,
        fontSize: 12,
        fontWeight: 600,
        borderBottom: '2px solid'
      },
      '& .MuiTab-root': {
        padding: 0,
        minWidth: 'fit-content',
        marginRight: 40,
        overflow: 'unset'
      }
    }
  })
)