import React from 'react'
import { observer } from 'mobx-react-lite'

import { Box, CircularProgress } from '@material-ui/core'

import { MyTable } from './MyTable'

import { useLogic, useStore } from '../hooks/storeHook'
import { useBoolState, useLazyEffect } from '../hooks/commonHooks'

import { OzonAccountIdProps } from '../types/accountProps'
import { ozonFilesColumns } from '../types/ozonFilesColumns'
import { useSettingsPageStyles } from '../hooks/settingsPageStylesHook'

export const OzonFilesTab =
  observer(
    ({ozonAccountId}: OzonAccountIdProps) => {
      const {labelStore: {ozonFiles}} = useStore()
      const {loadOzonFiles} = useLogic()
      const classes = useSettingsPageStyles()

      const [isLoading, , setLoaded] = useBoolState(true)

      useLazyEffect(() => loadOzonFiles(ozonAccountId).finally(setLoaded))

      return (
        <Box className={classes.fullWidth}>
          {
            isLoading
            ? <CircularProgress size={30}/>
            :
            <MyTable
              table={ozonFiles}
              columns={ozonFilesColumns}
              labelNoRows={'Файлов нет'}
              labelNotFound={'Файлы не найдены'}
              labelRowsPerPage={'Файлов на странице:'}
            />
          }

          {
            ozonFiles.totalRowCount >= 500 &&
            <small>
              Показаны последние 500 файлов.
            </small>
          }
        </Box>
      )
    })