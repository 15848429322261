import React from 'react'
import { observer } from 'mobx-react-lite'

import { createStyles, makeStyles } from '@material-ui/core/styles'
import {
  Box, TablePagination as PaperTablePagination,
  TablePaginationProps as PaperTablePaginationProps
} from '@material-ui/core'
import { LabelDisplayedRowsArgs } from '@material-ui/core/TablePagination/TablePagination'

import { TablePaginationHelpers, tableRowsPerPageOptions } from '../hooks/tableHooks'

import { fixMsAppScrollProps } from '../common/msAppUtils'

import { ITableData } from '../types/tableTypes'
import { KeyboardArrowDown, KeyboardArrowLeft, KeyboardArrowRight } from '@material-ui/icons'
import { useDefaultMenuItemStyles } from '../hooks/defaultSelectStylesHook'
import { DefaultSelect } from './DefaultSelect'

export type TablePaginationProps =
// NOTE: Omit breaks these types
  Pick<PaperTablePaginationProps, 'count' | 'labelRowsPerPage' | 'labelDisplayedRows'>
  & {
  pagination: TablePaginationHelpers
}

export const TablePagination =
  ({pagination, ...passProps}: TablePaginationProps) => {
    const classes = useStyles()

    return (
      <PaperTablePagination
        component="div"
        page={pagination.page}
        rowsPerPage={pagination.rowsPerPage}
        rowsPerPageOptions={tableRowsPerPageOptions}
        onPageChange={pagination.handleChangePage}
        onRowsPerPageChange={pagination.handleChangeRowsPerPage}
        classes={{spacer: classes.paginationSpacer}}
        SelectProps={fixMsAppScrollSelectProps}
        {...passProps}
      />
    )
  }

export type TablePagination2Props<TRow extends object, TColumnId> =
// NOTE: Omit breaks these types
  Pick<PaperTablePaginationProps, 'labelRowsPerPage' | 'classes' | 'className' | 'style'>
  & {
  table: ITableData<TRow, TColumnId>
}

export const TablePagination2 =
  observer(
    <TRow extends object, TColumnId>
    ({
       table: {filteredRowCount, pageIndex, pageSize, setPageIndex, setPageSize},
       ...passProps
     }: TablePagination2Props<TRow, TColumnId>) => {
      const classes = useStyles()
      const menuClasses = useDefaultMenuItemStyles()

      return <CustomPagination
        page={pageIndex}
        count={filteredRowCount}
        rowsPerPage={pageSize}
        rowsPerPageOptions={tableRowsPerPageOptions}
        labelDisplayedRows={labelDisplayedRows}
        onPageChange={(_, page) => setPageIndex(page)}
        onRowsPerPageChange={e => setPageSize(e)}
        classes={{toolbar: classes.paginationToolbar, menuItem: menuClasses.menuItem}}
        {...passProps}
      />
    }
  )

export type CustomPaginationProps = {
  page: number;
  count: number;
  rowsPerPage: number;
  rowsPerPageOptions: Array<number>;
  labelDisplayedRows?: (paginationInfo: LabelDisplayedRowsArgs) => string;
  onPageChange: (event: React.MouseEvent<HTMLButtonElement> | null, page: number) => void;
  onRowsPerPageChange: (rowsPerPage: number) => void;
}

const useStyles1 = makeStyles(() =>
  createStyles({
    paginationContainer: {
      color: '#888888',
      display: 'flex',
      alignItems: 'end',
      padding: '10px 0',
      width: '100%',
      maxWidth: '400px',
      margin: '0 auto',
      fontSize: '12px',
      flexDirection: 'column'
    },
    selectContainer: {
      display: 'flex',
      alignItems: 'center',
      position: 'relative'
    },
    label: {
      fontSize: '12px'
    },
    button: {
      minWidth: '40px',
      fontSize: '14px',
      padding: 0,
      '&:focus': {
        outline: 'none'
      }
    },
    pageCounter: {
      display: 'flex',
      gap: '4px',
      alignItems: 'center'
    },
    arrowIcon: {
      position: 'absolute',
      right: '0px',
      top: '50%',
      transform: 'translateY(-50%)',
      pointerEvents: 'none'
    },
    arrowIconLeftRight: {
      cursor: 'pointer',
      width: 20,
      '&.disabled': {
        color: '#ccc',
        pointerEvents: 'none'
      }
    },
    customInput: {
      marginRight: 2,
      '& .MuiInputBase-input': {
        padding: '0 12px',
        border: 'none',
        width: '30px',
        cursor: 'pointer',
        '&:focus': {
          outline: 'none'
        }
      }
    }
  })
)

export const CustomPagination: React.FC<CustomPaginationProps> = ({
                                                                    page,
                                                                    count,
                                                                    rowsPerPage,
                                                                    rowsPerPageOptions,
                                                                    labelDisplayedRows,
                                                                    onPageChange,
                                                                    onRowsPerPageChange
                                                                  }) => {
  const classes = useStyles1()

  const handleRowsPerPageChange = async (value: number | undefined) => {
    if (value !== undefined) {
      onRowsPerPageChange(value)
    }
  }

  const handlePreviousPage = () => {
    if (page > 0) {
      onPageChange(null, page - 1)
    }
  }

  const handleNextPage = () => {
    const totalPages = Math.ceil(count / rowsPerPage)
    if (page < totalPages - 1) {
      onPageChange(null, page + 1)
    }
  }

  const from = count === 0 ? 0 : page * rowsPerPage + 1
  const to = Math.min(count, (page + 1) * rowsPerPage)

  return (
    <Box className={classes.paginationContainer}>
      <Box className={classes.selectContainer}>
        <span className={classes.label}>Товаров на странице:</span>
        <Box position="relative">
          <DefaultSelect
            value={rowsPerPage}
            onChange={handleRowsPerPageChange}
            options={rowsPerPageOptions.map((option) => ({
              value: option,
              label: option.toString()
            }))}
            placeholder="Rows per page"
            inputProps={{className: classes.customInput}}
          />
          <KeyboardArrowDown className={classes.arrowIcon}/>
        </Box>
      </Box>

      <Box className={classes.pageCounter}>
        <span>
          {labelDisplayedRows
           ? labelDisplayedRows({from, to, count} as LabelDisplayedRowsArgs)
           : `${from}-${to} из ${count}`}
        </span>

        <KeyboardArrowLeft
          onClick={handlePreviousPage}
          className={page === 0 ? `${classes.arrowIconLeftRight} disabled` : classes.arrowIconLeftRight}
        />
        <KeyboardArrowRight
          onClick={handleNextPage}
          className={page >= Math.ceil(count / rowsPerPage) - 1
                     ? `${classes.arrowIconLeftRight} disabled`
                     : classes.arrowIconLeftRight}
        />
      </Box>
    </Box>
  )
}
const labelDisplayedRows =
  ({from, to, count}: LabelDisplayedRowsArgs) =>
    `${from}-${to} из ${count}`

const fixMsAppScrollSelectProps = {MenuProps: fixMsAppScrollProps, IconComponent: KeyboardArrowDown}

const useStyles =
  makeStyles(() =>
    createStyles({
      paginationSpacer: {
        display: 'none'
      },
      paginationToolbar: {
        padding: 0
      }
    }))