import React from 'react'
import { observer } from 'mobx-react-lite'

import { TableCell } from '@material-ui/core'

import { ProductOzonSyncSwitch } from './ProductOzonSyncSwitch'

import { MyRowProps } from '../../types/tableTypes'
import { MyProductModel } from '../../server/mpsklad_core/Models/MyProductModel'
import clsx from 'clsx'

export const MyProductOzonSyncCell =
  observer(
    ({row, isSelected}: MyRowProps<MyProductModel>) =>
      <TableCell className={clsx('table-border', isSelected && 'selected_row')} align="center" style={{padding: '8px'}}>
        {
          row.ozonProducts.map(ozonProduct =>
            <div key={ozonProduct.id}>
              <ProductOzonSyncSwitch msProductId={row.id} ozonProduct={ozonProduct}/>
            </div>
          )
        }
      </TableCell>
  )