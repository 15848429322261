import React, { FunctionComponent, ReactNode } from 'react'

import clsx from 'clsx'
import { AppBar, Tab, Tabs } from '@material-ui/core'
import { AppBarProps } from '@material-ui/core/AppBar/AppBar'

import { useStyles } from './AutoTabs'
import { tabA11yProps, TabPanel } from './TabPanel'
import { makeStyles } from '@material-ui/core/styles'

export type ReactiveTabsProps = {
  /**
   * If specified, this component will be controlled. Use `onTabChange` to update the `tab`.
   */
  tab: number

  titles: ReactNode[]

  secondary?: boolean

  appBarProps?: Omit<AppBarProps, 'position' | 'className'>

  onTabChange: (newTabIndex: number) => void

  children: ReactNode

  AfterTabsComponent?: FunctionComponent
}

/**
 * Renders the AppBar like AutoTabs, but has a single child in the TabPanel.
 * Must be controlled via `tab` prop.
 */
export const ReactiveTabs =
  ({tab, titles, secondary, appBarProps, onTabChange, children, AfterTabsComponent}: ReactiveTabsProps) => {
    const classes = useStyles()
    const tabClasses = useReactiveTabsStyles()

    const onTabsChange =
      (event: unknown, newTabIndex: number) =>
        onTabChange(newTabIndex)

    // TODO: Altrenative indicator for active tab to fix multi-row bugs or is Tabs[scrollable] good enough?
    return <>
      <AppBar
        position="relative"
        className={clsx(classes.appBar, classes.appBarPrimary, secondary && classes.appBarSecondary, 'reactive_tabs')}
        {...appBarProps}
        style={{paddingTop: '20px'}}
      >
        <Tabs
          variant="scrollable"
          value={tab}
          className={tabClasses.tabs}
          onChange={onTabsChange}
          TabIndicatorProps={{hidden: true}}
        >
          {
            titles.map(
              (title, index) =>
                <Tab
                  key={index} disableRipple label={title} {...tabA11yProps(index)}/>)
          }
        </Tabs>

        {
          AfterTabsComponent !== undefined &&
          <AfterTabsComponent/>
        }
      </AppBar>

      {
        <TabPanel value={0} index={0}>
          {children}
        </TabPanel>
      }
    </>
  }

const useReactiveTabsStyles = makeStyles(
  (theme) => ({
    tabs: {
      maxWidth: '100%',
      minWidth: 0,
      '& button': {},
      '& p': {
        fontSize: 12,
        fontWeight: 400,
        color: theme.palette.mpSkladPrimary.gray
      },
      '& .Mui-selected .MuiTab-wrapper span:first-child': {
        color: theme.palette.mpSkladPrimary.blue,
        fontSize: 12,
        fontWeight: 600,
        borderBottom: '2px solid',
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        textAlign: 'left',
        width: 'fit-content'
      },
      '& .MuiTab-wrapper span:first-child': {
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        textAlign: 'left',
        width: 'fit-content'
      },
      '& .MuiTab-root': {
        padding: 0,
        minWidth: 'fit-content',
        marginRight: 40,
        overflow: 'unset'
      },
      '& .MuiTab-wrapper': {
        display: 'block'
      }
    }
  })
)