import React from 'react'
import { observer } from 'mobx-react-lite'
import clsx from 'clsx'

import { FormHelperText, FormLabel, Grid, IconButton } from '@material-ui/core'

import addIcon from '../assets/addIcon.svg'
import arrowRight from '../assets/arrowRight.svg'
import deleteIcon from '../assets/deleteIcon.svg'

import { BootstrapInput } from './BootstrapInput'
import { WarehousePicker } from './WarehousePicker'
import { MoySkladStorePicker } from './MoySkladStorePicker'
import { StoresFormBaseProps, useStoresFormStyles } from './StoresFormDirect'

import { useLogic } from '../hooks/storeHook'
import { useMessages } from '../hooks/snackbarHooks'
import { useDefaultMenuItemStyles } from '../hooks/defaultSelectStylesHook'

import { getIntegrationTypeName } from '../types/navStore'

import { EditStoreModel } from '../server/mpsklad_core/Models/EditStoreModel'
import { WarehouseModel } from '../server/mpsklad_core/Models/WarehouseModel'
import { EditStoresModel } from '../server/mpsklad_core/Models/EditStoresModel'
import { MoySkladStoreModel } from '../server/mpsklad_core/Models/MoySkladStoreModel'
import { StoreRelationType } from '../server/mpsklad_core/Entity/Base/StoreRelationType'

/**
 * See {@link StoreRelationType.SumFromMoySklad}.
 */
export const StoresFormSumFromMoySklad =
  observer(
    <TWarehouse extends WarehouseModel>
    ({
       disabled,
       storeType,
       serverData, formData,
       whKeySelector, storeWhKeySelector,
       formatWarehouseDisplayName
     }: StoresFormBaseProps<TWarehouse>) => {
      const classes = useStoresFormStyles()
      const menuClasses = useDefaultMenuItemStyles()

      const {showWarning} = useMessages()

      const {showDialog} = useLogic()

      const storeTypeName = getIntegrationTypeName(storeType)

      if (formData.relationType !== StoreRelationType.SumFromMoySklad) {
        throw new Error(`Bad relation type: ${formData.relationType}!`)
      }

      if (serverData.warehouses.length === 0) {
        return <FormHelperText error>Нет складов {storeTypeName}.</FormHelperText>
      }

      if (serverData.msStores.length === 0) {
        return <FormHelperText error>Нет складов МоегоСклада.</FormHelperText>
      }

      const formStore = tryGetFormStore(formData)

      const onAddStore =
        () => {
          if (formStore) {
            throw new Error(`formStore already exists when adding a new one, total: ${formData.stores.length}!`)
          }

          if (serverData.warehouses.length === 0) {
            showWarning(`Нет складов ${storeTypeName}!`)
            return
          }

          if (serverData.msStores.length === 0) {
            showWarning('Нет складов МоегоСклада!')
            return
          }

          formData.stores = [{
            storeId: undefined,
            warehouseId: serverData.warehouses[0].id,
            warehouseName: serverData.warehouses[0].name,
            msStoreIds: [serverData.msStores[0].id],
            stocksSplitPercentage: undefined
          }]
        }

      if (!formStore) {
        return (
          <Grid container direction="column" spacing={0}>
            <Grid item xs={12}>
              <Grid container spacing={1}>
                <Grid item xs={6}>
                  <FormLabel component="legend" className={classes.label}>
                    <span className={classes.dash}>&mdash;</span>
                    Склад {storeTypeName}
                  </FormLabel>
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <Grid container spacing={1}>
                <Grid item xs={5}>
                  <BootstrapInput readOnly className={menuClasses.input} onClick={onAddStore}/>
                </Grid>

                <Grid item xs={1}>
                  <IconButton
                    title="Добавить"
                    disabled={disabled}
                    className={clsx(menuClasses.iconButtonOutlined)}
                    onClick={onAddStore}
                  >
                    <img src={addIcon} alt=""/>
                  </IconButton>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        )
      }

      const onChangeWarehouse =
        (warehouse: TWarehouse) => {
          if (formStore == null) {
            throw new Error('No formStore to change warehouse in!')
          }

          formStore.warehouseId = warehouse.id
          formStore.warehouseName = warehouse.name
        }

      const onDeleteStore =
        async () => {
          if (formStore.storeId != null && !await showDialog(
            `Заказы для склада ${storeTypeName} будут удалены из MPsklad после сохранения!`, {
              title: 'Удалить сопоставление складов?',
              acceptButton: 'Удалить'
            })) {
            return
          }

          formData.stores = []
        }

      const onAddMsStoreId =
        () => {
          if (formStore == null) {
            throw new Error('No formStore to add MS store to!')
          }

          const unmatchedMsStore = serverData.msStores.find(msStore => !formStore.msStoreIds.includes(msStore.id))

          if (!unmatchedMsStore) {
            showWarning('Все склады МоегоСклада сопоставлены!')
            return
          }

          formStore.msStoreIds.push(unmatchedMsStore.id)
        }

      const onChangeMsStore =
        (oldMsStoreId: string, newMsStore: MoySkladStoreModel | null) => {
          if (formStore == null) {
            throw new Error('No formStore to change MS store in!')
          }

          if (newMsStore == null) {
            throw new Error('Empty new MS store!')
          }

          const changedIndex = formStore.msStoreIds.findIndex(_ => _ === oldMsStoreId)

          if (changedIndex < 0) {
            throw new Error(`Failed to find old MS store #${oldMsStoreId}`)
          }

          formStore.msStoreIds.splice(changedIndex, 1, newMsStore.id)
        }

      const onDeleteMsStoreId =
        (msStoreId: string) => {
          if (formStore == null) {
            throw new Error('No formStore to delete MS store from!')
          }

          formStore.msStoreIds = formStore.msStoreIds.filter(_ => _ !== msStoreId)
        }

      return (
        <Grid container direction="column">
          <Grid item xs={12}>
            <Grid container spacing={1}>
              <Grid item xs={6}>
                <FormLabel component="legend" className={classes.label}>
                  <span className={classes.dash}>—</span>
                  Склад МоегоСклада, на который будут поступать заказы покупателя
                </FormLabel>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Grid container>
              <Grid item xs={5}>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <Grid container spacing={1}>
                      <Grid item xs={10}>
                        <MoySkladStorePicker
                          allowEmpty
                          msStoreId={formData.ordersMsStoreId}
                          msStores={serverData.msStores}
                          formData={formData}
                          onChange={msStore => formData.ordersMsStoreId = msStore?.id}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Grid container spacing={1}>
              <Grid item xs={6}>
                <FormLabel component="legend" className={classes.label}>
                  <span className={classes.dash}>—</span>
                  Склад МоегоСклада
                </FormLabel>
              </Grid>

              <Grid item xs={6}>
                <FormLabel component="legend" className={classes.label}>
                  <span className={classes.dash}>&mdash;</span>
                  Склад {storeTypeName}
                </FormLabel>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Grid container>
              <Grid item xs={5}>
                <Grid container spacing={3}>
                  {
                    formStore.msStoreIds.map(
                      msStoreId =>
                        <Grid item xs={12} key={msStoreId}>
                          <Grid container spacing={1}>
                            <Grid item xs={10}>
                              <MoySkladStorePicker
                                filterMatchedStores
                                msStoreId={msStoreId}
                                msStores={serverData.msStores}
                                formData={formData}
                                onChange={newMsStore => onChangeMsStore(msStoreId, newMsStore)}
                              />
                            </Grid>

                            <Grid item xs={2}>
                              <IconButton
                                title="Удалить"
                                disabled={disabled}
                                className={clsx(menuClasses.iconButtonOutlined)}
                                onClick={() => onDeleteMsStoreId(msStoreId)}
                              >
                                <img src={deleteIcon} alt=""/>
                              </IconButton>
                            </Grid>
                          </Grid>
                        </Grid>
                    )
                  }

                  <Grid item xs={12}>
                    <Grid container spacing={1}>
                      <Grid item xs={10}>
                        <BootstrapInput readOnly className={menuClasses.input} onClick={onAddMsStoreId}/>
                      </Grid>

                      <Grid item xs={2}>
                        <IconButton
                          title="Добавить"
                          disabled={disabled}
                          className={clsx(menuClasses.iconButtonOutlined)}
                          onClick={onAddMsStoreId}
                        >
                          <img src={addIcon} alt=""/>
                        </IconButton>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={1} alignContent="center">
                <img src={arrowRight} alt="" style={{width: 20, height: 20}}/>
              </Grid>

              <Grid item xs={5}>
                <Grid container spacing={1}>
                  <Grid item xs={10}>
                    <WarehousePicker
                      formStore={formStore}
                      formData={formData}
                      warehouses={serverData.warehouses}
                      whKeySelector={whKeySelector}
                      storeWhKeySelector={storeWhKeySelector}
                      formatWarehouseDisplayName={formatWarehouseDisplayName}
                      onChange={onChangeWarehouse}
                    />
                  </Grid>

                  <Grid item xs={2}>
                    <IconButton
                      title="Удалить"
                      disabled={disabled}
                      className={clsx(menuClasses.iconButtonOutlined)}
                      onClick={onDeleteStore}
                    >
                      <img src={deleteIcon} alt=""/>
                    </IconButton>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      )
    })

const tryGetFormStore =
  (formData: EditStoresModel): EditStoreModel | null => {
    if (formData.stores.length === 0) {
      return null
    }

    if (formData.stores.length === 1) {
      return formData.stores[0]
    }

    throw new Error(`Bad form store count for SumFromMoySklad: ${formData.stores.length}.`)
  }