import React, { useCallback, useMemo } from 'react'
import { PropFilterItemBase } from './StringPropFilter'
import { DefaultSelect } from './DefaultSelect'

export type BooleanPropFilterItem = PropFilterItemBase & {
  isBoolean: true

  onText?: string

  offText?: string

  allowMixed?: boolean

  mixedText?: string
}

export const BooleanPropFilter = ({
                                    placeholder, value, onText, offText, allowMixed, mixedText, setValue
                                  }: BooleanPropFilterItem) => {

  const handleSelectChange = useCallback((selectedValue: string | undefined) => {
    setValue(selectedValue === resetBooleanValue ? undefined : selectedValue)
  }, [setValue])

  const options = useMemo(() => {
    const baseOptions = [
      {value: resetBooleanValue, label: placeholder || 'Все'},
      {value: JSON.stringify(true), label: onText || 'Включена'},
      {value: JSON.stringify(false), label: offText || 'Отключена'}
    ]

    if (allowMixed) {
      baseOptions.push({value: JSON.stringify(null), label: mixedText || 'Вкл + Откл'})
    }

    return baseOptions
  }, [placeholder, onText, offText, allowMixed, mixedText])

  return (
    <DefaultSelect
      value={value ?? resetBooleanValue}
      onChange={handleSelectChange}
      options={options}
      fullWidth
      placeholder={placeholder}
    />
  )
}

/**
 * NOTE: This exists because undefined doesn't work (obviously)
 */
const resetBooleanValue = 'none'