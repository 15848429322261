import React from 'react'
import { observer } from 'mobx-react-lite'

import { Checkbox, TableCell } from '@material-ui/core'

import { ITableData } from '../../types/tableTypes'
import CircleUnchecked from '@material-ui/icons/RadioButtonUnchecked'
import CircleCheckedFilled from '@material-ui/icons/CheckCircle'

interface ISelectCellProps<TRow extends object, TColumnId> {
  row: TRow,

  table: ITableData<TRow, TColumnId>
}

export const TableSelectCell =
  observer(
    <TRow extends object, TColumnId>
    ({row, table}: ISelectCellProps<TRow, TColumnId>) => {
      const {isRowSelected, toggleRowSelection} = table

      return (
        <TableCell
          className="table-border"
          padding="checkbox"
          onClick={() => toggleRowSelection(row)}
          style={{cursor: 'pointer'}}
        >
          <Checkbox
            checked={isRowSelected(row)}
            style={{color: '#3987cf'}}
            icon={<CircleUnchecked/>}
            checkedIcon={<CircleCheckedFilled style={{color: '#3987cf'}}/>}/>
        </TableCell>
      )
    })