import React from 'react'
import { observer } from 'mobx-react-lite'
import { Route, Switch } from 'react-router-dom'

import { Box } from '@material-ui/core'

import { WbOrdersTabsScreen } from '../WbOrdersScreen'
import { OzonOrdersTabsScreen } from '../OzonOrdersScreen'
import { YandexMarketOrdersTabsScreen } from '../YandexMarketOrdersTabsScreen'

import { useStore } from '../../hooks/storeHook'

import { appRoutes } from '../../common/appRoutes'

import { IntegrationType } from '../../server/mpsklad_core/Entity/IntegrationType'
import { useValidateStoreAccountRoute } from '../../hooks/validateStoreAccountRoute'

export const OrdersRoute =
  observer(() => {
    const {integration} = useValidateStoreAccountRoute(appRoutes.CommonStore.orders)

    const {allowedIntegrations} = useStore()

    return (
      <div className="body_wrapper_zero body_wrapper__home border_left" style={{minWidth: 'calc(100vw - 300px)'}}>
        <Box flex={1}>
          <Switch>
            {
              allowedIntegrations[IntegrationType.Ozon] &&
              integration === 'Ozon' &&
              <Route path={appRoutes.CommonStore.orders.template} component={OzonOrdersTabsScreen}/>
            }

            {
              allowedIntegrations[IntegrationType.Wildberries] &&
              integration === 'Wildberries' &&
              <Route path={appRoutes.CommonStore.orders.template} component={WbOrdersTabsScreen}/>
            }

            {
              allowedIntegrations[IntegrationType.YandexMarket] &&
              integration === 'YandexMarket' &&
              <Route path={appRoutes.CommonStore.orders.template} component={YandexMarketOrdersTabsScreen}/>
            }
          </Switch>
        </Box>
      </div>
    )
  })