import React, { useCallback } from 'react'
import { observer } from 'mobx-react-lite'

import { PricesSettings } from '../PricesSettings'
import { CompanySettings } from '../CompanySettings'
import { StoreSettingsPage } from '../StoreSettingsPage'
import { YandexMarketApiSettings } from './YandexMarketApiSettings'
import { YandexMarketStoresForm } from '../../YandexMarketStoresForm'
import { YandexMarketOrdersSettings } from './YandexMarketOrdersSettings'
import { YandexMarketReturnsSettings } from './YandexMarketReturnsSettings'

import { useMessages } from '../../../hooks/snackbarHooks'
import { useLogic, useStore } from '../../../hooks/storeHook'

import { CommissionsRecordingType } from '../../../server/mpsklad_core/Entity/Base/CommissionsRecordingType'
import { EditStoreAccountMsDataModel } from '../../../server/mpsklad_core/Models/EditStoreAccountMsDataModel'
import { EditYandexMarketAccountModel } from '../../../server/mpsklad_core/Models/EditYandexMarketAccountModel'
import { YandexMarketDeliveryType } from '../../../server/mpsklad_core/Entity/YandexMarket/Enums/YandexMarketDeliveryType'
import { CommissionsSettings } from '../CommissionsSettings'

export const YandexMarketSettingsPage =
  observer(() => {
    const logic = useLogic()
    const store = useStore()
    const {showSuccess} = useMessages()

    const accountId = store.getHomeNav()?.accountId
    const account = logic.tryGetYandexMarketAccount(accountId)

    const onSubmit = useCallback(
      async (data: EditStoreAccountMsDataModel) => {
        try {
          const formData: Omit<EditYandexMarketAccountModel, 'id'> = {
            campaignId: account?.campaignId ?? 0,
            userCode: undefined,
            usingUnifiedPrices: account?.usingUnifiedPrices ?? false,
            deliveryType: account?.deliveryType ?? YandexMarketDeliveryType.FBS,
            isUpdatePrices: account?.isUpdatePrices ?? false,
            isGeneratingPayments: account?.isGeneratingPayments ?? false,
            isUsingCommissions: account?.isUsingCommissions ?? false,
            ordersCommissionsRecording:
              account?.ordersCommissionsRecording ?? CommissionsRecordingType.Nothing,
            documentsCommissionsRecording:
              account?.documentsCommissionsRecording ?? CommissionsRecordingType.Nothing,
            name: account?.name || undefined,
            msSalesReturnStoreId: account?.msSalesReturnStoreId,
            msData: data
          }

          if (account) {
            await logic.editYandexMarketAccount({id: account.id, ...formData})
          } else {
            await logic.createYandexMarketAccount({id: 0, ...formData})
          }

          showSuccess('Сохранено!')
        } catch (e) {
          console.error('Failed to save YandexMarket account', e)
        }
      },
      [account, logic, showSuccess])

    return (
      <StoreSettingsPage
        integrationTypeKey={'YandexMarket'}
        account={account}
        apiSettingsComponent={YandexMarketApiSettings}
        companySettingsComponent={CompanySettings}
        ordersSettingsComponent={YandexMarketOrdersSettings}
        warehousesSettingsComponent={YandexMarketStoresForm}
        returnsSettingsComponent={YandexMarketReturnsSettings}
        commissionsSettingsComponent={CommissionsSettings}
        pricesSettingsComponent={(props) => (
          <PricesSettings
            {...props}
            onCheckPrices={logic.api.prices.checkPriceActivationYandexMarket}
            onEnablePriceSync={logic.api.prices.enablePriceSyncYandexMarket}
            onForceUpdatePrices={() => logic.api.userSync.updatePricesYandexMarket(props.account.id)}
          />
        )}
        onSubmit={onSubmit}
      />
    )
  })