import React from 'react'
import { observer } from 'mobx-react-lite'

import { AccountStoresForm } from './AccountStoresForm'

import { useApi } from '../hooks/storeHook'

import { required } from '../common/objectUtils'

import { WbAccountRequiredProps } from '../types/accountProps'

import { EditStoreModel } from '../server/mpsklad_core/Models/EditStoreModel'
import { WbWarehouseModel } from '../server/mpsklad_core/Models/WbWarehouseModel'
import { WbDeliveryType } from '../server/mpsklad_core/Entity/Wildberries/WbDeliveryType'

export const WbStoresForm =
  observer(
    ({account}: WbAccountRequiredProps) => {
      const api = useApi()

      let whKeySelector: (warehouse: WbWarehouseModel) => number | string
      let storeWhKeySelector: (store: EditStoreModel) => number | string
      let hint: string | undefined = undefined
      let loadingHelpText: string | undefined = undefined

      switch (account.deliveryType) {
        case WbDeliveryType.FBS:
        case WbDeliveryType.DBS: {
          whKeySelector = warehouse => required(warehouse.id)
          storeWhKeySelector = store => required(store.warehouseId)
          break
        }

        case WbDeliveryType.FBW: {
          whKeySelector = warehouse => required(warehouse.name)
          storeWhKeySelector = store => required(store.warehouseName)
          hint = 'На складах со звёздочкой * есть остатки'
          loadingHelpText = 'Загрузка складов FBW может занять пару минут (ограничение API WB).'
          break
        }

        default:
          throw new Error(`Unprocessed enum value for WbDeliveryType: ${account.deliveryType}`)
      }

      return <AccountStoresForm
        account={account}
        loadStores={api.userSync.getWbStores}
        loadWarehouses={api.userSync.getWbWarehouses}
        editStores={api.userSync.editWbStores}
        whKeySelector={whKeySelector}
        storeWhKeySelector={storeWhKeySelector}
        hint={hint}
        formatWarehouseNameHint={warehouse => warehouse.hasStocks ? ' *' : ''}
        loadingHelpText={loadingHelpText}
      />
    })