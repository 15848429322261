import React from 'react'
import { observer } from 'mobx-react-lite'

import { TableCell } from '@material-ui/core'
import { TableCellProps } from '@material-ui/core/TableCell/TableCell'

import { MyRowProps } from '../types/tableTypes'
import { ExternalProductModel } from '../server/mpsklad_core/Models/ExternalProductModel'

export type ProductImageCellProps =
  TableCellProps & {
  product: Pick<ExternalProductModel, 'imageUrl'>
}

export const ProductImageCell2 =
  observer(
    ({row}: MyRowProps<ExternalProductModel>) =>
      <ProductImageCell product={row}/>
  )

// TODO: Remove after server side tables
export const ProductImageCell =
  observer(
    ({product, ...passProps}: ProductImageCellProps) =>
      <UrlImageCell imageUrl={product.imageUrl} {...passProps}/>
  )

export type UrlImageCellProps =
  TableCellProps & {
  imageUrl?: string
}

export const UrlImageCell =
  observer(
    ({imageUrl, ...passProps}: UrlImageCellProps) =>
      <TableCell {...passProps} style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
        {
          imageUrl &&
          <img src={imageUrl} alt="" style={{width: 'auto', height: 100}}/>
        }
      </TableCell>
  )