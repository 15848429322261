import { WbAccountModel } from '../server/mpsklad_core/Models/WbAccountModel'
import { OzonAccountModel } from '../server/mpsklad_core/Models/OzonAccountModel'
import { YandexMarketAccountModel } from '../server/mpsklad_core/Models/YandexMarketAccountModel'

export const makeOzonAccountName =
  (account: OzonAccountModel): string =>
    account.name || `Ozon ${account.clientId}`

export const makeWbAccountName =
  (account: WbAccountModel): string =>
    account.name || `Wildberries ${account.id}`

export const makeYandexMarketAccountName =
  (account: YandexMarketAccountModel): string =>
    account.name || `Yandex.Market ${account.id}`