import React, { FormEvent, useState } from 'react'
import { observer, useLocalObservable } from 'mobx-react-lite'
import { autorun, comparer, reaction } from 'mobx'

import { useApi } from '../../../hooks/storeHook'
import { useMessages } from '../../../hooks/snackbarHooks'
import { useBoolState, useLazyEffect } from '../../../hooks/commonHooks'
import { useCheckboxStyles } from '../../../hooks/checkboxStylesHook'

import { exact } from '../../../common/tsUtils'
import { ResponseCancelledError } from '../../../types/responseCancelledError'
import { wait } from '../../../common/commonUtils'
import { dateUtils } from '../../../common/dateTimeUtility'

import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  FormLabel,
  Grid,
  Radio,
  RadioGroup
} from '@material-ui/core'
import clsx from 'clsx'
import CircleUnchecked from '@material-ui/icons/RadioButtonUnchecked'
import { CheckCircle, CheckCircleOutline } from '@material-ui/icons'
import { PrintMsBarcodesCheckbox } from '../../PrintMsBarcodesCheckbox'
import { LabelsPostDateFilters, maxShipmentDate, minShipmentDate } from '../../LabelsPostDateFilters'
import { DateTimeRangePicker } from '../../DateTimeRangePicker'
import { OzonPreviewPrintTable } from '../../OzonPreviewPrintTable'

import { OzonAccountIdProps } from '../../../types/accountProps'
import { OrdersAtDateCount } from '../../../types/ordersAtDateCount'
import { OzonPrintOrderColumnId } from '../../../types/ozonColumns'

import { OzonLabelFormat } from '../../../server/mpsklad_common/Models/OzonLabelFormat'
import { OzonPrintOptions } from '../../../server/mpsklad_core/Models/OzonPrintOptions'
import { OzonPrintOrderModel } from '../../../server/mpsklad_core/Models/OzonPrintOrderModel'
import { MemoryTableData } from '../../../store/memoryTableData'

import { getUnixTime } from 'date-fns'
import { filterPreviewByShipmentDate, getOrderShipmentDateCounts } from '../../../common/labelsUtil'
import { useSettingsPageStyles } from '../../../hooks/settingsPageStylesHook'

export type LabelsTabStateBase = {
  startDate: Date
  endDate: Date
  maxDate: Date

  pickedShipmentDate: Date | null
  shipmentDateMin: Date
  shipmentDateMax: Date

  useAutoReset: boolean
  hasPurchaseList: boolean
  hasMsBarcodes: boolean
}

export type OzonLabelsTabOptions =
  LabelsTabStateBase & {
  hasOrderNumbers: boolean
  format: OzonLabelFormat

  readonly printRequestData: Readonly<OzonPrintOptions>
  readonly previewRequestData: Readonly<OzonPrintOptions>
}

export const OzonLabelsPrintForm =
  observer(
    ({ozonAccountId}: OzonAccountIdProps) => {
      const api = useApi()

      const {showSuccess} = useMessages()

      const [isSubmitting, setSubmitting, setSubmitted] = useBoolState()
      const [submitError] = useState(null as string | null)

      const classes = useSettingsPageStyles()
      const checkboxClasses = useCheckboxStyles()

      const options =
        useLocalObservable(() =>
          exact<OzonLabelsTabOptions>({
            // NOTE: Effects are required for initial values that depend on props or state
            startDate: dateUtils.startOfYesterday,
            endDate: dateUtils.endOfToday,
            maxDate: dateUtils.endOfToday,
            // TODO: Not only this is not controlled, its computation is non-trivial...
            pickedShipmentDate: null,
            shipmentDateMin: minShipmentDate,
            shipmentDateMax: maxShipmentDate,
            useAutoReset: false,
            hasPurchaseList: true,
            hasOrderNumbers: true,
            hasMsBarcodes: false,
            format: OzonLabelFormat.AmountNameCode,
            get printRequestData() {
              return exact<OzonPrintOptions>({
                accountId: ozonAccountId,
                startDateUnix: getUnixTime(this.startDate),
                endDateUnix: getUnixTime(this.endDate),
                shipmentDateMin: this.shipmentDateMin.toISOString(),
                shipmentDateMax: this.shipmentDateMax.toISOString(),
                useAutoReset: this.useAutoReset,
                hasPurchaseList: this.hasPurchaseList,
                hasOrderNumbers: this.hasOrderNumbers,
                hasMsBarcodes: this.hasMsBarcodes,
                format: this.format
              })
            },
            get previewRequestData(): Readonly<OzonPrintOptions> {
              return exact<OzonPrintOptions>({
                ...this.printRequestData,
                // TODO: Make these filters server-side for absolute consistency
                // TODO: Use startOfDay, endOfDay in Unix-time to preserve client-side timezone on the backend
                // These filters are applied client-side for the preview, thus we request with default values
                shipmentDateMin: minShipmentDate.toISOString(),
                shipmentDateMax: maxShipmentDate.toISOString()
              })
            }
          }))

      const localState =
        useLocalObservable(() => ({
          preview: null as OzonPrintOrderModel[] | null,
          previewWithShipmentFilters: null as OzonPrintOrderModel[] | null,
          get shipmentDateCounts(): OrdersAtDateCount[] {
            return getOrderShipmentDateCounts(this.preview)
          }
        }))

      const [ozonPrintOrders] = useState(
        () => new MemoryTableData<OzonPrintOrderModel, OzonPrintOrderColumnId>('ozonPrintOrders'))

      useLazyEffect(() =>
        autorun(() => {
          const {previewWithShipmentFilters, shipmentDateMin, shipmentDateMax} =
            filterPreviewByShipmentDate(options.pickedShipmentDate, localState.preview, localState.shipmentDateCounts)

          localState.previewWithShipmentFilters = previewWithShipmentFilters
          options.shipmentDateMin = shipmentDateMin
          options.shipmentDateMax = shipmentDateMax
        }))

      // Fetch preview orders from backend when options change
      useLazyEffect(() =>
        reaction(
          () => options.previewRequestData,
          async () => {
            ozonPrintOrders.data = []

            try {
              localState.preview = null
              localState.preview = await api.previewOzonLabelsPreemptive(options.previewRequestData)
              ozonPrintOrders.data = localState.preview

              // Reset filter by shipment date when other options change
              // TODO: This is required because LabelsPostDateFilters is not controlled, make it
              options.pickedShipmentDate = null
            } catch (e) {
              if (e instanceof ResponseCancelledError) {
                // Ignore
                return
              } else {
                throw e
              }
            }
          }, {
            fireImmediately: true,
            equals: comparer.shallow
          }))

      const onDateChange =
        (startDate: Date, endDate: Date) => {
          options.startDate = startDate
          options.endDate = endDate
        }

      const onSubmit =
        async (e: FormEvent) => {
          e.preventDefault()

          if (isSubmitting) {
            return
          }

          setSubmitting()

          try {
            const {fileUrl, errorCount} = await api.label.printOzon(options.printRequestData)

            if (errorCount === 0) {
              showSuccess('Создано!')
            } else {
              showSuccess(`Создано! Отправлений с ошибками: ${errorCount}.`)
            }

            await wait(500)
            window.location.assign(fileUrl)
          } finally {
            setSubmitted()
          }
        }

      const onPostDateChange =
        (newDate: Date | null) =>
          options.pickedShipmentDate = newDate

      return (
        <Box className={clsx(classes.pageContainer, classes.fullWidth)} paddingTop={0}>
          <FormHelperText className={classes.helperText}>
            {/* TODO: Take from backend? */}
            Печать этикеток возможна только для заказов в статусе "Ожидает отгрузки".
            Возможна печать до 300 заказов в одном файле.
          </FormHelperText>

          <form onSubmit={onSubmit}>
            <Grid
              container
              className={clsx(classes.supplySettingsContainer, 'default-border')}
              direction="column"
              spacing={3}
            >
              <Grid item container xs={12}>
                <FormControl component="fieldset" fullWidth>
                  <FormLabel component="legend" className={classes.label}>
                    Дата поступления заказа
                  </FormLabel>

                  <DateTimeRangePicker
                    containerProps={{xs: 7}}
                    startDate={options.startDate}
                    endDate={options.endDate}
                    maxDate={options.maxDate}
                    onChange={onDateChange}
                  />

                  <FormLabel component="legend" className={classes.label}>
                    Дата для поставки в Ozon
                  </FormLabel>

                  <LabelsPostDateFilters
                    overallCount={localState.preview?.length ?? 0}
                    daysCounts={localState.shipmentDateCounts}
                    onFilter={onPostDateChange}
                  />


                </FormControl>
              </Grid>

              <Grid item xs={12}>
                <Grid container>
                  <Grid item container xs={6}>
                    <FormControl component="fieldset">

                      <Box>
                        <FormLabel component="legend" className={classes.label}>
                          <span className={classes.dash}>—</span>
                          Что печатать
                        </FormLabel>
                      </Box>

                      <FormGroup>
                        <FormControlLabel
                          className={clsx(checkboxClasses.checkbox)}
                          control={
                            <Checkbox
                              color="primary"
                              name="feedCheckbox"
                              checked
                              disabled
                              icon={<CircleUnchecked/>}
                              checkedIcon={<CheckCircleOutline/>}
                            />
                          }
                          label="Лента"
                        />

                        <FormControlLabel
                          className={clsx(checkboxClasses.checkbox,
                            options.hasPurchaseList && checkboxClasses.checkedCheckbox)}
                          control={
                            <Checkbox
                              color="primary"
                              name="purchaseListCheckbox"
                              checked={options.hasPurchaseList}
                              onChange={e => options.hasPurchaseList = e.target.checked}
                              icon={<CircleUnchecked/>}
                              checkedIcon={<CheckCircle/>}
                            />
                          }
                          label="Список покупок"
                        />

                        <PrintMsBarcodesCheckbox
                          checked={options.hasMsBarcodes}
                          onChange={checked => options.hasMsBarcodes = checked}
                        />

                        <FormControlLabel
                          className={clsx(checkboxClasses.checkbox,
                            options.useAutoReset && checkboxClasses.checkedCheckbox)}
                          control={
                            <Checkbox
                              color="primary"
                              name="autoResetCheckbox"
                              checked={options.useAutoReset}
                              onChange={e => options.useAutoReset = e.target.checked}
                              icon={<CircleUnchecked/>}
                              checkedIcon={<CheckCircle/>}
                            />
                          }
                          label="Использовать автоматическое обнуление"
                        />

                        <FormHelperText>
                          Распечатанные заказы будут вычеркнуты, и в следующий раз будут взяты только новые заказы.
                        </FormHelperText>
                      </FormGroup>
                    </FormControl>
                  </Grid>

                  <Grid item container xs={6}>
                    <Grid item xs={12}>
                      <FormControl component="fieldset">

                        <Box>
                          <FormLabel component="legend" className={classes.label}>
                            <span className={classes.dash}>—</span>
                            Настройка списка покупок
                          </FormLabel>
                        </Box>


                        <FormGroup>
                          <RadioGroup
                            name="formatRadioGroup"
                            value={options.format}
                            onChange={e => options.format = parseInt(e.target.value)}
                          >
                            <FormControlLabel
                              className={clsx(checkboxClasses.checkbox,
                                options.format === OzonLabelFormat.AmountNameCode && checkboxClasses.checkedCheckbox)}
                              label="Шт. - Имя [КОД]"
                              control={<Radio color="primary"/>}
                              value={OzonLabelFormat.AmountNameCode}
                              disabled={!options.hasPurchaseList}
                            />

                            <FormControlLabel
                              className={clsx(checkboxClasses.checkbox,
                                options.format === OzonLabelFormat.CodeAmount && checkboxClasses.checkedCheckbox)}
                              label="КОД - шт."
                              control={<Radio color="primary"/>}
                              value={OzonLabelFormat.CodeAmount}
                              disabled={!options.hasPurchaseList}
                            />
                          </RadioGroup>

                          <FormControlLabel
                            className={clsx(checkboxClasses.checkbox,
                              options.hasOrderNumbers && checkboxClasses.checkedCheckbox)}
                            control={
                              <Checkbox
                                color="primary"
                                name="includeOrderNumberCheckbox"
                                checked={options.hasOrderNumbers}
                                onChange={e => options.hasOrderNumbers = e.target.checked}
                                icon={<CircleUnchecked/>}
                                checkedIcon={<CheckCircle/>}
                              />
                            }
                            label="Вставлять номера заказов"
                            disabled={!options.hasPurchaseList}
                          />
                        </FormGroup>
                      </FormControl>
                    </Grid>
                  </Grid>

                </Grid>
              </Grid>

            </Grid>

            <Box>
              <button
                className={clsx(classes.saveButton, 'default-button')}
                disabled={isSubmitting}
                onClick={onSubmit}>
                <p>{isSubmitting ? 'Создаём...' : 'Создать этикетки'}</p>
              </button>
            </Box>

            <Grid
              container
              className={clsx(classes.supplySettingsContainer, 'default-border')}
              direction="column">
              {
                !!submitError &&
                <Grid item>
                  <FormLabel error>
                    {submitError}
                  </FormLabel>
                </Grid>
              }

              <Grid item xl={12}>
                <Box>
                  <FormLabel component="legend" className={clsx(classes.label)}>
                    <span className={classes.dash}>—</span>
                    Предпросмотр
                  </FormLabel>
                </Box>

                <OzonPreviewPrintTable orders={ozonPrintOrders}/>
              </Grid>
            </Grid>
          </form>
        </Box>
      )
    })