import React, { useState } from 'react'
import { makeAutoObservable } from 'mobx'
import { observer } from 'mobx-react-lite'

import { Box, FormHelperText, FormLabel, Grid } from '@material-ui/core'

import { MoySkladStatusMapForm } from './MoySkladStatusMapForm'

import { useBoolState, useLazyEffect } from '../hooks/commonHooks'
import { useMessages } from '../hooks/snackbarHooks'
import { useApi, useStore } from '../hooks/storeHook'
import { makeStyles } from '@material-ui/core/styles'
import clsx from 'clsx'

import { MoySkladStatusMapsModel } from '../server/mpsklad_core/Models/MoySkladStatusMapsModel'

export type MoySkladStatusMapPanelProps = {
  shouldSkipMapButton?: boolean,
  shouldShowHelpHints?: boolean
}

export const MoySkladStatusMapPanel =
  observer(
    ({shouldSkipMapButton, shouldShowHelpHints}: MoySkladStatusMapPanelProps) => {
      const {showError} = useMessages()

      const {syncStore} = useStore()

      const api = useApi()
      const [isLoading, setIsLoading, setNotLoading] = useBoolState()

      const classes = useStyles()

      const [statusMaps, setStatusMaps] = useState<MoySkladStatusMapsModel | null>(null)

      const onLoad = async () => {
        setIsLoading()

        try {
          const maps = await api.userSync.getMoySkladStatusMaps()
          setStatusMaps(makeAutoObservable(maps))
        } catch (e) {
          console.error('Failed to load MoySklad status maps', e)
          showError('Не удалось загрузить')
        } finally {
          setNotLoading()
        }
      }

      useLazyEffect(() => {
        if (shouldSkipMapButton) {
          setIsLoading()
          onLoad().then(() => setNotLoading()).catch(e => {
            console.error('Failed to load MoySklad status maps', e)
            showError('Не удалось загрузить')
          })
        }
      }, [shouldSkipMapButton])

      if (!syncStore.moySkladAccount) {
        return null
      }

      return (
        <Box className={classes.pageContainer}>

          {shouldShowHelpHints &&
           <>
             <Box className={clsx(classes.helpBox, 'default-border')}>
               <FormLabel className={classes.helpBoxLabel}>
                 Зачем это нужно:
               </FormLabel>
               <br/>
               <br/>
               <FormHelperText>
                 1. Автоматического обновления статусов на всех маркетплейсах.
                 <br/>
                 2. Снижения ошибок и несоответствий при обработке заказов.
                 <br/>
                 3. Повышения эффективности работы
                 и информирования клиентов.
               </FormHelperText>

             </Box>

             <Box className={clsx(classes.helpActionsBox, 'default-border')}>
               <FormHelperText>
                 Создайте недастоющие статусы
                 в МоёмСкладе.
                 <br/>
                 <br/>
                 Откройте <a className={classes.link}
                             href="https://help.mp360.ru/%D0%BD%D0%B0%D1%81%D1%82%D1%80%D0%BE%D0%B9%D0%BA%D0%B0-%D0%BF%D1%80%D0%B8%D0%BB%D0%BE%D0%B6%D0%B5%D0%BD%D0%B8%D1%8F.html"
                             target="_blank" rel="noopener noreferrer">
                 этот раздел</a> в новом окне
                 <br/>
                 <br/>
                 Подробнее в <a className={classes.link} href="https://help.mp360.ru/" target="_blank"
                                rel="noopener noreferrer">
                 инструкции</a>
               </FormHelperText>
             </Box>
           </>}

          <Grid container className={clsx(classes.settingsTopic, 'default-border')}>
            <Grid item className={classes.root} xs={12}>

              {
                statusMaps === null
                ? isLoading
                  ? 'загружаем'
                  :
                  <Box width={'100%'} display="flex" justifyContent="space-between" alignItems="center">
                    <Box>
                      <FormLabel className={classes.label}>
                        Сопоставление статусов заказов
                      </FormLabel>

                      <FormHelperText className={classes.helperText}>
                        {syncStore.moySkladAccount.hasStatusMaps ? 'Статусы сопоставлены' : 'Статусы не сопоставлены'}
                      </FormHelperText>
                    </Box>


                    <button
                      className={clsx(classes.saveButton, 'default-button')}
                      disabled={isLoading}
                      onClick={onLoad}>
                      <p>
                        {
                          syncStore.moySkladAccount.hasStatusMaps
                          ? 'Изменить'
                          : 'Сопоставить'
                        }
                      </p>
                    </button>

                  </Box>
                :
                <>
                  <FormLabel className={classes.header}>
                    Сопоставление статусов заказов
                  </FormLabel>
                  <MoySkladStatusMapForm statusMaps={statusMaps} resetStatusMaps={() => setStatusMaps(null)}/>
                </>
              }
            </Grid>
          </Grid>

        </Box>
      )
    })

const useStyles = makeStyles(
  () => ({
    pageContainer: {
      margin: '0 40px 20px 0px',
      position: 'relative'
    },
    helpBox: {
      padding: 20,
      minWidth: 300,
      position: 'absolute',
      left: 'calc(100% + 50px)',
      backgroundColor: '#FBFBFD',
      height: 'fit-content'
    },
    helpActionsBox: {
      padding: 20,
      minWidth: 300,
      position: 'absolute',
      top: 250,
      left: 'calc(100% + 50px)',
      backgroundColor: '#FBFBFD',
      height: 'fit-content'
    },
    link: {
      color: '#3987CF'
    },
    helpBoxLabel: {
      marginBottom: 20,
      fontWeight: 'bold',
      fontSize: 14,
      color: '#F19F94'
    },
    root: {
      padding: 15
    },
    settingsTopic: {
      borderRadius: 12
    },
    helperText: {
      fontSize: 11,
      lineHeight: '12px',
      marginTop: 5
    },
    dash: {
      color: '#3987CF',
      marginRight: 3
    },
    label: {
      fontSize: 12,
      fontWeight: 600,
      color: '#1F364D'
    },
    header: {
      fontSize: 18,
      fontWeight: 600,
      color: '#000',
      padding: 5,
      lineHeight: '21.6px',
      margin: '10px 10px 30px 10px'
    },
    saveButton: {
      width: 160,
      height: 40,
      color: '#FFFFFF',
      alignItems: 'center',
      marginLeft: 10
    },
    cancelButton: {
      width: 160,
      height: 40,
      color: '#FFFFFF',
      backgroundColor: '#D2D2D2',
      alignItems: 'center'
    }
  })
)