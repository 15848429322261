import React, { FormEvent, useMemo } from 'react'
import { observer } from 'mobx-react-lite'
import { useHistory } from 'react-router-dom'
import clsx from 'clsx'

import { Box, FormHelperText, FormLabel, Grid, Link, Typography } from '@material-ui/core'
import { OpenInNewOutlined } from '@material-ui/icons'

import { LabeledInput } from '../../LabeledInput'
import { WbLoadOrdersForm } from '../../WbLoadOrdersForm'
import { DefaultRadioGroup } from '../../DefaultRadioGroup'
import { SyncStartTableRow } from '../../SyncStartTableRow'

import { useMessages } from '../../../hooks/snackbarHooks'
import { useWildberriesForm } from './hooks/useWildberriesForm'
import { useApi, useLogic, useStore } from '../../../hooks/storeHook'
import { useRadioGroupStyles } from '../../../hooks/radioGroupStylesHook'
import { useSettingsPageStyles } from '../../../hooks/settingsPageStylesHook'

import { appRoutes } from '../../../common/appRoutes'

import { EditWbAccountModel } from '../../../server/mpsklad_core/Models/EditWbAccountModel'
import { WbDeliveryType } from '../../../server/mpsklad_core/Entity/Wildberries/WbDeliveryType'
import { CommissionsRecordingType } from '../../../server/mpsklad_core/Entity/Base/CommissionsRecordingType'

export const WildberriesApiSettings =
  observer(() => {
    const {showSuccess, showError} = useMessages()
    const store = useStore()
    const logic = useLogic()
    const {
      userSync: {
        setWbSyncStartDate
      }
    } = useApi()

    const account = logic.tryGetWbAccount(store.homeNavRequired.accountId)
    const history = useHistory()

    const {
      isLoading,
      setIsLoading,
      deliveryType,
      setDeliveryType,
      newToken,
      setNewToken,
      name,
      setName
    } = useWildberriesForm(account)

    const classes = useSettingsPageStyles()
    const radioGroupClasses = useRadioGroupStyles()

    const deliveryTypeOptions =
      useMemo(() => [{
          value: WbDeliveryType.FBS,
          label: 'Продажа со своего склада (FBS)'
        }, {
          value: WbDeliveryType.FBW,
          label: 'Продажа со склада WB (FBW)'
        }, {
          value: WbDeliveryType.DBS,
          label: 'Продажа со своего склада и доставка силами продавца (DBS)'
        }],
        [])

    const getDeliveryTypeClasses =
      (checked: boolean) => clsx(radioGroupClasses.radioButton, checked && radioGroupClasses.selectedRadioButton)

    const onSubmit = async (e: FormEvent) => {
      e.preventDefault()

      if (!newToken) {
        showError('Необходимо ввести API токен.')
        return
      }

      setIsLoading(true)

      try {
        const formData = {
          deliveryType,
          isUpdatePrices: account?.isUpdatePrices ?? false,
          isGeneratingPayments: account?.isGeneratingPayments ?? false,
          isUsingCommissions: account?.isUsingCommissions ?? false,
          ordersCommissionsRecording:
            account?.ordersCommissionsRecording ?? CommissionsRecordingType.Nothing,
          documentsCommissionsRecording:
            account?.documentsCommissionsRecording ?? CommissionsRecordingType.Nothing,
          name: name || undefined,
          msSalesReturnStoreId: account?.msSalesReturnStoreId,
          tokenCombined: newToken,
          msData: {
            msOrganization: account?.msOrganization ?? '',
            msCounterparty: account?.msCounterparty ?? '',
            msContractId: account?.msContractId ?? undefined,
            msSalesChannelId: account?.msSalesChannelId ?? undefined,
            msProjectId: account?.msProjectId ?? undefined
          }
        } satisfies Omit<EditWbAccountModel, 'id'>

        if (account) {
          await logic.editWbAccount({id: account.id, ...formData})
        } else {
          const newAccount = await logic.createWbAccount({id: 0, ...formData})

          history.push(appRoutes.CommonStore.settings.api.route({accountId: newAccount.id, integration: 'Wildberries'}))
        }

        showSuccess('Сохранено!')
      } catch (e) {
        console.error('Failed to save Wildberries account', e)
        showError('Ошибка при сохранении аккаунта')
      } finally {
        setIsLoading(false)
      }
    }

    return (
      <Box className={classes.pageContainer}>
        <Typography className={classes.header}>Настройки API</Typography>

        <form onSubmit={onSubmit}>
          <Grid container spacing={3}>
            {/* Left column */}
            <Grid item xs={12} md={6} style={account ? {minWidth: '50%'} : {}}>
              <Grid container spacing={3}>
                {/* API Token */}
                <Grid item xs={12}>
                  <Grid container className={clsx(classes.settingsTopic, 'default-border')}>
                    <Grid item className={classes.root} xs={12}>
                      <LabeledInput
                        required
                        id="newToken"
                        label="API токен"
                        placeholder="eyJhbGcc..."
                        value={newToken}
                        onChange={setNewToken}
                        helperText={<>
                          {/* @formatter:off */}
                          Создать токен можно в личном кабинете Wildberries:&nbsp;
                          <Link target="_blank" href="https://seller.wildberries.ru/supplier-settings/access-to-api">
                            Профиль - Настройки - Доступ к API <OpenInNewOutlined fontSize="inherit"/>
                          </Link>.
                          <br/>
                          Необходимо выбрать категории: Контент, Маркетплейс, Статистика, Цены и скидки.
                          {/* @formatter:on */}
                        </>}
                      />
                    </Grid>
                  </Grid>
                </Grid>

                {/* Account Name */}
                <Grid item xs={12}>
                  <Grid container className={clsx(classes.settingsTopic, 'default-border')}>
                    <Grid item className={classes.root} xs={12}>
                      <LabeledInput
                        required
                        id="accountName"
                        label="Название аккаунта"
                        placeholder="Wildberries 8922"
                        value={name}
                        onChange={setName}
                      />
                    </Grid>
                  </Grid>
                </Grid>

                {/* Schema of Work */}
                <Grid item xs={12}>
                  <Grid container className={clsx(classes.settingsTopic, 'default-border')}>
                    <Grid item className={classes.root} xs={12}>
                      <DefaultRadioGroup
                        name="ModelType"
                        label="Схема работы"
                        options={deliveryTypeOptions}
                        value={deliveryType}
                        onChange={setDeliveryType}
                        getOptionLabelClassName={() => radioGroupClasses.radioLabel}
                        getOptionClassName={getDeliveryTypeClasses}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            {/* Right column */}
            {
              account &&
              <Grid item xs={12} md={6}>
                <Grid container spacing={3}>
                  {account.ordersTrackStartUnix &&
                   <Grid item xs={12}>
                     <Grid container className={clsx(classes.settingsTopic, 'default-border')}>
                       <Grid item className={classes.root} xs={12}>
                         <Box>
                           <FormLabel component="legend" className={classes.boldLabel}>
                             Дата начала синхронизации заказов
                           </FormLabel>
                         </Box>

                         <SyncStartTableRow account={account} onSubmit={setWbSyncStartDate}/>

                         <FormHelperText className={classes.helperText}>
                           Будут синхронизированы заказы, созданные позже этой даты.
                         </FormHelperText>
                       </Grid>
                     </Grid>
                   </Grid>
                  }

                  {
                    account.deliveryType === WbDeliveryType.FBW &&
                    <Grid item xs={12}>
                      <Grid container className={clsx(classes.settingsTopic, 'default-border')}>
                        <Grid item className={classes.root} xs={12}>
                          <Box>
                            <FormLabel component="legend" className={classes.boldLabel}>
                              Загрузка заказов
                            </FormLabel>
                          </Box>

                          <Grid container spacing={2}>
                            <Grid item xs={12}>
                              <WbLoadOrdersForm wbAccountId={account.id}/>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  }
                </Grid>
              </Grid>
            }
          </Grid>

          <Box display="flex">
            <button
              type="submit"
              className={clsx(classes.saveButton, 'default-button')}
              disabled={isLoading}
            >
              <p>{isLoading ? 'Сохраняем...' : 'Сохранить'}</p>
            </button>
          </Box>
        </form>
      </Box>
    )
  })