import React from 'react'
import { observer } from 'mobx-react-lite'

import { Box, FormHelperText } from '@material-ui/core'

import { MyServerTable } from './MyServerTable'

import { useStore } from '../hooks/storeHook'

import { useMoySkladProductColumns } from '../types/moySkladColumns'
import { makeStyles } from '@material-ui/core/styles'
import { BootstrapInput } from './BootstrapInput'
import { useSettingsPageStyles } from '../hooks/settingsPageStylesHook'

export const MoySkladProductsTable =
  observer(() => {
    const {syncStore: {moySkladAccount}, productStore: {moySkladProducts}} = useStore()
    const msProductColumns = useMoySkladProductColumns()

    const classes = {...useStyles(), ...useSettingsPageStyles()}

    if (moySkladAccount == null) {
      return null
    }

    return (
      <>
        <Box marginY={2} display="inline-flex">
          <Box>
            <BootstrapInput
              id="search-ms"
              placeholder="Поиск"
              onChange={e => moySkladProducts.setGlobalFilter(e.target.value)}
              className={classes.input}
            />
            <FormHelperText className={classes.helperText}>
              Id / GUID / Код / Артикул / Наименование / Штрихкод
            </FormHelperText>
          </Box>
        </Box>

        <MyServerTable
          tableProps={{size: 'small'}}
          table={moySkladProducts}
          columns={msProductColumns}
          labelNoRows="Нет товаров"
          labelNotFound="Товары не найдены"
          labelRowsPerPage="Товаров на странице:"
        />
      </>
    )
  })

const useStyles = makeStyles(() => ({
  input: {
    fontSize: 12
  }
}))