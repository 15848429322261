// TODO: Get rid of these helpers? Type gymnastics are complicated, new routes should not be using this anyway.
import { IntegrationTypeKey } from '../types/integrationTypeUtils'
import { matchPath } from 'react-router-dom'

export type RouteWithParams<TParams extends Record<string, unknown>> = {
  template: string,
  route: (routeArgs: TParams) => string
  parse: (url: string) => Partial<TParams> | null
}

export type RouteWithChildren<TBase extends string, TChildren extends string[]> = {
  base: TBase
} & {
  [TChild in TChildren[number]]: ChildRoute<TBase, TChild>
}

export type ChildRoute<TBase extends string, TChild extends string> =
  `${TBase}/${TChild}`

export type RoutesPath<TRoutes> = RoutePath<TRoutes[keyof TRoutes]>

export type RoutePath<TRoute> =
  TRoute extends string
  ? TRoute
  : TRoute extends {template: string}
    ? TRoute['template']
    : TRoute extends RouteWithChildren<infer TBase, infer TChildren>
      ? TBase | ChildRoute<TBase, TChildren[number]>
      : unknown

const normalizeAccountId = (value: unknown): number => {
  if (typeof value !== 'number' || value < 0) {
    return 0
  }
  return value
}

export const createRoute =
  <TParams extends Record<string, unknown>>() =>
    <TParamName extends (keyof TParams)[]>
    (templateFragments: TemplateStringsArray, ...routeParamNames: TParamName): RouteWithParams<TParams> => {
      const templateFragmentsFiltered = templateFragments.filter(s => !!s)

      if (routeParamNames.length === 0) {
        throw new Error('No route params')
      }

      if (routeParamNames.length !== templateFragmentsFiltered.length) {
        throw new Error(`Lengths of route params and template fragments do not match`)
      }

      if (!templateFragmentsFiltered[0].startsWith('/')) {
        throw new Error(`The route template must start with a slash: ${templateFragmentsFiltered[0]}`)
      }

      for (const templateFragment of templateFragmentsFiltered) {
        if (!/^[\w/]+$/.test(templateFragment)) {
          throw new Error(`Bad route template fragment: ${templateFragment}`)
        }
      }

      const template = templateFragmentsFiltered.reduce((acc, fragment, index) => {
        const param = routeParamNames[index] ? `:${String(routeParamNames[index])}` : ''
        return acc + fragment + param
      }, '')

      return {
        template,

        route: (routeParams) => {
          const routeEntries = Object.entries(routeParams)

          return templateFragmentsFiltered.reduce((acc, fragment, index) => {
            const routeParamName = routeParamNames[index]

            let routeEntry: [keyof TParams, unknown] | undefined = routeEntries.find(([key]) => key === routeParamName)

            if (!routeEntry) {
              throw new Error(`Missing route param: ${String(routeParamName)}`)
            }
            let [, value] = routeEntry

            if (routeParamName === 'accountId') {
              value = normalizeAccountId(value)
            }

            return acc + fragment + String(value)
          }, '')
        },

        parse: (url) => parseUrl<TParams>(template, url)
      }
    }

export function parseUrl<TParams extends Record<string, unknown>>(
  template: string,
  url: string): Partial<TParams> | null {
  const templateParts = template.split('/').filter(Boolean)
  const urlParts = url.split('/').filter(Boolean)

  const maxPartsToCheck = Math.min(templateParts.length, urlParts.length)

  const truncatedTemplate = templateParts.slice(0, maxPartsToCheck).join('/')
  const truncatedUrl = urlParts.slice(0, maxPartsToCheck).join('/')

  const match = matchPath(truncatedUrl, {path: truncatedTemplate})

  if (!match) {
    return null
  }
  const params: Partial<TParams> = {}

  for (let i = 0; i < templateParts.length; i++) {
    const templatePart = templateParts[i]
    const urlPart = urlParts[i]

    if (templatePart.startsWith(':')) {
      const paramName = templatePart.slice(1) as keyof TParams
      if (paramName === 'integration') {
        params[paramName] = decodeURIComponent(
          urlPart) as unknown as TParams[keyof TParams & 'integration' | IntegrationTypeKey]
      } else {
        params[paramName] = decodeURIComponent(urlPart) as TParams[keyof TParams]
      }
    }
  }

  return params
}

/**
 * Combines a base route with a child route or a child route template.
 *
 * This function supports two types of `childRouteOrTemplate`:
 * 1. **String template**: Directly appends a string to the base route template, creating a new route.
 * 2. **RouteWithParams**: Combines two route templates and merges their parameters to create a more complex route with a unified parameter set.
 *
 * @template TBaseParams - The type of parameters for the base route.
 * @template TChildParams - The type of parameters for the child route. Defaults to an empty object.
 *
 * @param {RouteWithParams<TBaseParams>} baseRoute - The base route object containing a template and a `route` function.
 * This route contains dynamic segments like `/:param`, which are replaced by actual values when generating the final route.
 *
 * @param {RouteWithParams<TChildParams> | string} childRouteOrTemplate - Can either be a string template to append to the base route (e.g., `/details`), or another `RouteWithParams` object that itself contains dynamic segments.
 *
 * @returns {RouteWithParams<TBaseParams & TChildParams> | RouteWithParams<TBaseParams>}
 * - If `childRouteOrTemplate` is a string, it returns a route combining the base route and the child string, keeping the same parameters as `TBaseParams`.
 * - If `childRouteOrTemplate` is a `RouteWithParams`, it returns a route combining both the base route and child route, with the merged parameters of `TBaseParams & TChildParams`.
 *
 */

export function composeRoute<
  TBaseParams extends Record<string, unknown>,
  TChildParams extends Record<string, unknown> = {}
>(
  baseRoute: RouteWithParams<TBaseParams>,
  childRouteOrTemplate: RouteWithParams<TChildParams> | string
): RouteWithParams<TBaseParams & TChildParams> | RouteWithParams<TBaseParams> {

  if (typeof childRouteOrTemplate === 'string') {
    const combinedTemplate = `${baseRoute.template}${childRouteOrTemplate}`
    return {
      template: combinedTemplate,

      route: (params: TBaseParams) => {
        const basePath = baseRoute.route(params)
        return `${basePath}${childRouteOrTemplate}`
      },

      parse: (url: string) => parseUrl<TBaseParams>(combinedTemplate, url)
    }
  }

  const combinedTemplate = `${baseRoute.template}${childRouteOrTemplate.template}`

  return {
    template: combinedTemplate,

    route: (params: TBaseParams & TChildParams) => {
      const baseParams: Partial<TBaseParams> = {}
      const childParams: Partial<TChildParams> = {}

      Object.keys(params).forEach((key) => {
        if (baseRoute.template.includes(`:${key}`)) {
          (baseParams as TBaseParams)[key as keyof TBaseParams] = params[key] as TBaseParams[keyof TBaseParams]
        } else if (childRouteOrTemplate.template.includes(`:${key}`)) {
          (childParams as TChildParams)[key as keyof TChildParams] = params[key] as TChildParams[keyof TChildParams]
        }
      })

      const basePath = baseRoute.route(baseParams as TBaseParams)
      const childPath = childRouteOrTemplate.route(childParams as TChildParams)

      return `${basePath}${childPath}`
    },
    parse: (url: string) => {
      const baseParsed = baseRoute.parse(url)

      if (!baseParsed) {
        return null
      }

      const missingBaseParams = Object.keys(baseRoute.template).some(param => !(param in baseParsed))

      if (missingBaseParams) {
        return null
      }

      const remainingUrl = url.replace(baseRoute.route(baseParsed as TBaseParams), '')
      const childParsed = childRouteOrTemplate.parse(remainingUrl)

      if (!childParsed) {
        return null
      }

      return {...baseParsed, ...childParsed} as TBaseParams & TChildParams
    }
  }
}