import React from 'react'
import { observer } from 'mobx-react-lite'

import {
  Box,
  CircularProgress
} from '@material-ui/core'

import { OzonPrintOrderModel } from '../server/mpsklad_core/Models/OzonPrintOrderModel'
import { BootstrapInput } from './BootstrapInput'
import { MyTable } from './MyTable'
import { OzonPrintOrderColumnId, ozonPrintOrderColumns } from '../types/ozonColumns'
import { ITableData } from '../types/tableTypes'

export type OzonPreviewPrintTableProps = {
  orders: ITableData<OzonPrintOrderModel, OzonPrintOrderColumnId>
}

export const OzonPreviewPrintTable =
  observer(
    ({orders}: OzonPreviewPrintTableProps) => {
      if (orders === null) {
        return <Box padding={2}>
          <CircularProgress size={20}/>
        </Box>
      }

      return (
        <>
          <BootstrapInput
            disabled
            fullWidth
            value={`Выбрано заказов: ${orders.selectedRowCount}`}
            style={{margin: 0}}>
          </BootstrapInput>

          <MyTable
            table={orders}
            columns={ozonPrintOrderColumns}
            labelNoRows="Заказов нет"
            labelNotFound="Заказы не найдены"
            labelRowsPerPage="Заказов на странице:"
          />
        </>)
    })