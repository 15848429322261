import React from 'react'
import clsx from 'clsx'

import { CheckCircle } from '@material-ui/icons'
import { makeStyles } from '@material-ui/core/styles'
import { FormControlLabel, FormHelperText, Radio } from '@material-ui/core'

import { DefaultRadioGroupProps, DefaultRadioOption, DefaultRadioValue } from './DefaultRadioGroup'

export type DefaultRadioItemProps<TValue extends DefaultRadioValue> =
  Pick<DefaultRadioGroupProps<TValue>,
    'value'
    | 'disabled'
    | 'optionClassName'
    | 'getOptionClassName'
    | 'optionRadioClassName'
    | 'optionLabelClassName'
    | 'getOptionLabelClassName'
    | 'optionHintClassName'>
  & DefaultRadioGroupProps<TValue>['optionProps']
  & {
  option: DefaultRadioOption<TValue>
}

export const DefaultRadioItem =
  <TValue extends DefaultRadioValue>
  ({
     option, value,
     disabled,
     optionClassName, getOptionClassName,
     optionRadioClassName,
     optionLabelClassName, getOptionLabelClassName,
     optionHintClassName,
     ...passProps
   }: DefaultRadioItemProps<TValue>) => {
    const classes = useStyles()

    return (
      <FormControlLabel
        value={getOptionKey(option)}
        disabled={disabled || option.disabled}
        className={clsx(
          optionClassName,
          getOptionClassName && getOptionClassName(value === option.value)
        )}
        control={
          <Radio
            checked={value === option.value}
            checkedIcon={<CheckCircle/>}
            disableRipple
            className={clsx(classes.control, optionRadioClassName)}
          />
        }
        label={
          <>
            <span
              className={clsx(
                optionLabelClassName,
                getOptionLabelClassName && getOptionLabelClassName(value === option.value))
              }>
              {option.label}
            </span>

            {
              option.hint &&
              <FormHelperText className={optionHintClassName}>
                {option.hint}
              </FormHelperText>
            }
          </>
        }
        {...passProps}
      />
    )
  }

export const getOptionKey =
  <TValue extends DefaultRadioValue>
  (option: DefaultRadioOption<TValue>): string =>
    JSON.stringify(option.value)

const useStyles = makeStyles(() => ({
  control: {
    '& .MuiTouchRipple-root': {
      color: 'transparent'
    },
    '&:focusVisible': {
      backgroundColor: 'transparent'
    },
    '&:active': {
      backgroundColor: 'transparent'
    },
    '&.MuiRadio-colorSecondary.Mui-checked:hover': {
      backgroundColor: 'transparent'
    },
    '&.MuiIconButton-root:hover': {
      backgroundColor: 'transparent'
    },
    '&.MuiIconButton-colorSecondary:hover': {
      backgroundColor: 'transparent'
    }
  }
}))