import React from 'react'
import { observer } from 'mobx-react-lite'
import { Route, Switch } from 'react-router-dom'

import { useStore } from '../../hooks/storeHook'

import { appRoutes } from '../../common/appRoutes'

import { IntegrationType } from '../../server/mpsklad_core/Entity/IntegrationType'

import { OzonImportPage } from './Ozon/OzonImportPage'
import { YandexMarketImportPage } from './YandexMarket/YandexMarketImportPage'
import { WildberriesImportPage } from './Wildberries/WildberriesImportPage'
import { useValidateStoreAccountRoute } from '../../hooks/validateStoreAccountRoute'

export const ImportRoute = observer(() => {
  const {integration} = useValidateStoreAccountRoute(appRoutes.CommonStore.import)

  const {allowedIntegrations} = useStore()

  return (
    <div className="body_wrapper_zero">
      <Switch>
        {
          allowedIntegrations[IntegrationType.YandexMarket] &&
          integration === 'YandexMarket' &&
          <Route
            path={appRoutes.CommonStore.import.template}
            component={YandexMarketImportPage}/>
        }

        {
          allowedIntegrations[IntegrationType.Wildberries] &&
          integration === 'Wildberries' &&
          <Route path={appRoutes.CommonStore.import.template}
                 component={WildberriesImportPage}/>
        }

        {
          allowedIntegrations[IntegrationType.Ozon] &&
          integration === 'Ozon' &&
          <Route path={appRoutes.CommonStore.import.template}
                 component={OzonImportPage}/>
        }
      </Switch>
    </div>
  )
})